import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StrategySelectQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type StrategySelectQuery = { __typename: 'Query', activeOrg: { __typename: 'Org', id: string, displayName?: string | undefined, currentStrategy?: { __typename: 'Strategy', id: string, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction> } | undefined } | undefined }, allOrgUnits: { __typename: 'OrgUnitConnection', edges: Array<{ __typename: 'OrgUnitEdge', node: { __typename: 'OrgUnit', id: string, name?: string | undefined, currentStrategy?: { __typename: 'Strategy', id: string, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction> } | undefined } | undefined } }> } };

export type StrategySelectStrategyFragment = { __typename: 'Strategy', id: string, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction> } | undefined };

export const StrategySelectStrategyFragmentDoc = gql`
    fragment strategySelectStrategy on Strategy {
  id
  orgUnit {
    id
    name
    currentUserAuthorizedActions
  }
}
    `;
export const StrategySelectDocument = gql`
    query StrategySelect {
  activeOrg {
    id
    displayName
    currentStrategy {
      ...strategySelectStrategy
    }
  }
  allOrgUnits {
    edges {
      node {
        id
        name
        currentStrategy {
          ...strategySelectStrategy
        }
      }
    }
  }
}
    ${StrategySelectStrategyFragmentDoc}`;

/**
 * __useStrategySelectQuery__
 *
 * To run a query within a React component, call `useStrategySelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useStrategySelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStrategySelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useStrategySelectQuery(baseOptions?: Apollo.QueryHookOptions<StrategySelectQuery, StrategySelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StrategySelectQuery, StrategySelectQueryVariables>(StrategySelectDocument, options);
      }
export function useStrategySelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StrategySelectQuery, StrategySelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StrategySelectQuery, StrategySelectQueryVariables>(StrategySelectDocument, options);
        }
export function useStrategySelectSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<StrategySelectQuery, StrategySelectQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StrategySelectQuery, StrategySelectQueryVariables>(StrategySelectDocument, options);
        }
export type StrategySelectQueryHookResult = ReturnType<typeof useStrategySelectQuery>;
export type StrategySelectLazyQueryHookResult = ReturnType<typeof useStrategySelectLazyQuery>;
export type StrategySelectSuspenseQueryHookResult = ReturnType<typeof useStrategySelectSuspenseQuery>;
export type StrategySelectQueryResult = Apollo.QueryResult<StrategySelectQuery, StrategySelectQueryVariables>;