import { useMemo } from 'react';
import styled from '@emotion/styled';

import { determineIfIsOverdue } from 'actions/action.utils';
import ActionChipOverdue from 'actions/ActionChipOverdue';
import { ReactComponent as DateIcon } from 'shared/static/icons/icon-date.svg';
import { ReactComponent as CheckSquareIcon } from 'shared/static/icons/icon-check-square.svg';
import UserAvatar from 'user/UserAvatar';
import { date } from 'shared/services/date.service';
import CompletionRate from 'shared/components/CompletionRate';
import type { ActionWithObjective } from 'actions/actions.type';
import useDialogState from 'shared/hooks/useDialogState';
import ActionStatusModal from 'actions/ActionStatusModal';
import ActionContextMenu from 'actions/ActionContextMenu/ActionContextMenu';
import TeamAvatar from 'team/TeamAvatar/TeamAvatar';
import { transientOptions } from 'shared/utils/emotion.utils';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import LockedLink from 'shared/components/LockedLink';

type ActionRowProps = {
  action: ActionWithObjective;
};

const Row = styled('li', transientOptions)<{ $isLocked: boolean }>`
  align-items: center;
  display: flex;
  gap: 12px;
  max-height: 50px;
  padding: 16px 22px 16px 26px;
  position: relative;

  &::after {
    border-bottom: 1px solid ${({ theme }) => theme.color.strokeLight};
    bottom: 0;
    content: '';
    left: 26px;
    position: absolute;
    width: calc(100% - 48px);
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.hoverLight};
    cursor: ${({ $isLocked }) => ($isLocked ? 'default' : 'pointer')};
  }
`;

const NameContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 14px;
  overflow: hidden;
`;

const NameIcon = styled(CheckSquareIcon)`
  color: ${({ theme }) => theme.color.typoSecondary};
  height: 14px;
  width: 14px;
  flex-shrink: 0;
`;

const NameText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
`;

const Timeline = styled('div', transientOptions)<{ $isOverdue: boolean }>`
  align-items: center;
  display: flex;
  flex-basis: 126px;
  flex-shrink: 0;
  gap: 6px;
  ${({ $isOverdue }) => !$isOverdue && 'margin-left: auto;'}
`;

const TimelineIcon = styled(DateIcon)`
  color: ${({ theme }) => theme.color.typoSecondary};
  flex-basis: 24px;
  min-height: 24px;
  min-width: 24px;
`;

const TimelineText = styled('div', transientOptions)<{ $isOverdue: boolean }>`
  color: ${({ theme, $isOverdue }) =>
    $isOverdue ? theme.color.error : theme.color.typoSecondary};
  white-space: nowrap;
`;

const AvatarContainer = styled.div`
  flex-shrink: 0;
`;

const ActionRow = ({ action }: ActionRowProps) => {
  const { isOpen, onOpen, onClose } = useDialogState();
  const isActionOverdue = useMemo(() => determineIfIsOverdue(action), [action]);

  const isLocked = !canPerformStrategyElementAction(action, 'READ');

  return (
    <>
      <Row $isLocked={isLocked} onClick={isLocked ? undefined : onOpen}>
        <NameContainer>
          <NameIcon />
          <LockedLink isActive={!isLocked}>
            <NameText>{action.name}</NameText>
          </LockedLink>
        </NameContainer>

        {isActionOverdue && <ActionChipOverdue />}

        <Timeline $isOverdue={isActionOverdue}>
          <TimelineIcon />
          <TimelineText $isOverdue={isActionOverdue}>
            {action.timeLine.endDate &&
              date.format(action.timeLine.endDate, 'dd MMM yyyy')}
          </TimelineText>
        </Timeline>

        <CompletionRate
          complete={action.currentStatus?.complete}
          completionRate={action.currentStatus?.completionRate}
        />

        <TeamAvatar orgUnit={action.orgUnit} size={'small'} />

        <AvatarContainer>
          <UserAvatar user={action.owner} />
        </AvatarContainer>

        <ActionContextMenu action={action} />
      </Row>

      <ActionStatusModal
        actionId={action.id}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};

export default ActionRow;
