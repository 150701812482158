import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrgCardQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrgCardQuery = { __typename: 'Query', activeOrg: { __typename: 'Org', id: string, orgKey: string, displayName?: string | undefined, displayNameAbbreviated?: string | undefined, employeesCount?: number | undefined, backgroundPhotoUrl?: string | undefined, backgroundColorAsHex?: string | undefined, orgUnits: Array<{ __typename: 'OrgUnit', id: string }>, currentStrategy?: { __typename: 'Strategy', id: string, name?: string | undefined, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined } | undefined } };

export type OrgCardOrgFragment = { __typename: 'Org', id: string, orgKey: string, displayName?: string | undefined, displayNameAbbreviated?: string | undefined, employeesCount?: number | undefined, backgroundPhotoUrl?: string | undefined, backgroundColorAsHex?: string | undefined, orgUnits: Array<{ __typename: 'OrgUnit', id: string }>, currentStrategy?: { __typename: 'Strategy', id: string, name?: string | undefined, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined } | undefined };

export const OrgCardOrgFragmentDoc = gql`
    fragment orgCardOrg on Org {
  id
  orgKey
  displayName
  displayNameAbbreviated
  employeesCount
  backgroundPhotoUrl
  backgroundColorAsHex
  orgUnits {
    id
  }
  currentStrategy {
    id
    name
    orgUnit {
      id
    }
  }
}
    `;
export const OrgCardDocument = gql`
    query OrgCard {
  activeOrg {
    ...orgCardOrg
  }
}
    ${OrgCardOrgFragmentDoc}`;

/**
 * __useOrgCardQuery__
 *
 * To run a query within a React component, call `useOrgCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgCardQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgCardQuery(baseOptions?: Apollo.QueryHookOptions<OrgCardQuery, OrgCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgCardQuery, OrgCardQueryVariables>(OrgCardDocument, options);
      }
export function useOrgCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgCardQuery, OrgCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgCardQuery, OrgCardQueryVariables>(OrgCardDocument, options);
        }
export function useOrgCardSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OrgCardQuery, OrgCardQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrgCardQuery, OrgCardQueryVariables>(OrgCardDocument, options);
        }
export type OrgCardQueryHookResult = ReturnType<typeof useOrgCardQuery>;
export type OrgCardLazyQueryHookResult = ReturnType<typeof useOrgCardLazyQuery>;
export type OrgCardSuspenseQueryHookResult = ReturnType<typeof useOrgCardSuspenseQuery>;
export type OrgCardQueryResult = Apollo.QueryResult<OrgCardQuery, OrgCardQueryVariables>;