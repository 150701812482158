import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { isApolloError } from '@apollo/client';

import env from 'environment';
import Result from 'shared/components/Result';
import Space from 'shared/components/Space';
import { ReactComponent as LookingAtCardBoardBoxIllustration } from 'shared/static/illustrations/illustration-looking-at-cardboard-box.svg';
import { routesPaths } from 'App.routing.paths';
import { getGraphqlErrorMessage, isGraphqlError } from 'shared/graphql/utils';
import Flex from 'shared/components/Flex';
import { auth } from 'auth/AuthProvider';

import { useErrorPage } from './useErrorPage';
import { getApolloErrorMessage } from '../errors.utils';

const email = 'support@bluejam.io';

const ErrorPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { error, clearError } = useErrorPage();

  const details =
    env.REACT_APP_ENV === 'production' ? undefined : error?.message;

  const errorMessage = !error
    ? t('errorMessage.unknown')
    : isApolloError(error)
    ? getApolloErrorMessage(t, error)
    : isGraphqlError(error)
    ? getGraphqlErrorMessage(t, error)
    : t('errorMessage.unknown');

  return (
    <Space isCentered={true}>
      <Result
        illustration={LookingAtCardBoardBoxIllustration}
        heading={t('serverErrorResult.heading')}
        isGlobal={true}
        description={
          <Flex direction={'column'} gap={'1rem'}>
            {details && <div>{details}</div>}

            <div>{errorMessage}</div>

            <div>
              <Trans
                i18nKey={'serverErrorResult.contact'}
                values={{ email }}
                components={{ b: <b /> }}
              />
            </div>
          </Flex>
        }
        buttons={[
          {
            children: t('errorPage.goToHomePage'),
            onPress: () => {
              clearError();
              navigate(routesPaths.root());
            },
          },
          {
            children: t('errorPage.goToLogin'),
            variant: 'outlined',
            onPress: () => {
              clearError();
              signOut(auth);
            },
          },
        ]}
      />
    </Space>
  );
};

export default ErrorPage;
