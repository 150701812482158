import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteObjectiveConfirmationModalQueryVariables = Types.Exact<{
  objectiveId: Types.Scalars['ID']['input'];
}>;


export type DeleteObjectiveConfirmationModalQuery = { __typename: 'Query', objective: { __typename: 'Objective', id: string, metrics: Array<{ __typename: 'Metric', id: string }>, initiatives: Array<{ __typename: 'Initiative', id: string }>, actions: Array<{ __typename: 'Action', id: string }> } };


export const DeleteObjectiveConfirmationModalDocument = gql`
    query DeleteObjectiveConfirmationModal($objectiveId: ID!) {
  objective(id: $objectiveId) {
    id
    metrics {
      id
    }
    initiatives {
      id
    }
    actions {
      id
    }
  }
}
    `;

/**
 * __useDeleteObjectiveConfirmationModalQuery__
 *
 * To run a query within a React component, call `useDeleteObjectiveConfirmationModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeleteObjectiveConfirmationModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeleteObjectiveConfirmationModalQuery({
 *   variables: {
 *      objectiveId: // value for 'objectiveId'
 *   },
 * });
 */
export function useDeleteObjectiveConfirmationModalQuery(baseOptions: Apollo.QueryHookOptions<DeleteObjectiveConfirmationModalQuery, DeleteObjectiveConfirmationModalQueryVariables> & ({ variables: DeleteObjectiveConfirmationModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeleteObjectiveConfirmationModalQuery, DeleteObjectiveConfirmationModalQueryVariables>(DeleteObjectiveConfirmationModalDocument, options);
      }
export function useDeleteObjectiveConfirmationModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeleteObjectiveConfirmationModalQuery, DeleteObjectiveConfirmationModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeleteObjectiveConfirmationModalQuery, DeleteObjectiveConfirmationModalQueryVariables>(DeleteObjectiveConfirmationModalDocument, options);
        }
export function useDeleteObjectiveConfirmationModalSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DeleteObjectiveConfirmationModalQuery, DeleteObjectiveConfirmationModalQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeleteObjectiveConfirmationModalQuery, DeleteObjectiveConfirmationModalQueryVariables>(DeleteObjectiveConfirmationModalDocument, options);
        }
export type DeleteObjectiveConfirmationModalQueryHookResult = ReturnType<typeof useDeleteObjectiveConfirmationModalQuery>;
export type DeleteObjectiveConfirmationModalLazyQueryHookResult = ReturnType<typeof useDeleteObjectiveConfirmationModalLazyQuery>;
export type DeleteObjectiveConfirmationModalSuspenseQueryHookResult = ReturnType<typeof useDeleteObjectiveConfirmationModalSuspenseQuery>;
export type DeleteObjectiveConfirmationModalQueryResult = Apollo.QueryResult<DeleteObjectiveConfirmationModalQuery, DeleteObjectiveConfirmationModalQueryVariables>;