import { useTranslation } from 'react-i18next';

import Button from 'shared/components/Button';
import useCanPerformOrgOrOrgUnitAction from 'user/ability/useCanPerformOrgOrOrgUnitAction';
import useDialogState from 'shared/hooks/useDialogState';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';

import CreateAssessmentModal from './CreateAssessmentModal';

const CreateAssessmentButton = () => {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDialogState();

  const isAllowed = useCanPerformOrgOrOrgUnitAction('WRITE_STRATEGY', 'WRITE');

  if (!isAllowed) return null;

  return (
    <>
      <Button onPress={onOpen} icon={PlusIcon} iconPosition={'start'}>
        {t('strategy.assessments.createButton')}
      </Button>

      <CreateAssessmentModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default CreateAssessmentButton;
