import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveInitiativeMilestoneMutationVariables = Types.Exact<{
  initiativeIdToUpdate: Types.Scalars['ID']['input'];
  milestoneIdToRemove: Types.Scalars['ID']['input'];
}>;


export type RemoveInitiativeMilestoneMutation = { __typename: 'Mutation', updateInitiative: { __typename: 'Initiative', id: string } };


export const RemoveInitiativeMilestoneDocument = gql`
    mutation RemoveInitiativeMilestone($initiativeIdToUpdate: ID!, $milestoneIdToRemove: ID!) {
  updateInitiative(
    input: {idToUpdate: $initiativeIdToUpdate, milestones: {idsToDelete: [$milestoneIdToRemove]}}
  ) {
    id
  }
}
    `;
export type RemoveInitiativeMilestoneMutationFn = Apollo.MutationFunction<RemoveInitiativeMilestoneMutation, RemoveInitiativeMilestoneMutationVariables>;

/**
 * __useRemoveInitiativeMilestoneMutation__
 *
 * To run a mutation, you first call `useRemoveInitiativeMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveInitiativeMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeInitiativeMilestoneMutation, { data, loading, error }] = useRemoveInitiativeMilestoneMutation({
 *   variables: {
 *      initiativeIdToUpdate: // value for 'initiativeIdToUpdate'
 *      milestoneIdToRemove: // value for 'milestoneIdToRemove'
 *   },
 * });
 */
export function useRemoveInitiativeMilestoneMutation(baseOptions?: Apollo.MutationHookOptions<RemoveInitiativeMilestoneMutation, RemoveInitiativeMilestoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveInitiativeMilestoneMutation, RemoveInitiativeMilestoneMutationVariables>(RemoveInitiativeMilestoneDocument, options);
      }
export type RemoveInitiativeMilestoneMutationHookResult = ReturnType<typeof useRemoveInitiativeMilestoneMutation>;
export type RemoveInitiativeMilestoneMutationResult = Apollo.MutationResult<RemoveInitiativeMilestoneMutation>;
export type RemoveInitiativeMilestoneMutationOptions = Apollo.BaseMutationOptions<RemoveInitiativeMilestoneMutation, RemoveInitiativeMilestoneMutationVariables>;