import { useMemo } from 'react';
import { groupBy, sortBy, uniqBy } from 'lodash';

import { date } from 'shared/services/date.service';

import type {
  FollowUpActivityItem,
  FollowUpActivityItemOneThing,
  FollowUpActivityItemStatusRequest,
  FollowUpActivityItemWalkOfStrategy,
} from './ContributionMyFollowUpsViewMore.type';
import type {
  ContributionMyFollowUpsCampaignRequestFragment,
  ContributionMyFollowUpsOneThingCampaignFragment,
  ContributionMyFollowUpsStatusRequestFragment,
} from './ContributionMyFollowUpsViewMore.graphql.generated';

const useMyFollowUpItems = (
  filterCompletedGroups: boolean,
  statusRequests?: ContributionMyFollowUpsStatusRequestFragment[],
  campaignRequests?: ContributionMyFollowUpsCampaignRequestFragment[],
  oneThingCampaignRequests?: {
    campaign: ContributionMyFollowUpsOneThingCampaignFragment;
  }[],
): FollowUpActivityItem[] =>
  useMemo(() => {
    if (!statusRequests || !campaignRequests || !oneThingCampaignRequests) {
      return [];
    }

    const statusRequestsByTimeAndSubmitter = groupBy(
      statusRequests,
      (statusRequest) =>
        `${date.formatShort(statusRequest.requestSubmitTime)}:${
          statusRequest.requestSubmitter.id
        }:${
          statusRequest.deadline
            ? date.formatShort(statusRequest.deadline)
            : '-'
        }`,
    );

    const statusRequestsByTimeAndSubmitterEntries = Object.entries(
      statusRequestsByTimeAndSubmitter,
    );

    const statusRequestItems = statusRequestsByTimeAndSubmitterEntries
      .map(
        ([key, statusRequests]) =>
          ({
            type: 'status-request',
            id: key,
            requestSubmitTime: statusRequests[0].requestSubmitTime,
            requestRecipient: statusRequests[0].requestRecipient,
            requestSubmitter: statusRequests[0].requestSubmitter,
            statusRequests: uniqBy(
              statusRequests,
              (statusRequest) => statusRequest.strategyElement.id,
            ),
            deadline: statusRequests[0].deadline,
            isDone: statusRequests.every(
              (statusRequest) => statusRequest.isComplete,
            ),
          } satisfies FollowUpActivityItemStatusRequest),
      )
      .filter(
        (item) =>
          !filterCompletedGroups ||
          item.statusRequests.some(
            (statusRequest) => !statusRequest.isComplete,
          ),
      );

    const walkOfStrategyItems = campaignRequests
      .map(
        (campaignRequest) =>
          ({
            type: 'walk-of-strategy',
            id: campaignRequest.campaign.id,
            requestSubmitTime: campaignRequest.campaign.timeLine.startDate,
            requestSubmitter: campaignRequest.campaign.owner,
            deadline:
              campaignRequest.campaign.deadline ||
              campaignRequest.campaign.timeLine.endDate,
            campaignRequest,
            isDone: campaignRequest.response?.status === 'DONE',
          } satisfies FollowUpActivityItemWalkOfStrategy),
      )
      .filter(
        (item) =>
          !filterCompletedGroups ||
          (item.campaignRequest.campaign.state.stage === 'ACTIVE' &&
            item.campaignRequest.response?.status !== 'DONE'),
      );

    const oneThingItems = oneThingCampaignRequests
      .map(
        (campaignRequest) =>
          ({
            type: 'one-thing',
            id: campaignRequest.campaign.id,
            requestSubmitTime: campaignRequest.campaign.timeLine.startDate,
            requestSubmitter: campaignRequest.campaign.owner,
            deadline:
              campaignRequest.campaign.deadline ||
              campaignRequest.campaign.timeLine.endDate,
            campaign: campaignRequest.campaign,
            isDone: campaignRequest.campaign.currentUserTask?.status === 'DONE',
          } satisfies FollowUpActivityItemOneThing),
      )
      .filter(
        (item) =>
          !filterCompletedGroups ||
          (item.campaign.state.stage === 'ACTIVE' &&
            item.campaign.currentUserTask?.status !== 'DONE'),
      );

    const allItems = [
      ...statusRequestItems,
      ...walkOfStrategyItems,
      ...oneThingItems,
    ];

    return sortBy(allItems, (item) => item.requestSubmitTime).reverse();
  }, [
    campaignRequests,
    filterCompletedGroups,
    oneThingCampaignRequests,
    statusRequests,
  ]);

export default useMyFollowUpItems;
