import type { TypePolicies } from '@apollo/client';

import { readDateTimeField } from 'shared/graphql/utils';

export const strategyTypePolicies: TypePolicies = {
  StrategyAssessment: {
    fields: {
      createDateTime: {
        read: readDateTimeField,
      },
    },
  },
};
