import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InsightsReportSuccessSuggestionsSubscriptionVariables = Types.Exact<{
  numberOfSuggestions: Types.Scalars['Int']['input'];
  elementIds?: Types.InputMaybe<Types.StrategyElementListInput>;
  languageLocaleCode: Types.Scalars['String']['input'];
  temperature?: Types.InputMaybe<Types.Scalars['Float']['input']>;
}>;


export type InsightsReportSuccessSuggestionsSubscription = { __typename: 'Subscription', suggestions: Array<{ __typename: 'AiSuggestionElement', typeAsText: string, fields: Array<{ __typename: 'KeyValue', key: string, value: string }> }> };


export const InsightsReportSuccessSuggestionsDocument = gql`
    subscription InsightsReportSuccessSuggestions($numberOfSuggestions: Int!, $elementIds: StrategyElementListInput, $languageLocaleCode: String!, $temperature: Float) {
  suggestions: aiSuggestionStream(
    input: {languageLocaleCode: $languageLocaleCode, elementIds: $elementIds, response: {typeAsText: "Insights Report", fields: ["success comment"]}, numberOfSuggestions: $numberOfSuggestions, model: {temperature: $temperature}, hints: ["Suggest summaries of successes achieved in the provided report items", "Success comment should target elements that are going very well and not elements at risk or blocked", "Do not suggest issues or improvements", "Avoid mentioning audit records"]}
  ) {
    typeAsText
    fields {
      key
      value
    }
  }
}
    `;

/**
 * __useInsightsReportSuccessSuggestionsSubscription__
 *
 * To run a query within a React component, call `useInsightsReportSuccessSuggestionsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInsightsReportSuccessSuggestionsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsReportSuccessSuggestionsSubscription({
 *   variables: {
 *      numberOfSuggestions: // value for 'numberOfSuggestions'
 *      elementIds: // value for 'elementIds'
 *      languageLocaleCode: // value for 'languageLocaleCode'
 *      temperature: // value for 'temperature'
 *   },
 * });
 */
export function useInsightsReportSuccessSuggestionsSubscription(baseOptions: Apollo.SubscriptionHookOptions<InsightsReportSuccessSuggestionsSubscription, InsightsReportSuccessSuggestionsSubscriptionVariables> & ({ variables: InsightsReportSuccessSuggestionsSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<InsightsReportSuccessSuggestionsSubscription, InsightsReportSuccessSuggestionsSubscriptionVariables>(InsightsReportSuccessSuggestionsDocument, options);
      }
export type InsightsReportSuccessSuggestionsSubscriptionHookResult = ReturnType<typeof useInsightsReportSuccessSuggestionsSubscription>;
export type InsightsReportSuccessSuggestionsSubscriptionResult = Apollo.SubscriptionResult<InsightsReportSuccessSuggestionsSubscription>;