import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import type { SerializedStyles } from '@emotion/react';

import ContextMenu from 'shared/components/ContextMenu';
import useHandleError from 'shared/errors/useHandleError';
import useDialogState from 'shared/hooks/useDialogState';
import { useToasts } from 'shared/toast/useToasts';
import type { Action, ActionStatus } from 'types.graphql.generated';
import {
  canPerformStrategyElementAction,
  type StrategyElementWithAuthorizedActions,
} from 'user/ability/canPerformStrategyElementAction';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import ConfirmationModal from 'shared/components/__DEPRECATED__/ConfirmationModal';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import { ReactComponent as TrashIcon } from 'shared/static/icons/icon-trash.svg';
import { ReactComponent as EditIcon } from 'shared/static/icons/icon-edit.svg';
import { ReactComponent as CheckmarkDoneIcon } from 'shared/static/icons/icon-checkmark-done.svg';
import { ReactComponent as RefreshIcon } from 'shared/static/icons/icon-refresh.svg';
import { ReactComponent as SendIcon } from 'shared/static/icons/icon-send.svg';
import EditAction from 'actions/EditAction';
import ActionStatusModal from 'actions/ActionStatusModal';
import SendStatusRequestsDialog from 'status/SendStatusRequestsDialog';

import { useDeleteActionMutation } from './DeleteAction.graphql.generated';
import { useAddActionStatusMutation } from './AddActionStatus.graphql.generated';

type Props = {
  action: Pick<Action, '__typename' | 'id' | 'isCurrentUserOwner'> & {
    currentStatus?: Pick<ActionStatus, 'complete' | 'completionRate'>;
  } & StrategyElementWithAuthorizedActions;
  triggerStyle?: SerializedStyles;
};

const ActionContextMenu = ({ action, triggerStyle }: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const { activeOrg } = useActiveOrg();

  const actionId = action.id;

  const [
    isDeleteConfirmationModalOpened,
    toggleIsDeleteConfirmationModalOpened,
  ] = useToggle(false);

  const [
    isCompleteConfirmationModalOpened,
    toggleIsCompleteConfirmationModalOpened,
  ] = useToggle(false);

  const [
    isReactivateConfirmationModalOpened,
    toggleIsReactivateConfirmationModalOpened,
  ] = useToggle(false);

  const {
    isOpen: isCreateStatusOpen,
    onOpen: onCreateStatusOpen,
    onClose: onCreateStatusClose,
  } = useDialogState();

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDialogState();

  const {
    isOpen: isSendStatusRequestOpen,
    onOpen: onSendStatusRequestOpen,
    onClose: onSendStatusRequestClose,
  } = useDialogState();

  const onError = useHandleError();

  const [createCompletedActionStatus] = useAddActionStatusMutation({
    variables: {
      input: {
        orgKey: activeOrg.orgKey,
        actionId,
        complete: true,
        completionRate: action.currentStatus?.completionRate,
      },
    },
    onCompleted: () =>
      addToast({
        variant: 'success',
        children: t('strategy.toasts.completeSuccessToast'),
      }),
    onError,
  });

  const [createActiveActionStatus] = useAddActionStatusMutation({
    variables: {
      input: {
        orgKey: activeOrg.orgKey,
        actionId,
        complete: false,
        completionRate: action.currentStatus?.completionRate,
      },
    },
    onCompleted: () =>
      addToast({
        variant: 'success',
        children: t('strategy.toasts.reactivateSuccessToast'),
      }),
    onError,
  });

  const [deleteAction] = useDeleteActionMutation({
    variables: { actionId },
    update(cache) {
      setTimeout(() => cache.evict({ id: `Action:${actionId}` }), 100);
    },
    onCompleted: () =>
      addToast({
        variant: 'success',
        children: t('strategy.toasts.removeSuccessToast'),
      }),
    onError,
  });

  const items = useMemo(() => {
    if (!action) return [];

    const canRequestStatus = !action.isCurrentUserOwner;
    const canWrite = canPerformStrategyElementAction(action, 'WRITE');
    const canReactivate = action?.currentStatus?.complete && canWrite;
    const canComplete = !action?.currentStatus?.complete && canWrite;

    return [
      canRequestStatus && {
        id: 'requestStatus',
        title: t('statusRequest.action'),
        icon: SendIcon,
      },
      canWrite && {
        id: 'shareProgress',
        title: t('actions.contextMenu.item.shareProgress'),
        icon: PlusIcon,
      },
      canReactivate && {
        id: 'reactivate',
        title: t('actions.contextMenu.item.reactivate'),
        icon: RefreshIcon,
      },
      canComplete && {
        id: 'complete',
        title: t('actions.contextMenu.item.complete'),
        icon: CheckmarkDoneIcon,
      },
      canWrite && {
        id: 'edit',
        title: t('actions.contextMenu.item.edit'),
        icon: EditIcon,
      },
      canWrite && {
        id: 'delete',
        title: t('actions.contextMenu.item.delete'),
        icon: TrashIcon,
      },
    ].filter(Boolean);
  }, [action, t]);

  const handleAction = useCallback(
    (itemId: string) => {
      switch (itemId) {
        case 'requestStatus':
          onSendStatusRequestOpen();
          return;
        case 'shareProgress':
          onCreateStatusOpen();
          return;
        case 'reactivate':
          toggleIsReactivateConfirmationModalOpened();
          return;
        case 'complete':
          toggleIsCompleteConfirmationModalOpened();
          return;
        case 'edit':
          onEditOpen();
          return;
        case 'delete':
          toggleIsDeleteConfirmationModalOpened();
      }
    },
    [
      onCreateStatusOpen,
      onEditOpen,
      onSendStatusRequestOpen,
      toggleIsCompleteConfirmationModalOpened,
      toggleIsDeleteConfirmationModalOpened,
      toggleIsReactivateConfirmationModalOpened,
    ],
  );

  return (
    <>
      <ContextMenu
        items={items}
        onAction={handleAction}
        testId={'action'}
        triggerStyle={triggerStyle}
      />

      <ConfirmationModal
        isOpen={isDeleteConfirmationModalOpened}
        onClose={toggleIsDeleteConfirmationModalOpened}
        onConfirm={deleteAction}
      >
        {t('actions.contextMenu.confirmation.delete')}
      </ConfirmationModal>

      <ConfirmationModal
        isOpen={isCompleteConfirmationModalOpened}
        onClose={toggleIsCompleteConfirmationModalOpened}
        onConfirm={createCompletedActionStatus}
      >
        {t('actions.contextMenu.confirmation.complete')}
      </ConfirmationModal>

      <ConfirmationModal
        isOpen={isReactivateConfirmationModalOpened}
        onClose={toggleIsReactivateConfirmationModalOpened}
        onConfirm={createActiveActionStatus}
      >
        {t('actions.contextMenu.confirmation.reactivate')}
      </ConfirmationModal>

      <ActionStatusModal
        actionId={action.id}
        isOpen={isCreateStatusOpen}
        onClose={onCreateStatusClose}
      />

      <EditAction
        actionId={action.id}
        isOpen={isEditOpen}
        onClose={onEditClose}
      />

      <SendStatusRequestsDialog
        isOpen={isSendStatusRequestOpen}
        onClose={onSendStatusRequestClose}
        strategyElements={[action]}
      />
    </>
  );
};

export default ActionContextMenu;
