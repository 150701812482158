import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnlinkMetricMutationVariables = Types.Exact<{
  objectiveId: Types.Scalars['ID']['input'];
  metricId: Types.Scalars['ID']['input'];
}>;


export type UnlinkMetricMutation = { __typename: 'Mutation', updateObjective: { __typename: 'Objective', id: string, metrics: Array<{ __typename: 'Metric', id: string }> } };


export const UnlinkMetricDocument = gql`
    mutation UnlinkMetric($objectiveId: ID!, $metricId: ID!) {
  updateObjective(
    input: {idToUpdate: $objectiveId, metricLinks: {idsToRemove: [$metricId]}}
  ) {
    id
    metrics {
      id
    }
  }
}
    `;
export type UnlinkMetricMutationFn = Apollo.MutationFunction<UnlinkMetricMutation, UnlinkMetricMutationVariables>;

/**
 * __useUnlinkMetricMutation__
 *
 * To run a mutation, you first call `useUnlinkMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkMetricMutation, { data, loading, error }] = useUnlinkMetricMutation({
 *   variables: {
 *      objectiveId: // value for 'objectiveId'
 *      metricId: // value for 'metricId'
 *   },
 * });
 */
export function useUnlinkMetricMutation(baseOptions?: Apollo.MutationHookOptions<UnlinkMetricMutation, UnlinkMetricMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlinkMetricMutation, UnlinkMetricMutationVariables>(UnlinkMetricDocument, options);
      }
export type UnlinkMetricMutationHookResult = ReturnType<typeof useUnlinkMetricMutation>;
export type UnlinkMetricMutationResult = Apollo.MutationResult<UnlinkMetricMutation>;
export type UnlinkMetricMutationOptions = Apollo.BaseMutationOptions<UnlinkMetricMutation, UnlinkMetricMutationVariables>;