import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { OrgUnitForDeleteFragmentDoc } from '../../useDeleteOrgUnit/useDeleteOrgUnit.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrgUnitCardQueryVariables = Types.Exact<{
  orgUnitId: Types.Scalars['ID']['input'];
}>;


export type OrgUnitCardQuery = { __typename: 'Query', orgUnit: { __typename: 'OrgUnit', id: string, name?: string | undefined, nameAbbreviated?: string | undefined, orgUnitMembershipsCascadingTotalCount: number, backgroundPhotoUrl?: string | undefined, backgroundColorAsHex?: string | undefined, currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, currentStrategy?: { __typename: 'Strategy', id: string, name?: string | undefined, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined } | undefined, childOrgUnitTree: Array<{ __typename: 'OrgUnit', id: string }> } };

export type OrgUnitCardOrgUnitFragment = { __typename: 'OrgUnit', id: string, name?: string | undefined, nameAbbreviated?: string | undefined, orgUnitMembershipsCascadingTotalCount: number, backgroundPhotoUrl?: string | undefined, backgroundColorAsHex?: string | undefined, currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, currentStrategy?: { __typename: 'Strategy', id: string, name?: string | undefined, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined } | undefined, childOrgUnitTree: Array<{ __typename: 'OrgUnit', id: string }> };

export const OrgUnitCardOrgUnitFragmentDoc = gql`
    fragment orgUnitCardOrgUnit on OrgUnit {
  id
  name
  nameAbbreviated
  orgUnitMembershipsCascadingTotalCount
  backgroundPhotoUrl
  backgroundColorAsHex
  currentUserAuthorizedActions
  currentStrategy {
    id
    name
    orgUnit {
      id
    }
  }
  childOrgUnitTree {
    id
  }
}
    `;
export const OrgUnitCardDocument = gql`
    query OrgUnitCard($orgUnitId: ID!) {
  orgUnit(id: $orgUnitId) {
    ...orgUnitCardOrgUnit
    ...orgUnitForDelete
  }
}
    ${OrgUnitCardOrgUnitFragmentDoc}
${OrgUnitForDeleteFragmentDoc}`;

/**
 * __useOrgUnitCardQuery__
 *
 * To run a query within a React component, call `useOrgUnitCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgUnitCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgUnitCardQuery({
 *   variables: {
 *      orgUnitId: // value for 'orgUnitId'
 *   },
 * });
 */
export function useOrgUnitCardQuery(baseOptions: Apollo.QueryHookOptions<OrgUnitCardQuery, OrgUnitCardQueryVariables> & ({ variables: OrgUnitCardQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgUnitCardQuery, OrgUnitCardQueryVariables>(OrgUnitCardDocument, options);
      }
export function useOrgUnitCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgUnitCardQuery, OrgUnitCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgUnitCardQuery, OrgUnitCardQueryVariables>(OrgUnitCardDocument, options);
        }
export function useOrgUnitCardSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OrgUnitCardQuery, OrgUnitCardQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrgUnitCardQuery, OrgUnitCardQueryVariables>(OrgUnitCardDocument, options);
        }
export type OrgUnitCardQueryHookResult = ReturnType<typeof useOrgUnitCardQuery>;
export type OrgUnitCardLazyQueryHookResult = ReturnType<typeof useOrgUnitCardLazyQuery>;
export type OrgUnitCardSuspenseQueryHookResult = ReturnType<typeof useOrgUnitCardSuspenseQuery>;
export type OrgUnitCardQueryResult = Apollo.QueryResult<OrgUnitCardQuery, OrgUnitCardQueryVariables>;