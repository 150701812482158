import { compact } from 'lodash';
import type { TFunction } from 'react-i18next';

import type { ParentOrgUnitTree } from 'orgUnit/orgUnit.type';
import { TeamAdapter } from 'team/TeamAdapter';
import type { Org, OrgUnit, Strategy } from 'types.graphql.generated';
import { strategyRoutesPaths } from 'strategy/strategy.routing.paths';
import { canPerformOrgUnitAction } from 'user/ability/canPerformOrgUnitAction';
import { teamRoutesPaths } from 'team/team.routing.paths';

export const getBreadcrumbItems = (params: {
  activeOrg: Pick<Org, 'orgKey' | 'displayName'> & {
    currentStrategy?: Pick<Strategy, 'id'>;
  };
  orgUnit?: Maybe<
    Pick<OrgUnit, 'id' | 'name' | 'currentUserAuthorizedActions'> & {
      parentOrgUnitTree: ParentOrgUnitTree;
    }
  >;
  t: TFunction;
}) => {
  const { t, activeOrg, orgUnit } = params;

  return compact([
    {
      name: activeOrg.displayName as string,
      to: strategyRoutesPaths.root({
        params: {
          teamSlug: TeamAdapter.fromOrg(activeOrg).toParam(),
        },
      }),
    },
    ...(orgUnit?.parentOrgUnitTree || []).map((item) => ({
      name: item.name as string,
      isLocked: !canPerformOrgUnitAction(item, 'READ'),
      to: teamRoutesPaths.root({
        params: {
          teamSlug: TeamAdapter.fromOrgUnit(item, activeOrg.orgKey).toParam(),
        },
      }),
    })),
    orgUnit && {
      name: orgUnit.name as string,
      isLocked: !canPerformOrgUnitAction(orgUnit, 'READ'),
      to: teamRoutesPaths.root({
        params: {
          teamSlug: TeamAdapter.fromOrgUnit(
            orgUnit,
            activeOrg.orgKey,
          ).toParam(),
        },
      }),
    },
    {
      name: t('assessment.viewAssessment.title'),
    },
  ]);
};
