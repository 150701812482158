import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InsightReportWizardReportQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type InsightReportWizardReportQuery = { __typename: 'Query', report: { __typename: 'Report', id: string, name?: string | undefined, publishedTime?: Date | undefined, reportStage?: Types.ReportStage | undefined, statusDeadlineTime?: Date | undefined, statusCutoffTime?: Date | undefined, reportSelection: { __typename: 'ReportSelection', reportObjectives: Array<{ __typename: 'ReportObjective', id: string, objective: { __typename: 'Objective', id: string }, reportMetrics: Array<{ __typename: 'ReportMetric', id: string, metric: { __typename: 'Metric', id: string } }>, reportInitiatives: Array<{ __typename: 'ReportInitiative', id: string, initiative: { __typename: 'Initiative', id: string } }> }> }, reportStatus: { __typename: 'ReportStatus', statusDateTime: Date, statusComments: Array<{ __typename: 'StatusComment', id: string, text?: string | undefined, type: Types.StatusCommentType }> } } };

export type InsightReportWizardCreateReportMutationVariables = Types.Exact<{
  input: Types.ReportInput;
}>;


export type InsightReportWizardCreateReportMutation = { __typename: 'Mutation', addReport: { __typename: 'Report', id: string, name?: string | undefined, reportStage?: Types.ReportStage | undefined, publishedTime?: Date | undefined, statusDeadlineTime?: Date | undefined, statusCutoffTime?: Date | undefined } };

export type InsightReportWizardUpdateReportMutationVariables = Types.Exact<{
  input: Types.ReportInput;
}>;


export type InsightReportWizardUpdateReportMutation = { __typename: 'Mutation', updateReport: { __typename: 'Report', id: string, name?: string | undefined, publishedTime?: Date | undefined, reportStage?: Types.ReportStage | undefined, reportStatus: { __typename: 'ReportStatus', statusDateTime: Date, statusComments: Array<{ __typename: 'StatusComment', id: string, text?: string | undefined, type: Types.StatusCommentType }> } } };


export const InsightReportWizardReportDocument = gql`
    query InsightReportWizardReport($id: ID!) {
  report(id: $id) {
    id
    name
    publishedTime
    reportStage
    statusDeadlineTime
    statusCutoffTime
    reportSelection {
      reportObjectives {
        id
        objective {
          id
        }
        reportMetrics {
          id
          metric {
            id
          }
        }
        reportInitiatives {
          id
          initiative {
            id
          }
        }
      }
    }
    reportStatus {
      statusDateTime
      statusComments {
        id
        text
        type
      }
    }
  }
}
    `;

/**
 * __useInsightReportWizardReportQuery__
 *
 * To run a query within a React component, call `useInsightReportWizardReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightReportWizardReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightReportWizardReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInsightReportWizardReportQuery(baseOptions: Apollo.QueryHookOptions<InsightReportWizardReportQuery, InsightReportWizardReportQueryVariables> & ({ variables: InsightReportWizardReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsightReportWizardReportQuery, InsightReportWizardReportQueryVariables>(InsightReportWizardReportDocument, options);
      }
export function useInsightReportWizardReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightReportWizardReportQuery, InsightReportWizardReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsightReportWizardReportQuery, InsightReportWizardReportQueryVariables>(InsightReportWizardReportDocument, options);
        }
export function useInsightReportWizardReportSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InsightReportWizardReportQuery, InsightReportWizardReportQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InsightReportWizardReportQuery, InsightReportWizardReportQueryVariables>(InsightReportWizardReportDocument, options);
        }
export type InsightReportWizardReportQueryHookResult = ReturnType<typeof useInsightReportWizardReportQuery>;
export type InsightReportWizardReportLazyQueryHookResult = ReturnType<typeof useInsightReportWizardReportLazyQuery>;
export type InsightReportWizardReportSuspenseQueryHookResult = ReturnType<typeof useInsightReportWizardReportSuspenseQuery>;
export type InsightReportWizardReportQueryResult = Apollo.QueryResult<InsightReportWizardReportQuery, InsightReportWizardReportQueryVariables>;
export const InsightReportWizardCreateReportDocument = gql`
    mutation InsightReportWizardCreateReport($input: ReportInput!) {
  addReport(input: $input) {
    id
    name
    reportStage
    publishedTime
    statusDeadlineTime
    statusCutoffTime
  }
}
    `;
export type InsightReportWizardCreateReportMutationFn = Apollo.MutationFunction<InsightReportWizardCreateReportMutation, InsightReportWizardCreateReportMutationVariables>;

/**
 * __useInsightReportWizardCreateReportMutation__
 *
 * To run a mutation, you first call `useInsightReportWizardCreateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsightReportWizardCreateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insightReportWizardCreateReportMutation, { data, loading, error }] = useInsightReportWizardCreateReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsightReportWizardCreateReportMutation(baseOptions?: Apollo.MutationHookOptions<InsightReportWizardCreateReportMutation, InsightReportWizardCreateReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsightReportWizardCreateReportMutation, InsightReportWizardCreateReportMutationVariables>(InsightReportWizardCreateReportDocument, options);
      }
export type InsightReportWizardCreateReportMutationHookResult = ReturnType<typeof useInsightReportWizardCreateReportMutation>;
export type InsightReportWizardCreateReportMutationResult = Apollo.MutationResult<InsightReportWizardCreateReportMutation>;
export type InsightReportWizardCreateReportMutationOptions = Apollo.BaseMutationOptions<InsightReportWizardCreateReportMutation, InsightReportWizardCreateReportMutationVariables>;
export const InsightReportWizardUpdateReportDocument = gql`
    mutation InsightReportWizardUpdateReport($input: ReportInput!) {
  updateReport(input: $input) {
    id
    name
    publishedTime
    reportStage
    reportStatus {
      statusDateTime
      statusComments {
        id
        text
        type
      }
    }
  }
}
    `;
export type InsightReportWizardUpdateReportMutationFn = Apollo.MutationFunction<InsightReportWizardUpdateReportMutation, InsightReportWizardUpdateReportMutationVariables>;

/**
 * __useInsightReportWizardUpdateReportMutation__
 *
 * To run a mutation, you first call `useInsightReportWizardUpdateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsightReportWizardUpdateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insightReportWizardUpdateReportMutation, { data, loading, error }] = useInsightReportWizardUpdateReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsightReportWizardUpdateReportMutation(baseOptions?: Apollo.MutationHookOptions<InsightReportWizardUpdateReportMutation, InsightReportWizardUpdateReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsightReportWizardUpdateReportMutation, InsightReportWizardUpdateReportMutationVariables>(InsightReportWizardUpdateReportDocument, options);
      }
export type InsightReportWizardUpdateReportMutationHookResult = ReturnType<typeof useInsightReportWizardUpdateReportMutation>;
export type InsightReportWizardUpdateReportMutationResult = Apollo.MutationResult<InsightReportWizardUpdateReportMutation>;
export type InsightReportWizardUpdateReportMutationOptions = Apollo.BaseMutationOptions<InsightReportWizardUpdateReportMutation, InsightReportWizardUpdateReportMutationVariables>;