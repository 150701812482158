import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddMemberToOrgUnitFormOrgUnitQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type AddMemberToOrgUnitFormOrgUnitQuery = { __typename: 'Query', orgUnit: { __typename: 'OrgUnit', id: string, name?: string | undefined } };

export type AddMemberToOrgUnitFormUpdateOrgUnitMutationVariables = Types.Exact<{
  idToUpdate: Types.Scalars['ID']['input'];
  setUserInput: Types.SetUserInput;
  userFilterInput: Types.UserFilterInput;
}>;


export type AddMemberToOrgUnitFormUpdateOrgUnitMutation = { __typename: 'Mutation', updateOrgUnit: { __typename: 'OrgUnit', id: string, orgUnitMembersCount?: number | undefined, orgUnitMembersCascadingTotalCount?: number | undefined, orgUnitMembershipsCascadingTotalCount: number, parentOrgUnitTree: Array<{ __typename: 'OrgUnit', id: string, orgUnitMembersCount?: number | undefined, orgUnitMembersCascadingTotalCount?: number | undefined, orgUnitMembershipsCascadingTotalCount: number }>, orgUnitMembers: { __typename: 'UserConnection', edges: Array<{ __typename: 'UserEdge', node: { __typename: 'User', id: string, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined } }> } } };


export const AddMemberToOrgUnitFormOrgUnitDocument = gql`
    query AddMemberToOrgUnitFormOrgUnit($id: ID!) {
  orgUnit(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useAddMemberToOrgUnitFormOrgUnitQuery__
 *
 * To run a query within a React component, call `useAddMemberToOrgUnitFormOrgUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddMemberToOrgUnitFormOrgUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddMemberToOrgUnitFormOrgUnitQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAddMemberToOrgUnitFormOrgUnitQuery(baseOptions: Apollo.QueryHookOptions<AddMemberToOrgUnitFormOrgUnitQuery, AddMemberToOrgUnitFormOrgUnitQueryVariables> & ({ variables: AddMemberToOrgUnitFormOrgUnitQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddMemberToOrgUnitFormOrgUnitQuery, AddMemberToOrgUnitFormOrgUnitQueryVariables>(AddMemberToOrgUnitFormOrgUnitDocument, options);
      }
export function useAddMemberToOrgUnitFormOrgUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddMemberToOrgUnitFormOrgUnitQuery, AddMemberToOrgUnitFormOrgUnitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddMemberToOrgUnitFormOrgUnitQuery, AddMemberToOrgUnitFormOrgUnitQueryVariables>(AddMemberToOrgUnitFormOrgUnitDocument, options);
        }
export function useAddMemberToOrgUnitFormOrgUnitSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AddMemberToOrgUnitFormOrgUnitQuery, AddMemberToOrgUnitFormOrgUnitQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AddMemberToOrgUnitFormOrgUnitQuery, AddMemberToOrgUnitFormOrgUnitQueryVariables>(AddMemberToOrgUnitFormOrgUnitDocument, options);
        }
export type AddMemberToOrgUnitFormOrgUnitQueryHookResult = ReturnType<typeof useAddMemberToOrgUnitFormOrgUnitQuery>;
export type AddMemberToOrgUnitFormOrgUnitLazyQueryHookResult = ReturnType<typeof useAddMemberToOrgUnitFormOrgUnitLazyQuery>;
export type AddMemberToOrgUnitFormOrgUnitSuspenseQueryHookResult = ReturnType<typeof useAddMemberToOrgUnitFormOrgUnitSuspenseQuery>;
export type AddMemberToOrgUnitFormOrgUnitQueryResult = Apollo.QueryResult<AddMemberToOrgUnitFormOrgUnitQuery, AddMemberToOrgUnitFormOrgUnitQueryVariables>;
export const AddMemberToOrgUnitFormUpdateOrgUnitDocument = gql`
    mutation AddMemberToOrgUnitFormUpdateOrgUnit($idToUpdate: ID!, $setUserInput: SetUserInput!, $userFilterInput: UserFilterInput!) {
  updateOrgUnit(input: {idToUpdate: $idToUpdate, orgUnitMembers: [$setUserInput]}) {
    id
    orgUnitMembersCount
    orgUnitMembersCascadingTotalCount
    orgUnitMembershipsCascadingTotalCount
    parentOrgUnitTree {
      id
      orgUnitMembersCount
      orgUnitMembersCascadingTotalCount
      orgUnitMembershipsCascadingTotalCount
    }
    orgUnitMembers(filter: $userFilterInput) {
      edges {
        node {
          id
          orgUnit {
            id
          }
        }
      }
    }
  }
}
    `;
export type AddMemberToOrgUnitFormUpdateOrgUnitMutationFn = Apollo.MutationFunction<AddMemberToOrgUnitFormUpdateOrgUnitMutation, AddMemberToOrgUnitFormUpdateOrgUnitMutationVariables>;

/**
 * __useAddMemberToOrgUnitFormUpdateOrgUnitMutation__
 *
 * To run a mutation, you first call `useAddMemberToOrgUnitFormUpdateOrgUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMemberToOrgUnitFormUpdateOrgUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMemberToOrgUnitFormUpdateOrgUnitMutation, { data, loading, error }] = useAddMemberToOrgUnitFormUpdateOrgUnitMutation({
 *   variables: {
 *      idToUpdate: // value for 'idToUpdate'
 *      setUserInput: // value for 'setUserInput'
 *      userFilterInput: // value for 'userFilterInput'
 *   },
 * });
 */
export function useAddMemberToOrgUnitFormUpdateOrgUnitMutation(baseOptions?: Apollo.MutationHookOptions<AddMemberToOrgUnitFormUpdateOrgUnitMutation, AddMemberToOrgUnitFormUpdateOrgUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMemberToOrgUnitFormUpdateOrgUnitMutation, AddMemberToOrgUnitFormUpdateOrgUnitMutationVariables>(AddMemberToOrgUnitFormUpdateOrgUnitDocument, options);
      }
export type AddMemberToOrgUnitFormUpdateOrgUnitMutationHookResult = ReturnType<typeof useAddMemberToOrgUnitFormUpdateOrgUnitMutation>;
export type AddMemberToOrgUnitFormUpdateOrgUnitMutationResult = Apollo.MutationResult<AddMemberToOrgUnitFormUpdateOrgUnitMutation>;
export type AddMemberToOrgUnitFormUpdateOrgUnitMutationOptions = Apollo.BaseMutationOptions<AddMemberToOrgUnitFormUpdateOrgUnitMutation, AddMemberToOrgUnitFormUpdateOrgUnitMutationVariables>;