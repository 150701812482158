import type { PropsWithChildren, ReactNode } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

import type { LinkTabsProps } from 'shared/components/LinkTabs';
import LinkTabs from 'shared/components/LinkTabs';
import Layout from 'shared/components/Layout';
import { useTeamAdapter } from 'team/TeamAdapter';
import Heading from 'shared/components/Heading';
import Flex from 'shared/components/Flex';
import { teamRoutesPaths } from 'team/team.routing.paths';
import useCanPerformOrgOrOrgUnitAction from 'user/ability/useCanPerformOrgOrOrgUnitAction';
import useUserHasAuthority from 'user/ability/useUserHasAuthority';

type TeamInsightsLayoutProps = PropsWithChildren<{
  controls: ReactNode;
  subHeader?: ReactNode;
}>;

const TeamInsightsLayout = ({
  controls,
  subHeader,
  children,
}: TeamInsightsLayoutProps) => {
  const { t } = useTranslation();

  const { teamAdapter } = useTeamAdapter();

  const canSeeReports = useCanPerformOrgOrOrgUnitAction(
    'WRITE_STRATEGY',
    'WRITE',
  );

  const isSuperAdmin = useUserHasAuthority('ROLE_SUPER_ADMIN');

  const linkTabsItems = useMemo<LinkTabsProps['items']>(
    () =>
      [
        {
          name: t('overview'),
          to: teamRoutesPaths.insights.overview({
            params: { teamSlug: teamAdapter.toParam() },
          }),
        },
        canSeeReports && {
          name: t('report.report_other'),
          to: teamRoutesPaths.insights.reports.root({
            params: { teamSlug: teamAdapter.toParam() },
          }),
        },
        isSuperAdmin && {
          name: t('strategy.assessments.title'),
          to: teamRoutesPaths.insights.assessments.root({
            params: { teamSlug: teamAdapter.toParam() },
          }),
        },
      ].filter(Boolean),
    [canSeeReports, isSuperAdmin, t, teamAdapter],
  );

  return (
    <Layout.Content hasBorder={true}>
      <Flex direction={'column'} gap={'12px'}>
        <Flex justifyContent={'space-between'} alignItems={'center'} gap={8}>
          <Heading level={3} hasMargin={false} css={css({ flex: '1' })}>
            {t('team.teamInsights.title')}
          </Heading>

          {controls}
        </Flex>

        {subHeader}

        <LinkTabs items={linkTabsItems} />

        {children}
      </Flex>
    </Layout.Content>
  );
};

export default TeamInsightsLayout;
