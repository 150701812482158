import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MultiAiSuggestionsSubscriptionVariables = Types.Exact<{
  input: Types.AiSuggestionInput;
}>;


export type MultiAiSuggestionsSubscription = { __typename: 'Subscription', aiSuggestionStream: Array<{ __typename: 'AiSuggestionElement', fields: Array<{ __typename: 'KeyValue', key: string, value: string }> }> };


export const MultiAiSuggestionsDocument = gql`
    subscription MultiAiSuggestions($input: AiSuggestionInput!) {
  aiSuggestionStream(input: $input) {
    fields {
      key
      value
    }
  }
}
    `;

/**
 * __useMultiAiSuggestionsSubscription__
 *
 * To run a query within a React component, call `useMultiAiSuggestionsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMultiAiSuggestionsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMultiAiSuggestionsSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMultiAiSuggestionsSubscription(baseOptions: Apollo.SubscriptionHookOptions<MultiAiSuggestionsSubscription, MultiAiSuggestionsSubscriptionVariables> & ({ variables: MultiAiSuggestionsSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<MultiAiSuggestionsSubscription, MultiAiSuggestionsSubscriptionVariables>(MultiAiSuggestionsDocument, options);
      }
export type MultiAiSuggestionsSubscriptionHookResult = ReturnType<typeof useMultiAiSuggestionsSubscription>;
export type MultiAiSuggestionsSubscriptionResult = Apollo.SubscriptionResult<MultiAiSuggestionsSubscription>;