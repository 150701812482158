import type { TFunction } from 'i18next';
import compact from 'lodash/compact';
import { capitalize } from 'lodash';

import { initiativeOverviewRoutesPaths } from 'initiative/InitiativeOverview/InitiativeOverview.routing.paths';
import { ReactComponent as InsightsIcon } from 'shared/static/icons/icon-insights.svg';
import { ReactComponent as AttributesIcon } from 'shared/static/icons/icon-document.svg';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import type { LinkTabItem } from 'shared/components/LinkTabs/LinkTabs.type';
import { TeamAdapter } from 'team/TeamAdapter';
import type {
  Objective,
  Org,
  OrgUnit,
  Strategy,
} from 'types.graphql.generated';
import { teamRoutesPaths } from 'team/team.routing.paths';
import { strategyRoutesPaths } from 'strategy/strategy.routing.paths';
import { canPerformOrgUnitAction } from 'user/ability/canPerformOrgUnitAction';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';

type ParentOrgUnitTree = Array<
  Pick<OrgUnit, 'id' | 'name' | 'currentUserAuthorizedActions'> & {
    parentOrgUnit?: Maybe<Pick<OrgUnit, 'id'>>;
  }
>;

export const getBreadcrumbsItems = ({
  objective,
  initiativeId,
  t,
  teamAdapter,
  activeOrg,
  orgUnit,
}: {
  activeOrg: Pick<Org, 'orgKey' | 'displayName'> & {
    currentStrategy?: Pick<Strategy, 'id'>;
  };
  initiativeId: string;
  objective?: Pick<Objective, 'id' | 'currentUserAuthorizedActions'>;
  orgUnit?: Maybe<
    Pick<OrgUnit, 'id' | 'name' | 'currentUserAuthorizedActions'> & {
      parentOrgUnitTree: ParentOrgUnitTree;
    }
  >;
  t: TFunction;
  teamAdapter: TeamAdapter;
}) => {
  const parentOrgUnits: ParentOrgUnitTree = [];
  if (orgUnit?.parentOrgUnitTree?.length) {
    parentOrgUnits.push(
      orgUnit.parentOrgUnitTree.find((item) => !item?.parentOrgUnit) as OrgUnit,
    );
    for (let i = 0; i < orgUnit?.parentOrgUnitTree?.length - 1; i++) {
      const find = orgUnit.parentOrgUnitTree.find(
        (item) =>
          item.parentOrgUnit?.id ===
          parentOrgUnits[parentOrgUnits.length - 1]?.id,
      );
      if (find) {
        parentOrgUnits.push(find);
      }
    }
  }

  return compact([
    {
      name: activeOrg.displayName as string,
      to: teamRoutesPaths.root({
        params: {
          teamSlug: TeamAdapter.fromOrg(activeOrg).toParam(),
        },
      }),
    },
    ...parentOrgUnits.map((item) => ({
      name: item.name as string,
      isLocked: !canPerformOrgUnitAction(item, 'READ'),
      to: teamRoutesPaths.root({
        params: {
          teamSlug: TeamAdapter.fromOrgUnit(item, activeOrg.orgKey).toParam(),
        },
      }),
    })),
    orgUnit
      ? {
          name: orgUnit.name as string,
          isLocked: !canPerformOrgUnitAction(orgUnit, 'READ'),
          to: teamRoutesPaths.root({
            params: {
              teamSlug: TeamAdapter.fromOrgUnit(
                orgUnit,
                activeOrg.orgKey,
              ).toParam(),
            },
          }),
        }
      : undefined,
    {
      name: capitalize(t('objective.strategicObjective_other')),
      isLocked: !!orgUnit && !canPerformOrgUnitAction(orgUnit, 'READ'),
      to: strategyRoutesPaths.objectives.root({
        params: {
          teamSlug: teamAdapter.toParam(),
        },
      }),
    },
    {
      name: capitalize(t('objective.objective')),
      isLocked:
        !!objective && !canPerformStrategyElementAction(objective, 'READ'),
      to: objectiveRoutesPaths.root({
        params: {
          teamSlug: teamAdapter.toParam(),
          objectiveId: objective?.id || '',
        },
      }),
    },
    {
      name: capitalize(t('initiative.initiative')),
      to: objectiveRoutesPaths.initiatives.initiative.root({
        params: {
          teamSlug: teamAdapter.toParam(),
          initiativeId,
          objectiveId: objective?.id || '',
        },
      }),
    },
  ]);
};

export const getNavigationItems = (t: TFunction): LinkTabItem[] => [
  {
    name: t('initiative.initiativeLayout.header.navigation.insights'),
    icon: InsightsIcon,
    to: initiativeOverviewRoutesPaths.insights.root(),
    end: true,
  },
  {
    name: t('initiative.initiativeLayout.header.navigation.attributes'),
    to: initiativeOverviewRoutesPaths.attributes(),
    icon: AttributesIcon,
    end: true,
  },
];
