import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { uniq } from 'lodash';

import useScrollToBottom from 'shared/hooks/useScrollToBottom';
import { transientOptions } from 'shared/utils/emotion.utils';
import Text from 'shared/components/Text';
import Flex from 'shared/components/Flex';
import useDialogState from 'shared/hooks/useDialogState';
import Button from 'shared/components/Button';
import Dialog from 'shared/components/Dialog';
import { ReactComponent as ArrowLeftIcon } from 'shared/static/icons/icon-arrow-left.svg';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';

import Start from './Start';
import MessageItem from './MessageItem';
import FreeTextPrompt from './FreeTextPrompt';
import Prompt from './Prompt';
import type {
  Message,
  PromptCategory,
  PromptMessage,
} from './AskSophieChat.type';

const Container = styled.div`
  min-height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
`;

const StartContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const MessagesContainer = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  overflow-y: auto;
`;

const Messages = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 24px;
  gap: 16px;
  padding: 16px 16px 0 16px;
`;

const PromptContainer = styled('div', transientOptions)<{
  $isScrolledToBottom: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 24px;
  box-shadow: ${(props) =>
    props.$isScrolledToBottom
      ? 'none'
      : '0px 15px 12px 10px rgba(0, 0, 0, 0.8)'};
  z-index: 10;
`;

const primaryPromptMessagesCount = 5;

type Props = {
  hideFreeText?: boolean;
  infoText: string;
  isOpen: boolean;
  promptMessages: PromptMessage[];
};

const AskSophieChat = ({
  isOpen,
  promptMessages,
  infoText,
  hideFreeText,
}: Props) => {
  const { t } = useTranslation();
  const {
    isScrolledToBottom,
    scrollToBottom,
    setScrollableDiv,
    setGrowableDiv,
  } = useScrollToBottom();

  const {
    isOpen: isPromptExpanded,
    onOpen: onPromptExpanded,
    onClose: onPromptCollapsed,
  } = useDialogState();

  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);

  const addMessage = (message: Message) => {
    setMessages((messages) => [...messages, message]);
    setTimeout(() => {
      scrollToBottom('smooth');
    }, 250);
  };

  const isChatEmpty = messages.length === 0;

  const [selectedCategory, setSelectedCategory] = useState<PromptCategory>();

  useEffect(() => {
    if (!isOpen) {
      setSelectedCategory(undefined);
      setMessages([]);
      setIsLoading(false);
    }
  }, [isOpen]);

  const categories = uniq(
    promptMessages.map((message) => message.category).filter(Boolean),
  );
  const hasCategories = categories.length > 0;

  const filteredMessages = promptMessages.filter(
    (message) => !hasCategories || message.category === selectedCategory,
  );

  return (
    <>
      <Container>
        {isChatEmpty ? (
          <StartContainer>
            <Start infoText={infoText} />
          </StartContainer>
        ) : (
          <MessagesContainer ref={setScrollableDiv}>
            <Messages ref={setGrowableDiv}>
              {messages.map((message, index) => (
                <MessageItem
                  key={`${index}-${message.type}`}
                  message={message}
                  setIsLoading={setIsLoading}
                />
              ))}
            </Messages>
          </MessagesContainer>
        )}

        <PromptContainer
          $isScrolledToBottom={isChatEmpty ? true : isScrolledToBottom}
        >
          {!hideFreeText && (
            <FreeTextPrompt addMessage={addMessage} isLoading={isLoading} />
          )}
          {!selectedCategory && (
            <Flex justifyContent={'center'} gap={8} wrap={'wrap'}>
              {categories.map((category) => (
                <Button
                  key={category}
                  variant={'outlined'}
                  onPress={() => setSelectedCategory(category)}
                  isDisabled={isLoading}
                >
                  {t(`assessment.category.${category}`)}
                </Button>
              ))}
            </Flex>
          )}
          <Flex direction={'column'} gap={8}>
            <Prompt
              messages={filteredMessages.slice(0, primaryPromptMessagesCount)}
              addMessage={addMessage}
              isLoading={isLoading}
            />
            <Flex justifyContent={'center'} gap={8}>
              {selectedCategory && (
                <Button
                  variant={'simple'}
                  color={'primary'}
                  onPress={() => setSelectedCategory(undefined)}
                  isDisabled={isLoading}
                  icon={ArrowLeftIcon}
                  iconPosition={'start'}
                >
                  {t('ai.strategySummary.control.showCategories')}
                </Button>
              )}
              {filteredMessages.length > primaryPromptMessagesCount && (
                <Button
                  variant={'simple'}
                  onPress={onPromptExpanded}
                  isDisabled={isLoading}
                  icon={PlusIcon}
                  iconPosition={'start'}
                >
                  {t('ai.strategySummary.control.showMore')}
                </Button>
              )}
            </Flex>
          </Flex>
          <Text size={'tiny'} isCentered={true} css={css({ maxWidth: 500 })}>
            {t('ai.strategySummary.disclaimer')}
          </Text>
        </PromptContainer>
      </Container>

      <Dialog
        isOpen={isPromptExpanded}
        onClose={onPromptCollapsed}
        headerTitle={t('more')}
        size={'big'}
      >
        <Prompt
          messages={filteredMessages}
          isLoading={isLoading}
          addMessage={(message: Message) => {
            onPromptCollapsed();
            addMessage(message);
          }}
        />
      </Dialog>
    </>
  );
};

export default AskSophieChat;
