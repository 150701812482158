import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

import Drawer from 'shared/components/Drawer';
import Tag from 'shared/components/Tag';
import type { PromptMessage } from 'ai/AskSophieChat';
import AskSophieChat from 'ai/AskSophieChat';

type Props = {
  infoText: string;
  isOpen: boolean;
  onClose: () => void;
  promptMessages: PromptMessage[];
};

const AskSophieSidePanel = ({
  isOpen,
  onClose,
  promptMessages,
  infoText,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      width={'medium'}
      heading={
        <>
          {t('ai.strategySummary.title')}
          <Tag variant={'primary'} css={css({ marginLeft: 12 })}>
            {t('ai.strategySummary.experimental')}
          </Tag>
        </>
      }
      padding={'none'}
      scrollbarGutter={'auto'}
    >
      <AskSophieChat
        isOpen={isOpen}
        infoText={infoText}
        promptMessages={promptMessages}
      />
    </Drawer>
  );
};

export default AskSophieSidePanel;
