import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ThemeQueryVariables = Types.Exact<{
  themeId: Types.Scalars['ID']['input'];
}>;


export type ThemeQuery = { __typename: 'Query', theme: { __typename: 'Theme', description?: string | undefined, id: string, name?: string | undefined, isActive: boolean, orderNumber?: number | undefined, themeCategory?: { __typename: 'ThemeCategory', id: string, name?: string | undefined } | undefined } };

export type ThemeProviderThemeFragment = { __typename: 'Theme', description?: string | undefined, id: string, name?: string | undefined, isActive: boolean, orderNumber?: number | undefined, themeCategory?: { __typename: 'ThemeCategory', id: string, name?: string | undefined } | undefined };

export type ThemeCategoryFragment = { __typename: 'ThemeCategory', id: string, name?: string | undefined };

export type AddThemeMutationVariables = Types.Exact<{
  input: Types.ThemeInput;
}>;


export type AddThemeMutation = { __typename: 'Mutation', addTheme: { __typename: 'Theme', description?: string | undefined, id: string, name?: string | undefined, isActive: boolean, orderNumber?: number | undefined, themeCategory?: { __typename: 'ThemeCategory', id: string, name?: string | undefined } | undefined } };

export type UpdateThemeMutationVariables = Types.Exact<{
  input: Types.ThemeInput;
}>;


export type UpdateThemeMutation = { __typename: 'Mutation', updateTheme: { __typename: 'Theme', description?: string | undefined, id: string, name?: string | undefined, isActive: boolean, orderNumber?: number | undefined, themeCategory?: { __typename: 'ThemeCategory', id: string, name?: string | undefined } | undefined } };

export type DeleteThemeMutationVariables = Types.Exact<{
  themeId: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;


export type DeleteThemeMutation = { __typename: 'Mutation', deleteThemes: boolean };

export const ThemeCategoryFragmentDoc = gql`
    fragment themeCategory on ThemeCategory {
  id
  name
}
    `;
export const ThemeProviderThemeFragmentDoc = gql`
    fragment themeProviderTheme on Theme {
  description
  id
  name
  isActive
  orderNumber
  themeCategory {
    ...themeCategory
  }
}
    ${ThemeCategoryFragmentDoc}`;
export const ThemeDocument = gql`
    query Theme($themeId: ID!) {
  theme(id: $themeId) {
    ...themeProviderTheme
  }
}
    ${ThemeProviderThemeFragmentDoc}`;

/**
 * __useThemeQuery__
 *
 * To run a query within a React component, call `useThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThemeQuery({
 *   variables: {
 *      themeId: // value for 'themeId'
 *   },
 * });
 */
export function useThemeQuery(baseOptions: Apollo.QueryHookOptions<ThemeQuery, ThemeQueryVariables> & ({ variables: ThemeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThemeQuery, ThemeQueryVariables>(ThemeDocument, options);
      }
export function useThemeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThemeQuery, ThemeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThemeQuery, ThemeQueryVariables>(ThemeDocument, options);
        }
export function useThemeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ThemeQuery, ThemeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ThemeQuery, ThemeQueryVariables>(ThemeDocument, options);
        }
export type ThemeQueryHookResult = ReturnType<typeof useThemeQuery>;
export type ThemeLazyQueryHookResult = ReturnType<typeof useThemeLazyQuery>;
export type ThemeSuspenseQueryHookResult = ReturnType<typeof useThemeSuspenseQuery>;
export type ThemeQueryResult = Apollo.QueryResult<ThemeQuery, ThemeQueryVariables>;
export const AddThemeDocument = gql`
    mutation AddTheme($input: ThemeInput!) {
  addTheme(input: $input) {
    ...themeProviderTheme
  }
}
    ${ThemeProviderThemeFragmentDoc}`;
export type AddThemeMutationFn = Apollo.MutationFunction<AddThemeMutation, AddThemeMutationVariables>;

/**
 * __useAddThemeMutation__
 *
 * To run a mutation, you first call `useAddThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addThemeMutation, { data, loading, error }] = useAddThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddThemeMutation(baseOptions?: Apollo.MutationHookOptions<AddThemeMutation, AddThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddThemeMutation, AddThemeMutationVariables>(AddThemeDocument, options);
      }
export type AddThemeMutationHookResult = ReturnType<typeof useAddThemeMutation>;
export type AddThemeMutationResult = Apollo.MutationResult<AddThemeMutation>;
export type AddThemeMutationOptions = Apollo.BaseMutationOptions<AddThemeMutation, AddThemeMutationVariables>;
export const UpdateThemeDocument = gql`
    mutation UpdateTheme($input: ThemeInput!) {
  updateTheme(input: $input) {
    ...themeProviderTheme
  }
}
    ${ThemeProviderThemeFragmentDoc}`;
export type UpdateThemeMutationFn = Apollo.MutationFunction<UpdateThemeMutation, UpdateThemeMutationVariables>;

/**
 * __useUpdateThemeMutation__
 *
 * To run a mutation, you first call `useUpdateThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateThemeMutation, { data, loading, error }] = useUpdateThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateThemeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateThemeMutation, UpdateThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateThemeMutation, UpdateThemeMutationVariables>(UpdateThemeDocument, options);
      }
export type UpdateThemeMutationHookResult = ReturnType<typeof useUpdateThemeMutation>;
export type UpdateThemeMutationResult = Apollo.MutationResult<UpdateThemeMutation>;
export type UpdateThemeMutationOptions = Apollo.BaseMutationOptions<UpdateThemeMutation, UpdateThemeMutationVariables>;
export const DeleteThemeDocument = gql`
    mutation DeleteTheme($themeId: [ID!]!) {
  deleteThemes(idsToDelete: $themeId)
}
    `;
export type DeleteThemeMutationFn = Apollo.MutationFunction<DeleteThemeMutation, DeleteThemeMutationVariables>;

/**
 * __useDeleteThemeMutation__
 *
 * To run a mutation, you first call `useDeleteThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteThemeMutation, { data, loading, error }] = useDeleteThemeMutation({
 *   variables: {
 *      themeId: // value for 'themeId'
 *   },
 * });
 */
export function useDeleteThemeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteThemeMutation, DeleteThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteThemeMutation, DeleteThemeMutationVariables>(DeleteThemeDocument, options);
      }
export type DeleteThemeMutationHookResult = ReturnType<typeof useDeleteThemeMutation>;
export type DeleteThemeMutationResult = Apollo.MutationResult<DeleteThemeMutation>;
export type DeleteThemeMutationOptions = Apollo.BaseMutationOptions<DeleteThemeMutation, DeleteThemeMutationVariables>;