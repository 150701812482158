import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteAssessmentMutationVariables = Types.Exact<{
  assessmentId: Types.Scalars['ID']['input'];
}>;


export type DeleteAssessmentMutation = { __typename: 'Mutation', deleteStrategyAssessments: boolean };


export const DeleteAssessmentDocument = gql`
    mutation DeleteAssessment($assessmentId: ID!) {
  deleteStrategyAssessments(idsToDelete: [$assessmentId])
}
    `;
export type DeleteAssessmentMutationFn = Apollo.MutationFunction<DeleteAssessmentMutation, DeleteAssessmentMutationVariables>;

/**
 * __useDeleteAssessmentMutation__
 *
 * To run a mutation, you first call `useDeleteAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssessmentMutation, { data, loading, error }] = useDeleteAssessmentMutation({
 *   variables: {
 *      assessmentId: // value for 'assessmentId'
 *   },
 * });
 */
export function useDeleteAssessmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAssessmentMutation, DeleteAssessmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAssessmentMutation, DeleteAssessmentMutationVariables>(DeleteAssessmentDocument, options);
      }
export type DeleteAssessmentMutationHookResult = ReturnType<typeof useDeleteAssessmentMutation>;
export type DeleteAssessmentMutationResult = Apollo.MutationResult<DeleteAssessmentMutation>;
export type DeleteAssessmentMutationOptions = Apollo.BaseMutationOptions<DeleteAssessmentMutation, DeleteAssessmentMutationVariables>;