import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';

import OverviewRow from 'shared/components/OverviewRow';
import Caption from 'shared/components/Caption/Caption';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';
import MaybeLinkIcon from 'objective/LinkIcon/MaybeLinkIcon';

import styles from './ObjectiveOverviewContent.module.scss';
import ObjectiveOverviewNav from '../ObjectiveOverviewNav';
import ObjectiveOverviewInsights from '../ObjectiveOverviewInsights';
import { useObjectiveOverview } from '../ObjectiveOverviewProvider/useObjectiveOverview';

const ObjectiveOverviewContent = () => {
  const { t } = useTranslation();

  const { objective } = useObjectiveOverview();

  const { teamAdapter } = useTeamAdapter();

  const hasMetrics = !!objective?.metrics.length;
  const hasInitiatives = !!objective?.initiatives.length;

  return (
    <div className={styles.container}>
      <ObjectiveOverviewNav />
      <article className={styles.sections}>
        <section className={styles.section}>
          <Caption size={'C2'} className={styles.caption}>
            {capitalize(t('metric.metric_other'))}
          </Caption>
          <ul className={styles.list}>
            {hasMetrics ? (
              objective.metrics.map((metric) => (
                <OverviewRow
                  linkTo={objectiveRoutesPaths.metrics.metric.root({
                    params: {
                      teamSlug: teamAdapter.toParam(),
                      objectiveId: objective.id,
                      metricId: metric.id,
                    },
                  })}
                  statusIndicator={metric.currentMetricStatus?.statusIndicator}
                  user={metric.owner}
                  orgUnit={metric.orgUnit}
                  textContent={metric.name}
                  key={metric.id}
                >
                  <MaybeLinkIcon
                    strategyElement={metric}
                    contextObjective={objective}
                  />
                </OverviewRow>
              ))
            ) : (
              <OverviewRow
                textContent={t('objective.overview.metrics.noMetrics')}
              />
            )}
          </ul>
        </section>
        <section className={styles.section}>
          <Caption size={'C2'} className={styles.caption}>
            {capitalize(t('initiative.initiative_other'))}
          </Caption>
          <ul className={styles.list}>
            {hasInitiatives ? (
              objective.initiatives.map((initiative) => (
                <OverviewRow
                  linkTo={objectiveRoutesPaths.initiatives.initiative.root({
                    params: {
                      teamSlug: teamAdapter.toParam(),
                      objectiveId: objective.id,
                      initiativeId: initiative.id,
                    },
                  })}
                  statusIndicator={
                    initiative.currentInitiativeStatus?.statusIndicator
                  }
                  user={initiative.owner}
                  orgUnit={initiative.orgUnit}
                  textContent={initiative.name}
                  key={initiative.id}
                >
                  <MaybeLinkIcon
                    strategyElement={initiative}
                    contextObjective={objective}
                  />
                </OverviewRow>
              ))
            ) : (
              <OverviewRow
                textContent={t('objective.overview.initiatives.noInitiatives')}
              />
            )}
          </ul>
        </section>
      </article>
      <ObjectiveOverviewInsights />
    </div>
  );
};

export default ObjectiveOverviewContent;
