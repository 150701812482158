import type { RouteObject } from 'react-router-dom';

import ViewAssessment from 'assessment/ViewAssessment';

import { assessmentRoutesPaths } from './assessment.routing.paths';

export const assessmentRoutes: RouteObject[] = [
  {
    path: assessmentRoutesPaths.view(),
    element: <ViewAssessment />,
  },
];
