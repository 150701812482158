import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { useToasts } from 'shared/toast/useToasts';
import useHandleError from 'shared/errors/useHandleError';

import type { OrgUnitForDeleteFragment } from './useDeleteOrgUnit.graphql.generated';
import { useDeleteOrgUnitDeleteOrgUnitMutation } from './useDeleteOrgUnit.graphql.generated';

export type UseDeleteOrgUnitParams = {
  onDelete?: () => void;
  orgUnit: OrgUnitForDeleteFragment;
};

export type UseDeleteOrgUnitReturnType = {
  deleteOrgUnit: () => void;
  isDeletingOrgUnit: boolean;
};

export const useDeleteOrgUnit = ({
  orgUnit,
  onDelete,
}: UseDeleteOrgUnitParams): UseDeleteOrgUnitReturnType => {
  const { t } = useTranslation();

  const { addToast } = useToasts();

  const onError = useHandleError();

  const [deleteOrgUnit, { loading }] = useDeleteOrgUnitDeleteOrgUnitMutation({
    variables: {
      id: orgUnit.id,
    },
    update(cache) {
      setTimeout(() => cache.evict({ id: `OrgUnit:${orgUnit.id}` }), 100);
    },
  });

  const handleDeleteOrgUnit = useCallback(
    () =>
      deleteOrgUnit({
        onCompleted: () => {
          addToast({
            children: t('team.deleteTeamSuccessToast', {
              teamName: orgUnit.name,
            }),
            variant: 'success',
          });
          onDelete?.();
        },
        onError,
      }),
    [addToast, deleteOrgUnit, onDelete, onError, orgUnit.name, t],
  );

  return {
    deleteOrgUnit: handleDeleteOrgUnit,
    isDeletingOrgUnit: loading,
  };
};
