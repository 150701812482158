import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteOneThingCampaignMutationVariables = Types.Exact<{
  campaignId: Types.Scalars['ID']['input'];
}>;


export type DeleteOneThingCampaignMutation = { __typename: 'Mutation', deleteOneThingCampaigns: boolean };


export const DeleteOneThingCampaignDocument = gql`
    mutation DeleteOneThingCampaign($campaignId: ID!) {
  deleteOneThingCampaigns(idsToDelete: [$campaignId])
}
    `;
export type DeleteOneThingCampaignMutationFn = Apollo.MutationFunction<DeleteOneThingCampaignMutation, DeleteOneThingCampaignMutationVariables>;

/**
 * __useDeleteOneThingCampaignMutation__
 *
 * To run a mutation, you first call `useDeleteOneThingCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneThingCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneThingCampaignMutation, { data, loading, error }] = useDeleteOneThingCampaignMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useDeleteOneThingCampaignMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneThingCampaignMutation, DeleteOneThingCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneThingCampaignMutation, DeleteOneThingCampaignMutationVariables>(DeleteOneThingCampaignDocument, options);
      }
export type DeleteOneThingCampaignMutationHookResult = ReturnType<typeof useDeleteOneThingCampaignMutation>;
export type DeleteOneThingCampaignMutationResult = Apollo.MutationResult<DeleteOneThingCampaignMutation>;
export type DeleteOneThingCampaignMutationOptions = Apollo.BaseMutationOptions<DeleteOneThingCampaignMutation, DeleteOneThingCampaignMutationVariables>;