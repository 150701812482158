import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CloseCampaignMutationVariables = Types.Exact<{
  orgKey: Types.Scalars['String']['input'];
  campaignId: Types.Scalars['ID']['input'];
}>;


export type CloseCampaignMutation = { __typename: 'Mutation', saveCampaign: { __typename: 'Campaign', id: string, state: { __typename: 'CampaignState', stage: Types.CampaignStage } } };


export const CloseCampaignDocument = gql`
    mutation CloseCampaign($orgKey: String!, $campaignId: ID!) {
  saveCampaign(
    input: {orgKey: $orgKey, idToUpdate: $campaignId, event: CLOSE_BY_USER}
  ) {
    id
    state {
      stage
    }
  }
}
    `;
export type CloseCampaignMutationFn = Apollo.MutationFunction<CloseCampaignMutation, CloseCampaignMutationVariables>;

/**
 * __useCloseCampaignMutation__
 *
 * To run a mutation, you first call `useCloseCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeCampaignMutation, { data, loading, error }] = useCloseCampaignMutation({
 *   variables: {
 *      orgKey: // value for 'orgKey'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useCloseCampaignMutation(baseOptions?: Apollo.MutationHookOptions<CloseCampaignMutation, CloseCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseCampaignMutation, CloseCampaignMutationVariables>(CloseCampaignDocument, options);
      }
export type CloseCampaignMutationHookResult = ReturnType<typeof useCloseCampaignMutation>;
export type CloseCampaignMutationResult = Apollo.MutationResult<CloseCampaignMutation>;
export type CloseCampaignMutationOptions = Apollo.BaseMutationOptions<CloseCampaignMutation, CloseCampaignMutationVariables>;