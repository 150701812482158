import type { Entries } from 'type-fest';

import type {
  Initiative,
  Metric,
  Objective,
  Theme,
} from 'types.graphql.generated';

import type { SubmitData } from './SelectableStrategyItems.type';
import type { SelectableStrategyItemsObjectiveFragment } from './SelectableStrategyItemsProvider';

export const getThemes = (
  objectives: SelectableStrategyItemsObjectiveFragment[],
): Pick<Theme, 'id' | 'name' | 'orderNumber'>[] => {
  const extractedThemes = objectives.reduce<{
    [themeId in Theme['id']]: Pick<Theme, 'name' | 'orderNumber'>;
  }>((accumulator, objective) => {
    const objectiveTheme = objective.theme;
    if (objectiveTheme && !accumulator[objectiveTheme?.id]) {
      accumulator[objectiveTheme?.id] = {
        name: objectiveTheme.name,
        orderNumber: objectiveTheme.orderNumber,
      };
    }
    return accumulator;
  }, {});
  return (Object.entries(extractedThemes) as Entries<typeof extractedThemes>)
    .map(([themeId, theme]) => ({
      id: themeId,
      name: theme.name,
      orderNumber: theme.orderNumber,
    }))
    .sort((previousTheme, nextTheme) => {
      if (previousTheme.orderNumber && nextTheme.orderNumber) {
        return previousTheme.orderNumber - nextTheme.orderNumber;
      }
      return 0;
    });
};

export const getAllIds = (
  objectives: SelectableStrategyItemsObjectiveFragment[],
) =>
  objectives.reduce<{
    count: number;
    initiativesIds: Initiative['id'][];
    metricsIds: Metric['id'][];
    objectivesIds: Objective['id'][];
  }>(
    (accumulator, objective) => {
      accumulator.objectivesIds.push(objective.id);
      accumulator.count++;
      objective.metrics.forEach((metric) => {
        accumulator.metricsIds.push(metric.id);
        accumulator.count++;
      });
      objective.initiatives.forEach((initiative) => {
        accumulator.initiativesIds.push(initiative.id);
        accumulator.count++;
      });
      return accumulator;
    },
    {
      initiativesIds: [],
      metricsIds: [],
      objectivesIds: [],
      count: 0,
    },
  );

export const getObjectiveIdFromObjectiveItem = (
  objectiveItemId: string,
  objectives: SelectableStrategyItemsObjectiveFragment[],
) =>
  objectives.find((objective) => {
    const hasMetric = !!objective.metrics.find(
      (metric) => metric.id === objectiveItemId,
    );
    const hasInitiative = !!objective.initiatives.find(
      (initiative) => initiative.id === objectiveItemId,
    );
    return hasMetric || hasInitiative;
  })!;

export const getObjectiveItemsFromObjective = (
  objectiveId: Objective['id'],
  objectives: SelectableStrategyItemsObjectiveFragment[],
) => {
  const objective = objectives.find(
    (objective) => objective.id === objectiveId,
  )!;
  return {
    initiatives: objective.initiatives,
    metrics: objective.metrics,
  };
};

export const resolveSubmitData = (params: {
  initiativeIds: Initiative['id'][];
  metricIds: Metric['id'][];
  objectiveIds: Objective['id'][];
  objectives: SelectableStrategyItemsObjectiveFragment[];
}): SubmitData => {
  const { objectives, metricIds, initiativeIds, objectiveIds } = params;

  const mappedObjectives = objectiveIds
    .map((objectiveId) => {
      const currentObjective = objectives.find(
        (objective) => objective.id === objectiveId,
      );

      if (!currentObjective) return;

      const mappedMetricIds = currentObjective.metrics
        .filter((metric) =>
          metricIds.find((metricId) => metricId === metric.id),
        )
        .map((metric) => metric.id);

      const mappedInitiativeIds = currentObjective.initiatives
        .filter((initiative) =>
          initiativeIds.find((initiativeId) => initiativeId === initiative.id),
        )
        .map((initiative) => initiative.id);

      return {
        objectiveId,
        metricIds: mappedMetricIds,
        initiativeIds: mappedInitiativeIds,
      };
    })
    .filter(Boolean);

  return {
    objectives: mappedObjectives,
  };
};
