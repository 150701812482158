import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UseDeleteStrategyDeleteStrategyMutationVariables = Types.Exact<{
  strategyId: Types.Scalars['ID']['input'];
}>;


export type UseDeleteStrategyDeleteStrategyMutation = { __typename: 'Mutation', deleteStrategies: boolean };


export const UseDeleteStrategyDeleteStrategyDocument = gql`
    mutation UseDeleteStrategyDeleteStrategy($strategyId: ID!) {
  deleteStrategies(idsToDelete: [$strategyId])
}
    `;
export type UseDeleteStrategyDeleteStrategyMutationFn = Apollo.MutationFunction<UseDeleteStrategyDeleteStrategyMutation, UseDeleteStrategyDeleteStrategyMutationVariables>;

/**
 * __useUseDeleteStrategyDeleteStrategyMutation__
 *
 * To run a mutation, you first call `useUseDeleteStrategyDeleteStrategyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUseDeleteStrategyDeleteStrategyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [useDeleteStrategyDeleteStrategyMutation, { data, loading, error }] = useUseDeleteStrategyDeleteStrategyMutation({
 *   variables: {
 *      strategyId: // value for 'strategyId'
 *   },
 * });
 */
export function useUseDeleteStrategyDeleteStrategyMutation(baseOptions?: Apollo.MutationHookOptions<UseDeleteStrategyDeleteStrategyMutation, UseDeleteStrategyDeleteStrategyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UseDeleteStrategyDeleteStrategyMutation, UseDeleteStrategyDeleteStrategyMutationVariables>(UseDeleteStrategyDeleteStrategyDocument, options);
      }
export type UseDeleteStrategyDeleteStrategyMutationHookResult = ReturnType<typeof useUseDeleteStrategyDeleteStrategyMutation>;
export type UseDeleteStrategyDeleteStrategyMutationResult = Apollo.MutationResult<UseDeleteStrategyDeleteStrategyMutation>;
export type UseDeleteStrategyDeleteStrategyMutationOptions = Apollo.BaseMutationOptions<UseDeleteStrategyDeleteStrategyMutation, UseDeleteStrategyDeleteStrategyMutationVariables>;