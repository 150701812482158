import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { transientOptions } from 'shared/utils/emotion.utils';
import StatusMark from 'shared/status/StatusMark';
import UserAvatar from 'user/UserAvatar';
import type { StatusIndicator } from 'shared/status/StatusIndicator';
import Flex from 'shared/components/Flex';
import LinkIcon from 'objective/LinkIcon';
import type { OrgUnitForTeamImageFragment } from 'orgUnit/OrgUnit.graphql.generated';
import type { OrgUnitWithAuthorizedActions } from 'user/ability/canPerformOrgUnitAction';
import TeamAvatar from 'team/TeamAvatar/TeamAvatar';
import LockedLink from 'shared/components/LockedLink';
import OptionalLink from 'shared/components/OptionalLink';

import type { StrategyOperationalItemOwner } from '../StrategyBoardList/StrategyBoardList.type';

const Container = styled(OptionalLink, transientOptions)<{
  $hasLink: boolean;
  $isActive: boolean;
  $isDisabled?: boolean;
}>`
  border: 1px solid
    ${({ theme, $hasLink }) =>
      $hasLink ? theme.color.strokeMedium : theme.color.strokeLight};
  border-radius: 8px;
  color: ${({ theme, $hasLink }) =>
    $hasLink ? theme.color.typoPrimary : theme.color.neutral2};
  display: flex;
  align-items: flex-start;
  gap: 14px;
  overflow: hidden;
  padding: 5px 10px 5px 15px;
  position: relative;
  opacity: ${(props) => (props.$isDisabled ? 0.5 : 1)};
  text-decoration: none;

  &:hover {
    background-color: ${({ theme, $hasLink, $isActive }) =>
      $hasLink && $isActive && theme.color.hoverLight};
    opacity: 1;
  }
`;

const ItemName = styled.div`
  margin-top: 3.5px;
  flex-grow: 1;
`;

const AvatarContainer = styled.div`
  height: 28px;
  width: 28px;
`;

type Props = {
  isDisabled?: boolean;
  isLink: boolean;
  isLocked: boolean;
  linkTo?: string;
  orgUnit?: OrgUnitForTeamImageFragment & OrgUnitWithAuthorizedActions;
  owner?: StrategyOperationalItemOwner;
  statusIndicator?: StatusIndicator;
  textContent?: string;
};

const StrategyBoardListRow = ({
  linkTo = '',
  statusIndicator,
  textContent,
  owner,
  isLink,
  isLocked,
  isDisabled,
  orgUnit,
}: Props) => {
  const hasLink = !!linkTo;

  return (
    <Container
      $hasLink={hasLink}
      $isDisabled={isDisabled}
      $isActive={!isLocked}
      isActive={!isLocked}
      to={linkTo}
    >
      {linkTo && (
        <Flex alignItems={'center'} css={css({ height: 28 })}>
          <StatusMark statusIndicator={statusIndicator} hasBorder={false} />
        </Flex>
      )}

      <ItemName>
        {hasLink ? (
          <LockedLink to={linkTo} isActive={!isLocked} isInline={true}>
            {textContent}
          </LockedLink>
        ) : (
          <>{textContent}</>
        )}
        {isLink && <LinkIcon isInline={true} css={css({ marginLeft: 8 })} />}
      </ItemName>

      {hasLink && <TeamAvatar orgUnit={orgUnit} size={'micro'} />}

      {owner ? <UserAvatar user={owner} /> : <AvatarContainer />}
    </Container>
  );
};

export default StrategyBoardListRow;
