import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { AuditRecordFragmentDoc } from '../StrategyProvider/StrategyProvider.graphql.generated';
import { UserLimitedProfileFragmentDoc } from '../../user/userProfile.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateStrategyDetailsMutationVariables = Types.Exact<{
  input: Types.StrategyInput;
}>;


export type UpdateStrategyDetailsMutation = { __typename: 'Mutation', updateStrategy: { __typename: 'Strategy', id: string, name?: string | undefined, timeLine?: { __typename: 'TimeLine', startDate?: Date | undefined, endDate?: Date | undefined } | undefined } };

export type UpdateStrategyMissionMutationVariables = Types.Exact<{
  input: Types.StrategyInput;
}>;


export type UpdateStrategyMissionMutation = { __typename: 'Mutation', updateStrategy: { __typename: 'Strategy', id: string, mission?: { __typename: 'StrategyItem', id: string, name?: string | undefined, symbol?: string | undefined, title?: string | undefined, description?: string | undefined, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined, auditRecord: { __typename: 'AuditRecord', createDateTime?: Date | undefined, updateDateTime?: Date | undefined } } | undefined } };

export type UpdateStrategyVisionMutationVariables = Types.Exact<{
  input: Types.StrategyInput;
}>;


export type UpdateStrategyVisionMutation = { __typename: 'Mutation', updateStrategy: { __typename: 'Strategy', id: string, vision?: { __typename: 'StrategyItem', id: string, name?: string | undefined, symbol?: string | undefined, title?: string | undefined, description?: string | undefined, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined, auditRecord: { __typename: 'AuditRecord', createDateTime?: Date | undefined, updateDateTime?: Date | undefined } } | undefined } };

export type UpdateStrategyGoalsMutationVariables = Types.Exact<{
  input: Types.StrategyInput;
}>;


export type UpdateStrategyGoalsMutation = { __typename: 'Mutation', updateStrategy: { __typename: 'Strategy', id: string, goals: Array<{ __typename: 'StrategyItem', id: string, name?: string | undefined, symbol?: string | undefined, title?: string | undefined, description?: string | undefined, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined, auditRecord: { __typename: 'AuditRecord', createDateTime?: Date | undefined, updateDateTime?: Date | undefined } }> } };

export type UpdateStrategyValuesMutationVariables = Types.Exact<{
  input: Types.StrategyInput;
}>;


export type UpdateStrategyValuesMutation = { __typename: 'Mutation', updateStrategy: { __typename: 'Strategy', id: string, values: Array<{ __typename: 'StrategyItem', id: string, name?: string | undefined, symbol?: string | undefined, title?: string | undefined, description?: string | undefined, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined, auditRecord: { __typename: 'AuditRecord', createDateTime?: Date | undefined, updateDateTime?: Date | undefined } }> } };

export type UpdateStrategyChoicesMutationVariables = Types.Exact<{
  input: Types.StrategyInput;
}>;


export type UpdateStrategyChoicesMutation = { __typename: 'Mutation', updateStrategy: { __typename: 'Strategy', id: string, choices: Array<{ __typename: 'StrategyItem', id: string, name?: string | undefined, symbol?: string | undefined, title?: string | undefined, description?: string | undefined, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined, auditRecord: { __typename: 'AuditRecord', createDateTime?: Date | undefined, updateDateTime?: Date | undefined } }> } };

export type UpdateStrategyBehaviorsMutationVariables = Types.Exact<{
  input: Types.StrategyInput;
}>;


export type UpdateStrategyBehaviorsMutation = { __typename: 'Mutation', updateStrategy: { __typename: 'Strategy', id: string, behaviors: Array<{ __typename: 'StrategyBehaviorSet', id: string, name?: string | undefined, title?: string | undefined, description?: string | undefined, behaviorSetGroups: Array<{ __typename: 'StrategyBehaviorSetGroup', id: string, name?: string | undefined, description?: string | undefined, items: Array<{ __typename: 'TextItem', id: string, text?: string | undefined, auditRecord?: { __typename: 'AuditRecord', createDateTime?: Date | undefined, updateDateTime?: Date | undefined } | undefined }>, auditRecord: { __typename: 'AuditRecord', createDateTime?: Date | undefined, updateDateTime?: Date | undefined } }>, auditRecord: { __typename: 'AuditRecord', createDateTime?: Date | undefined, updateDateTime?: Date | undefined } }> } };

export type EditStrategyWizardStrategyBehaviorSetFragment = { __typename: 'StrategyBehaviorSet', id: string, name?: string | undefined, title?: string | undefined, description?: string | undefined, behaviorSetGroups: Array<{ __typename: 'StrategyBehaviorSetGroup', id: string, name?: string | undefined, description?: string | undefined, items: Array<{ __typename: 'TextItem', id: string, text?: string | undefined, auditRecord?: { __typename: 'AuditRecord', createDateTime?: Date | undefined, updateDateTime?: Date | undefined } | undefined }>, auditRecord: { __typename: 'AuditRecord', createDateTime?: Date | undefined, updateDateTime?: Date | undefined } }>, auditRecord: { __typename: 'AuditRecord', createDateTime?: Date | undefined, updateDateTime?: Date | undefined } };

export type EditStrategyWizardStrategyItemFragment = { __typename: 'StrategyItem', id: string, name?: string | undefined, symbol?: string | undefined, title?: string | undefined, description?: string | undefined, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined, auditRecord: { __typename: 'AuditRecord', createDateTime?: Date | undefined, updateDateTime?: Date | undefined } };

export const EditStrategyWizardStrategyBehaviorSetFragmentDoc = gql`
    fragment editStrategyWizardStrategyBehaviorSet on StrategyBehaviorSet {
  id
  behaviorSetGroups {
    id
    name
    items {
      id
      text
      auditRecord {
        ...auditRecord
      }
    }
    description
    auditRecord {
      ...auditRecord
    }
  }
  name
  title
  description
  auditRecord {
    ...auditRecord
  }
}
    ${AuditRecordFragmentDoc}`;
export const EditStrategyWizardStrategyItemFragmentDoc = gql`
    fragment editStrategyWizardStrategyItem on StrategyItem {
  id
  name
  symbol
  title
  description
  owner {
    ...userLimitedProfile
  }
  auditRecord {
    ...auditRecord
  }
}
    ${UserLimitedProfileFragmentDoc}
${AuditRecordFragmentDoc}`;
export const UpdateStrategyDetailsDocument = gql`
    mutation UpdateStrategyDetails($input: StrategyInput!) {
  updateStrategy(input: $input) {
    id
    name
    timeLine {
      startDate
      endDate
    }
  }
}
    `;
export type UpdateStrategyDetailsMutationFn = Apollo.MutationFunction<UpdateStrategyDetailsMutation, UpdateStrategyDetailsMutationVariables>;

/**
 * __useUpdateStrategyDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateStrategyDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStrategyDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStrategyDetailsMutation, { data, loading, error }] = useUpdateStrategyDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStrategyDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStrategyDetailsMutation, UpdateStrategyDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStrategyDetailsMutation, UpdateStrategyDetailsMutationVariables>(UpdateStrategyDetailsDocument, options);
      }
export type UpdateStrategyDetailsMutationHookResult = ReturnType<typeof useUpdateStrategyDetailsMutation>;
export type UpdateStrategyDetailsMutationResult = Apollo.MutationResult<UpdateStrategyDetailsMutation>;
export type UpdateStrategyDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateStrategyDetailsMutation, UpdateStrategyDetailsMutationVariables>;
export const UpdateStrategyMissionDocument = gql`
    mutation UpdateStrategyMission($input: StrategyInput!) {
  updateStrategy(input: $input) {
    id
    mission {
      ...editStrategyWizardStrategyItem
    }
  }
}
    ${EditStrategyWizardStrategyItemFragmentDoc}`;
export type UpdateStrategyMissionMutationFn = Apollo.MutationFunction<UpdateStrategyMissionMutation, UpdateStrategyMissionMutationVariables>;

/**
 * __useUpdateStrategyMissionMutation__
 *
 * To run a mutation, you first call `useUpdateStrategyMissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStrategyMissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStrategyMissionMutation, { data, loading, error }] = useUpdateStrategyMissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStrategyMissionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStrategyMissionMutation, UpdateStrategyMissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStrategyMissionMutation, UpdateStrategyMissionMutationVariables>(UpdateStrategyMissionDocument, options);
      }
export type UpdateStrategyMissionMutationHookResult = ReturnType<typeof useUpdateStrategyMissionMutation>;
export type UpdateStrategyMissionMutationResult = Apollo.MutationResult<UpdateStrategyMissionMutation>;
export type UpdateStrategyMissionMutationOptions = Apollo.BaseMutationOptions<UpdateStrategyMissionMutation, UpdateStrategyMissionMutationVariables>;
export const UpdateStrategyVisionDocument = gql`
    mutation UpdateStrategyVision($input: StrategyInput!) {
  updateStrategy(input: $input) {
    id
    vision {
      ...editStrategyWizardStrategyItem
    }
  }
}
    ${EditStrategyWizardStrategyItemFragmentDoc}`;
export type UpdateStrategyVisionMutationFn = Apollo.MutationFunction<UpdateStrategyVisionMutation, UpdateStrategyVisionMutationVariables>;

/**
 * __useUpdateStrategyVisionMutation__
 *
 * To run a mutation, you first call `useUpdateStrategyVisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStrategyVisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStrategyVisionMutation, { data, loading, error }] = useUpdateStrategyVisionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStrategyVisionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStrategyVisionMutation, UpdateStrategyVisionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStrategyVisionMutation, UpdateStrategyVisionMutationVariables>(UpdateStrategyVisionDocument, options);
      }
export type UpdateStrategyVisionMutationHookResult = ReturnType<typeof useUpdateStrategyVisionMutation>;
export type UpdateStrategyVisionMutationResult = Apollo.MutationResult<UpdateStrategyVisionMutation>;
export type UpdateStrategyVisionMutationOptions = Apollo.BaseMutationOptions<UpdateStrategyVisionMutation, UpdateStrategyVisionMutationVariables>;
export const UpdateStrategyGoalsDocument = gql`
    mutation UpdateStrategyGoals($input: StrategyInput!) {
  updateStrategy(input: $input) {
    id
    goals {
      ...editStrategyWizardStrategyItem
    }
  }
}
    ${EditStrategyWizardStrategyItemFragmentDoc}`;
export type UpdateStrategyGoalsMutationFn = Apollo.MutationFunction<UpdateStrategyGoalsMutation, UpdateStrategyGoalsMutationVariables>;

/**
 * __useUpdateStrategyGoalsMutation__
 *
 * To run a mutation, you first call `useUpdateStrategyGoalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStrategyGoalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStrategyGoalsMutation, { data, loading, error }] = useUpdateStrategyGoalsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStrategyGoalsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStrategyGoalsMutation, UpdateStrategyGoalsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStrategyGoalsMutation, UpdateStrategyGoalsMutationVariables>(UpdateStrategyGoalsDocument, options);
      }
export type UpdateStrategyGoalsMutationHookResult = ReturnType<typeof useUpdateStrategyGoalsMutation>;
export type UpdateStrategyGoalsMutationResult = Apollo.MutationResult<UpdateStrategyGoalsMutation>;
export type UpdateStrategyGoalsMutationOptions = Apollo.BaseMutationOptions<UpdateStrategyGoalsMutation, UpdateStrategyGoalsMutationVariables>;
export const UpdateStrategyValuesDocument = gql`
    mutation UpdateStrategyValues($input: StrategyInput!) {
  updateStrategy(input: $input) {
    id
    values {
      ...editStrategyWizardStrategyItem
    }
  }
}
    ${EditStrategyWizardStrategyItemFragmentDoc}`;
export type UpdateStrategyValuesMutationFn = Apollo.MutationFunction<UpdateStrategyValuesMutation, UpdateStrategyValuesMutationVariables>;

/**
 * __useUpdateStrategyValuesMutation__
 *
 * To run a mutation, you first call `useUpdateStrategyValuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStrategyValuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStrategyValuesMutation, { data, loading, error }] = useUpdateStrategyValuesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStrategyValuesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStrategyValuesMutation, UpdateStrategyValuesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStrategyValuesMutation, UpdateStrategyValuesMutationVariables>(UpdateStrategyValuesDocument, options);
      }
export type UpdateStrategyValuesMutationHookResult = ReturnType<typeof useUpdateStrategyValuesMutation>;
export type UpdateStrategyValuesMutationResult = Apollo.MutationResult<UpdateStrategyValuesMutation>;
export type UpdateStrategyValuesMutationOptions = Apollo.BaseMutationOptions<UpdateStrategyValuesMutation, UpdateStrategyValuesMutationVariables>;
export const UpdateStrategyChoicesDocument = gql`
    mutation UpdateStrategyChoices($input: StrategyInput!) {
  updateStrategy(input: $input) {
    id
    choices {
      ...editStrategyWizardStrategyItem
    }
  }
}
    ${EditStrategyWizardStrategyItemFragmentDoc}`;
export type UpdateStrategyChoicesMutationFn = Apollo.MutationFunction<UpdateStrategyChoicesMutation, UpdateStrategyChoicesMutationVariables>;

/**
 * __useUpdateStrategyChoicesMutation__
 *
 * To run a mutation, you first call `useUpdateStrategyChoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStrategyChoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStrategyChoicesMutation, { data, loading, error }] = useUpdateStrategyChoicesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStrategyChoicesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStrategyChoicesMutation, UpdateStrategyChoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStrategyChoicesMutation, UpdateStrategyChoicesMutationVariables>(UpdateStrategyChoicesDocument, options);
      }
export type UpdateStrategyChoicesMutationHookResult = ReturnType<typeof useUpdateStrategyChoicesMutation>;
export type UpdateStrategyChoicesMutationResult = Apollo.MutationResult<UpdateStrategyChoicesMutation>;
export type UpdateStrategyChoicesMutationOptions = Apollo.BaseMutationOptions<UpdateStrategyChoicesMutation, UpdateStrategyChoicesMutationVariables>;
export const UpdateStrategyBehaviorsDocument = gql`
    mutation UpdateStrategyBehaviors($input: StrategyInput!) {
  updateStrategy(input: $input) {
    id
    behaviors {
      ...editStrategyWizardStrategyBehaviorSet
    }
  }
}
    ${EditStrategyWizardStrategyBehaviorSetFragmentDoc}`;
export type UpdateStrategyBehaviorsMutationFn = Apollo.MutationFunction<UpdateStrategyBehaviorsMutation, UpdateStrategyBehaviorsMutationVariables>;

/**
 * __useUpdateStrategyBehaviorsMutation__
 *
 * To run a mutation, you first call `useUpdateStrategyBehaviorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStrategyBehaviorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStrategyBehaviorsMutation, { data, loading, error }] = useUpdateStrategyBehaviorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStrategyBehaviorsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStrategyBehaviorsMutation, UpdateStrategyBehaviorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStrategyBehaviorsMutation, UpdateStrategyBehaviorsMutationVariables>(UpdateStrategyBehaviorsDocument, options);
      }
export type UpdateStrategyBehaviorsMutationHookResult = ReturnType<typeof useUpdateStrategyBehaviorsMutation>;
export type UpdateStrategyBehaviorsMutationResult = Apollo.MutationResult<UpdateStrategyBehaviorsMutation>;
export type UpdateStrategyBehaviorsMutationOptions = Apollo.BaseMutationOptions<UpdateStrategyBehaviorsMutation, UpdateStrategyBehaviorsMutationVariables>;