import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TeamTeamHierarchyQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TeamTeamHierarchyQuery = { __typename: 'Query', activeOrg: { __typename: 'Org', id: string, orgUnits: Array<{ __typename: 'OrgUnit', id: string, name?: string | undefined, nameAbbreviated?: string | undefined, orgUnitMembershipsCascadingTotalCount: number, backgroundColorAsHex?: string | undefined, currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, childOrgUnits: Array<{ __typename: 'OrgUnit', id: string }>, childOrgUnitTree: Array<{ __typename: 'OrgUnit', id: string }>, parentOrgUnit?: { __typename: 'OrgUnit', id: string } | undefined }> } };

export type TeamTeamHierarchyOrgUnitFragment = { __typename: 'OrgUnit', id: string, name?: string | undefined, nameAbbreviated?: string | undefined, orgUnitMembershipsCascadingTotalCount: number, backgroundColorAsHex?: string | undefined, currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, childOrgUnits: Array<{ __typename: 'OrgUnit', id: string }>, childOrgUnitTree: Array<{ __typename: 'OrgUnit', id: string }>, parentOrgUnit?: { __typename: 'OrgUnit', id: string } | undefined };

export const TeamTeamHierarchyOrgUnitFragmentDoc = gql`
    fragment teamTeamHierarchyOrgUnit on OrgUnit {
  id
  name
  nameAbbreviated
  orgUnitMembershipsCascadingTotalCount
  backgroundColorAsHex
  currentUserAuthorizedActions
  childOrgUnits {
    id
  }
  childOrgUnitTree {
    id
  }
  parentOrgUnit {
    id
  }
}
    `;
export const TeamTeamHierarchyDocument = gql`
    query TeamTeamHierarchy {
  activeOrg {
    id
    orgUnits {
      ...teamTeamHierarchyOrgUnit
    }
  }
}
    ${TeamTeamHierarchyOrgUnitFragmentDoc}`;

/**
 * __useTeamTeamHierarchyQuery__
 *
 * To run a query within a React component, call `useTeamTeamHierarchyQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamTeamHierarchyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamTeamHierarchyQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamTeamHierarchyQuery(baseOptions?: Apollo.QueryHookOptions<TeamTeamHierarchyQuery, TeamTeamHierarchyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamTeamHierarchyQuery, TeamTeamHierarchyQueryVariables>(TeamTeamHierarchyDocument, options);
      }
export function useTeamTeamHierarchyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamTeamHierarchyQuery, TeamTeamHierarchyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamTeamHierarchyQuery, TeamTeamHierarchyQueryVariables>(TeamTeamHierarchyDocument, options);
        }
export function useTeamTeamHierarchySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TeamTeamHierarchyQuery, TeamTeamHierarchyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TeamTeamHierarchyQuery, TeamTeamHierarchyQueryVariables>(TeamTeamHierarchyDocument, options);
        }
export type TeamTeamHierarchyQueryHookResult = ReturnType<typeof useTeamTeamHierarchyQuery>;
export type TeamTeamHierarchyLazyQueryHookResult = ReturnType<typeof useTeamTeamHierarchyLazyQuery>;
export type TeamTeamHierarchySuspenseQueryHookResult = ReturnType<typeof useTeamTeamHierarchySuspenseQuery>;
export type TeamTeamHierarchyQueryResult = Apollo.QueryResult<TeamTeamHierarchyQuery, TeamTeamHierarchyQueryVariables>;