import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StrategyRoomThemesManageThemesQueryVariables = Types.Exact<{
  filter: Types.ThemeFilterInput;
}>;


export type StrategyRoomThemesManageThemesQuery = { __typename: 'Query', allThemes: { __typename: 'ThemeConnection', edges: Array<{ __typename: 'ThemeEdge', node: { __typename: 'Theme', id: string, name?: string | undefined, isActive: boolean, description?: string | undefined, orderNumber?: number | undefined, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined, themeCategory?: { __typename: 'ThemeCategory', id: string, name?: string | undefined } | undefined, objectives: Array<{ __typename: 'Objective', id: string, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined, currentObjectiveStatus?: { __typename: 'ObjectiveStatus', id: string, complete: boolean } | undefined, theme?: { __typename: 'Theme', id: string } | undefined }> } }> } };

export type StrategyRoomManageThemesThemeFragment = { __typename: 'Theme', id: string, name?: string | undefined, isActive: boolean, description?: string | undefined, orderNumber?: number | undefined, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined, themeCategory?: { __typename: 'ThemeCategory', id: string, name?: string | undefined } | undefined, objectives: Array<{ __typename: 'Objective', id: string, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined, currentObjectiveStatus?: { __typename: 'ObjectiveStatus', id: string, complete: boolean } | undefined, theme?: { __typename: 'Theme', id: string } | undefined }> };

export type StrategyRoomManageThemesObjectiveFragment = { __typename: 'Objective', id: string, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined, currentObjectiveStatus?: { __typename: 'ObjectiveStatus', id: string, complete: boolean } | undefined, theme?: { __typename: 'Theme', id: string } | undefined };

export const StrategyRoomManageThemesObjectiveFragmentDoc = gql`
    fragment strategyRoomManageThemesObjective on Objective {
  id
  orgUnit {
    id
  }
  currentObjectiveStatus {
    id
    complete
  }
  theme {
    id
  }
}
    `;
export const StrategyRoomManageThemesThemeFragmentDoc = gql`
    fragment strategyRoomManageThemesTheme on Theme {
  id
  name
  isActive
  description
  orderNumber
  orgUnit {
    id
  }
  themeCategory {
    id
    name
  }
  objectives {
    ...strategyRoomManageThemesObjective
  }
}
    ${StrategyRoomManageThemesObjectiveFragmentDoc}`;
export const StrategyRoomThemesManageThemesDocument = gql`
    query StrategyRoomThemesManageThemes($filter: ThemeFilterInput!) {
  allThemes(filter: $filter) {
    edges {
      node {
        ...strategyRoomManageThemesTheme
      }
    }
  }
}
    ${StrategyRoomManageThemesThemeFragmentDoc}`;

/**
 * __useStrategyRoomThemesManageThemesQuery__
 *
 * To run a query within a React component, call `useStrategyRoomThemesManageThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStrategyRoomThemesManageThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStrategyRoomThemesManageThemesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStrategyRoomThemesManageThemesQuery(baseOptions: Apollo.QueryHookOptions<StrategyRoomThemesManageThemesQuery, StrategyRoomThemesManageThemesQueryVariables> & ({ variables: StrategyRoomThemesManageThemesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StrategyRoomThemesManageThemesQuery, StrategyRoomThemesManageThemesQueryVariables>(StrategyRoomThemesManageThemesDocument, options);
      }
export function useStrategyRoomThemesManageThemesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StrategyRoomThemesManageThemesQuery, StrategyRoomThemesManageThemesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StrategyRoomThemesManageThemesQuery, StrategyRoomThemesManageThemesQueryVariables>(StrategyRoomThemesManageThemesDocument, options);
        }
export function useStrategyRoomThemesManageThemesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<StrategyRoomThemesManageThemesQuery, StrategyRoomThemesManageThemesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StrategyRoomThemesManageThemesQuery, StrategyRoomThemesManageThemesQueryVariables>(StrategyRoomThemesManageThemesDocument, options);
        }
export type StrategyRoomThemesManageThemesQueryHookResult = ReturnType<typeof useStrategyRoomThemesManageThemesQuery>;
export type StrategyRoomThemesManageThemesLazyQueryHookResult = ReturnType<typeof useStrategyRoomThemesManageThemesLazyQuery>;
export type StrategyRoomThemesManageThemesSuspenseQueryHookResult = ReturnType<typeof useStrategyRoomThemesManageThemesSuspenseQuery>;
export type StrategyRoomThemesManageThemesQueryResult = Apollo.QueryResult<StrategyRoomThemesManageThemesQuery, StrategyRoomThemesManageThemesQueryVariables>;