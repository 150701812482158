import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AskSohpieButton from 'ai/AskSohpieButton';
import StrategyProvider, { useStrategy } from 'strategy/StrategyProvider';
import type { PromptMessage } from 'ai/AskSophieChat';

import { useStrategyBoard } from './StrategyBoardProvider';

const StrategyBoardAskSophieButton = () => {
  const { t } = useTranslation();

  const { strategy } = useStrategy();
  const { themes } = useStrategyBoard();

  const objectives = themes.flatMap((theme) => theme.objectives);

  const objectiveIds = objectives.map((objective) => objective.id);

  const metricIds = objectives
    .flatMap((objective) => objective.metrics)
    .map((metric) => metric.id);

  const initiativeIds = objectives
    .flatMap((objective) => objective.initiatives)
    .map((initiative) => initiative.id);

  const promptMessages = useMemo<PromptMessage[]>(
    () => [
      {
        buttonLabel: t('ai.strategySummary.button.alignment'),
        mainPrompt:
          'Please analyze if our objectives, metrics and initiatives are aligned.',
        numberOfCharacters: 5000,
        temperature: 1,
        hints: [
          'Analyze alignment of the given objectives, metrics and initiatives.',
          'First group per theme, then group per objective. Then group the results in a) Metrics and initiatives that seems to be well aligned to the objective, b) Metrics and initiatives that are potentially misaligned to the objectives.',
          'In your response, do not include information about the status of objectives, metrics or initiatives.',
        ],
      },
    ],
    [t],
  );

  return (
    <AskSohpieButton
      promptMessages={promptMessages}
      strategyId={strategy?.id}
      objectiveIds={objectiveIds}
      metricIds={metricIds}
      initiativeIds={initiativeIds}
      infoText={t('ai.strategySummary.start.subHeader.strategyBoard')}
    />
  );
};

export default () => (
  <StrategyProvider>
    <StrategyBoardAskSophieButton />
  </StrategyProvider>
);
