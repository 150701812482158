import styled from '@emotion/styled';

import type { Message } from 'ai/AskSophieChat';
import { transientOptions } from 'shared/utils/emotion.utils';

const ChatBubble = styled('div', transientOptions)<{
  $messageType: Message['type'];
}>`
  background-color: ${(props) =>
    props.$messageType === 'SummaryMessage'
      ? props.theme.color.backgroundLight
      : props.theme.color.primaryVariant};
  padding: 16px;
  border-radius: 16px;
  white-space: pre-wrap;

  table {
    th {
      padding: 6px;
    }

    td {
      border: 1px solid ${(props) => props.theme.color.strokeMedium};
      padding: 6px;
    }
  }
`;

export default ChatBubble;
