import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';
import type { ReactNode } from 'react';

import { date } from 'shared/services/date.service';
import Card from 'shared/components/Card';
import Text from 'shared/components/Text';
import Space from 'shared/components/Space';
import { ReactComponent as ChevronUpIcon } from 'shared/static/icons/icon-chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from 'shared/static/icons/icon-chevron-down.svg';
import StatusMark from 'shared/status/StatusMark';
import Heading from 'shared/components/Heading';
import Collapse from 'shared/components/Collapse';
import StatusComments from 'shared/status/StatusComments';
import { migrateTextItemsToStatusComments } from 'shared/status/status.utils';
import UserUpdateStatus from 'user/UserUpdateStatus';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';
import type { ContextMenuItem } from 'shared/components/ContextMenu';
import ContextMenu from 'shared/components/ContextMenu';

import StrategyInsightsMetric from '../StrategyInsightsMetric';
import styles from './StrategyInsightsObjective.module.scss';
import type { StrategyInsightsObjectiveFragment } from '../StrategyInsights.graphql.generated';

type StrategyInsightsObjectiveProps = {
  className?: string;
  isExpanded: boolean;
  objective: StrategyInsightsObjectiveFragment;
  onToggleExpand: () => void;
  renderInitiatives?: () => ReactNode;
  summaryBackground?: boolean;
};

const StrategyInsightsObjective = ({
  objective,
  className,
  isExpanded,
  onToggleExpand,
  summaryBackground,
  renderInitiatives,
}: StrategyInsightsObjectiveProps) => {
  const { name, currentObjectiveStatus, auditRecord, metrics, description } =
    objective;

  const navigate = useNavigate();

  const { teamAdapter } = useTeamAdapter();

  const { t } = useTranslation();

  const [isInsightSummaryCollapsed, toggleIsInsightSummaryCollapsed] =
    useToggle(true);

  const renderToggleExpandIcon = () => {
    const Icon = isExpanded ? ChevronDownIcon : ChevronUpIcon;
    return (
      <Icon
        onClick={() => {
          onToggleExpand();
        }}
        className={styles.toggleExpandIcon}
      />
    );
  };

  const renderDate = () => {
    const lastUpdateTime =
      currentObjectiveStatus?.auditRecord?.updateDateTime ||
      auditRecord.createDateTime;

    const dateDistance = lastUpdateTime
      ? date.formatDistance(lastUpdateTime, Date.now())
      : '-';

    const dateLabel = currentObjectiveStatus ? 'updated' : 'shared';

    return (
      <Text ellipsis={true} className={styles.date}>
        <Trans
          i18nKey={`strategy.strategyInsights.objective.${dateLabel}`}
          values={{ dateDistance }}
        >
          <Text />
          <Text variant={'strong'} />
        </Trans>
      </Text>
    );
  };

  const renderContent = () => (
    <>
      {description ? (
        <Text variant={'emphasis'} whiteSpace={'preWrap'}>
          {description}
        </Text>
      ) : null}
      {currentObjectiveStatus ? (
        <Collapse
          isCollapsed={isInsightSummaryCollapsed}
          onToggleIsCollapsed={toggleIsInsightSummaryCollapsed}
          renderHeading={() => (
            <Heading level={4} hasMargin={false}>
              {t('strategy.strategyInsights.objective.insightSummary.heading')}
            </Heading>
          )}
          hasBackground={summaryBackground}
          className={styles.commentsContainer}
        >
          <Space direction={'vertical'} size={'big'}>
            <StatusComments
              items={migrateTextItemsToStatusComments({
                challengeComments: currentObjectiveStatus.challengeComments,
                successComments: currentObjectiveStatus.successComments,
                actionComments: currentObjectiveStatus.actionComments,
              })}
            />
            <UserUpdateStatus
              auditRecordUser={currentObjectiveStatus.auditRecord.updateBy}
              updateDateTime={currentObjectiveStatus.auditRecord.updateDateTime}
            />
          </Space>
        </Collapse>
      ) : null}
      {metrics.map((metric) => (
        <StrategyInsightsMetric
          metric={metric}
          key={metric.id}
          summaryBackground={summaryBackground}
        />
      ))}
      {renderInitiatives?.()}
    </>
  );

  return (
    <Card
      className={className}
      controls={
        <ContextMenu<ContextMenuItem<'details'>>
          disabledKeys={['requestStatusUpdate']}
          items={[
            {
              id: 'details',
              title: t('strategy.strategyInsights.objective.menu.viewDetails'),
            },
          ]}
          onAction={(action) => {
            switch (action) {
              case 'details':
                navigate(
                  objectiveRoutesPaths.root({
                    params: {
                      teamSlug: teamAdapter.toParam(),
                      objectiveId: objective.id,
                    },
                  }),
                );
                break;
            }
          }}
        />
      }
    >
      <Space className={styles.headingContainer}>
        <Card.Heading
          level={4}
          onClick={() => {
            onToggleExpand();
          }}
          before={
            <StatusMark
              statusIndicator={currentObjectiveStatus?.statusIndicator}
            />
          }
          hasMargin={false}
          className={styles.heading}
        >
          {name}
        </Card.Heading>
        <Space>
          {renderToggleExpandIcon()}
          {renderDate()}
        </Space>
      </Space>
      {isExpanded ? renderContent() : null}
    </Card>
  );
};

export default StrategyInsightsObjective;
