import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LinkMetricToObjectiveMutationVariables = Types.Exact<{
  objectiveId: Types.Scalars['ID']['input'];
  metricId: Types.Scalars['ID']['input'];
}>;


export type LinkMetricToObjectiveMutation = { __typename: 'Mutation', updateObjective: { __typename: 'Objective', id: string, metrics: Array<{ __typename: 'Metric', id: string }> } };

export type LinkMetricDialogQueryVariables = Types.Exact<{
  objectiveId: Types.Scalars['ID']['input'];
}>;


export type LinkMetricDialogQuery = { __typename: 'Query', activeOrg: { __typename: 'Org', id: string, orgUnits: Array<{ __typename: 'OrgUnit', id: string, name?: string | undefined }> }, objective: { __typename: 'Objective', id: string, childObjectives: Array<{ __typename: 'Objective', id: string, name?: string | undefined, theme?: { __typename: 'Theme', id: string, name?: string | undefined, orderNumber?: number | undefined, isActive: boolean } | undefined, metrics: Array<{ __typename: 'Metric', id: string, name?: string | undefined, currentMetricStatus?: { __typename: 'MetricStatus', id: string, statusIndicator: StatusIndicatorClass } | undefined }>, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined }> } };

export type LinkMetricDialogObjectiveFragment = { __typename: 'Objective', id: string, name?: string | undefined, theme?: { __typename: 'Theme', id: string, name?: string | undefined, orderNumber?: number | undefined, isActive: boolean } | undefined, metrics: Array<{ __typename: 'Metric', id: string, name?: string | undefined, currentMetricStatus?: { __typename: 'MetricStatus', id: string, statusIndicator: StatusIndicatorClass } | undefined }>, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined };

export type LinkMetricDialogOrgUnitFragment = { __typename: 'OrgUnit', id: string, name?: string | undefined };

export type LinkMetricDialogMetricFragment = { __typename: 'Metric', id: string, name?: string | undefined, currentMetricStatus?: { __typename: 'MetricStatus', id: string, statusIndicator: StatusIndicatorClass } | undefined };

export type LinkMetricDialogThemeFragment = { __typename: 'Theme', id: string, name?: string | undefined, orderNumber?: number | undefined, isActive: boolean };

export const LinkMetricDialogThemeFragmentDoc = gql`
    fragment linkMetricDialogTheme on Theme {
  id
  name
  orderNumber
  isActive
}
    `;
export const LinkMetricDialogMetricFragmentDoc = gql`
    fragment linkMetricDialogMetric on Metric {
  id
  name
  currentMetricStatus {
    id
    statusIndicator
  }
}
    `;
export const LinkMetricDialogObjectiveFragmentDoc = gql`
    fragment linkMetricDialogObjective on Objective {
  id
  name
  theme {
    ...linkMetricDialogTheme
  }
  metrics {
    ...linkMetricDialogMetric
  }
  orgUnit {
    id
  }
}
    ${LinkMetricDialogThemeFragmentDoc}
${LinkMetricDialogMetricFragmentDoc}`;
export const LinkMetricDialogOrgUnitFragmentDoc = gql`
    fragment linkMetricDialogOrgUnit on OrgUnit {
  id
  name
}
    `;
export const LinkMetricToObjectiveDocument = gql`
    mutation LinkMetricToObjective($objectiveId: ID!, $metricId: ID!) {
  updateObjective(
    input: {idToUpdate: $objectiveId, metricLinks: {idsToSet: [$metricId]}}
  ) {
    id
    metrics {
      id
    }
  }
}
    `;
export type LinkMetricToObjectiveMutationFn = Apollo.MutationFunction<LinkMetricToObjectiveMutation, LinkMetricToObjectiveMutationVariables>;

/**
 * __useLinkMetricToObjectiveMutation__
 *
 * To run a mutation, you first call `useLinkMetricToObjectiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkMetricToObjectiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkMetricToObjectiveMutation, { data, loading, error }] = useLinkMetricToObjectiveMutation({
 *   variables: {
 *      objectiveId: // value for 'objectiveId'
 *      metricId: // value for 'metricId'
 *   },
 * });
 */
export function useLinkMetricToObjectiveMutation(baseOptions?: Apollo.MutationHookOptions<LinkMetricToObjectiveMutation, LinkMetricToObjectiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LinkMetricToObjectiveMutation, LinkMetricToObjectiveMutationVariables>(LinkMetricToObjectiveDocument, options);
      }
export type LinkMetricToObjectiveMutationHookResult = ReturnType<typeof useLinkMetricToObjectiveMutation>;
export type LinkMetricToObjectiveMutationResult = Apollo.MutationResult<LinkMetricToObjectiveMutation>;
export type LinkMetricToObjectiveMutationOptions = Apollo.BaseMutationOptions<LinkMetricToObjectiveMutation, LinkMetricToObjectiveMutationVariables>;
export const LinkMetricDialogDocument = gql`
    query LinkMetricDialog($objectiveId: ID!) {
  activeOrg {
    id
    orgUnits {
      ...linkMetricDialogOrgUnit
    }
  }
  objective(id: $objectiveId) {
    id
    childObjectives {
      ...linkMetricDialogObjective
    }
  }
}
    ${LinkMetricDialogOrgUnitFragmentDoc}
${LinkMetricDialogObjectiveFragmentDoc}`;

/**
 * __useLinkMetricDialogQuery__
 *
 * To run a query within a React component, call `useLinkMetricDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkMetricDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkMetricDialogQuery({
 *   variables: {
 *      objectiveId: // value for 'objectiveId'
 *   },
 * });
 */
export function useLinkMetricDialogQuery(baseOptions: Apollo.QueryHookOptions<LinkMetricDialogQuery, LinkMetricDialogQueryVariables> & ({ variables: LinkMetricDialogQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LinkMetricDialogQuery, LinkMetricDialogQueryVariables>(LinkMetricDialogDocument, options);
      }
export function useLinkMetricDialogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LinkMetricDialogQuery, LinkMetricDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LinkMetricDialogQuery, LinkMetricDialogQueryVariables>(LinkMetricDialogDocument, options);
        }
export function useLinkMetricDialogSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LinkMetricDialogQuery, LinkMetricDialogQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LinkMetricDialogQuery, LinkMetricDialogQueryVariables>(LinkMetricDialogDocument, options);
        }
export type LinkMetricDialogQueryHookResult = ReturnType<typeof useLinkMetricDialogQuery>;
export type LinkMetricDialogLazyQueryHookResult = ReturnType<typeof useLinkMetricDialogLazyQuery>;
export type LinkMetricDialogSuspenseQueryHookResult = ReturnType<typeof useLinkMetricDialogSuspenseQuery>;
export type LinkMetricDialogQueryResult = Apollo.QueryResult<LinkMetricDialogQuery, LinkMetricDialogQueryVariables>;