import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useHandleError from 'shared/errors/useHandleError';
import { useToasts } from 'shared/toast/useToasts';
import type { StrategyAssessmentInput } from 'types.graphql.generated';

import { useCreateAssessmentMutation } from './CreateAssessment.graphql.generated';
import { StrategyInsightsAssessmentsDocument } from '../../strategy/StrategyInsightsAssessments/StrategyInsightsAssessments.graphql.generated';
import AssesmentWizardModal from '../EditAssessment/AssesmentWizardModal';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const CreateAssessmentModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const onError = useHandleError();

  const [createAssessment, { loading }] = useCreateAssessmentMutation({
    onError,
    onCompleted: () => {
      addToast({
        variant: 'success',
        children: t('assessment.createAssessment.success'),
      });
      onClose();
    },
    refetchQueries: [StrategyInsightsAssessmentsDocument],
  });

  const handleSubmit = useCallback(
    (input: StrategyAssessmentInput) =>
      createAssessment({ variables: { input } }),
    [createAssessment],
  );

  return (
    <AssesmentWizardModal
      initialValues={{ title: '', content: '', type: 'PESTEL' }}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      isSubmitDisabled={loading}
    />
  );
};

export default CreateAssessmentModal;
