import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddActionStatusMutationVariables = Types.Exact<{
  input: Types.ActionStatusInput;
}>;


export type AddActionStatusMutation = { __typename: 'Mutation', addActionStatus: { __typename: 'ActionStatus', id: string, completionRate?: number | undefined, statusDateTime: Date, comment?: string | undefined, complete: boolean, statusIndicator: StatusIndicatorClass, auditRecord: { __typename: 'AuditRecord', updateDateTime?: Date | undefined, createDateTime?: Date | undefined, updateBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined, createBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined }, action: { __typename: 'Action', id: string, currentStatus?: { __typename: 'ActionStatus', id: string } | undefined } } };


export const AddActionStatusDocument = gql`
    mutation AddActionStatus($input: ActionStatusInput!) {
  addActionStatus(input: $input) {
    id
    completionRate
    statusDateTime
    auditRecord {
      updateDateTime
      createDateTime
      updateBy {
        email
        displayName
        photoUrl
      }
      createBy {
        email
        displayName
        photoUrl
      }
    }
    comment
    complete
    statusIndicator
    action {
      id
      currentStatus {
        id
      }
    }
  }
}
    `;
export type AddActionStatusMutationFn = Apollo.MutationFunction<AddActionStatusMutation, AddActionStatusMutationVariables>;

/**
 * __useAddActionStatusMutation__
 *
 * To run a mutation, you first call `useAddActionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddActionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addActionStatusMutation, { data, loading, error }] = useAddActionStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddActionStatusMutation(baseOptions?: Apollo.MutationHookOptions<AddActionStatusMutation, AddActionStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddActionStatusMutation, AddActionStatusMutationVariables>(AddActionStatusDocument, options);
      }
export type AddActionStatusMutationHookResult = ReturnType<typeof useAddActionStatusMutation>;
export type AddActionStatusMutationResult = Apollo.MutationResult<AddActionStatusMutation>;
export type AddActionStatusMutationOptions = Apollo.BaseMutationOptions<AddActionStatusMutation, AddActionStatusMutationVariables>;