import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InitiativeStatusWizardQueryVariables = Types.Exact<{
  initiativeId: Types.Scalars['ID']['input'];
}>;


export type InitiativeStatusWizardQuery = { __typename: 'Query', initiative: { __typename: 'Initiative', id: string, name?: string | undefined, description?: string | undefined, currentInitiativeStatus?: { __typename: 'InitiativeStatus', id: string, statusIndicator: StatusIndicatorClass, keyIndicatorOnOutcome?: boolean | undefined, keyIndicatorOnTime?: boolean | undefined, keyIndicatorOnBudget?: boolean | undefined } | undefined, milestones: Array<{ __typename: 'Milestone', id: string, name?: string | undefined, description?: string | undefined, currentMilestoneStatus?: { __typename: 'MilestoneStatus', id: string, statusIndicator: StatusIndicatorClass, completionRate?: number | undefined, comment?: string | undefined, complete: boolean } | undefined, timeLine?: { __typename: 'TimeLine', startDate?: Date | undefined, endDate?: Date | undefined, completeDateTime?: Date | undefined } | undefined }>, objective?: { __typename: 'Objective', id: string } | undefined } };

export type InitiativeStatusWizardInitiativeFragment = { __typename: 'Initiative', id: string, name?: string | undefined, description?: string | undefined, currentInitiativeStatus?: { __typename: 'InitiativeStatus', id: string, statusIndicator: StatusIndicatorClass, keyIndicatorOnOutcome?: boolean | undefined, keyIndicatorOnTime?: boolean | undefined, keyIndicatorOnBudget?: boolean | undefined } | undefined, milestones: Array<{ __typename: 'Milestone', id: string, name?: string | undefined, description?: string | undefined, currentMilestoneStatus?: { __typename: 'MilestoneStatus', id: string, statusIndicator: StatusIndicatorClass, completionRate?: number | undefined, comment?: string | undefined, complete: boolean } | undefined, timeLine?: { __typename: 'TimeLine', startDate?: Date | undefined, endDate?: Date | undefined, completeDateTime?: Date | undefined } | undefined }>, objective?: { __typename: 'Objective', id: string } | undefined };

export const InitiativeStatusWizardInitiativeFragmentDoc = gql`
    fragment initiativeStatusWizardInitiative on Initiative {
  id
  name
  description
  currentInitiativeStatus {
    id
    statusIndicator
    keyIndicatorOnOutcome
    keyIndicatorOnTime
    keyIndicatorOnBudget
  }
  milestones {
    id
    name
    description
    currentMilestoneStatus {
      id
      statusIndicator
      completionRate
      comment
      complete
    }
    timeLine {
      startDate
      endDate
      completeDateTime
    }
  }
  objective {
    id
  }
}
    `;
export const InitiativeStatusWizardDocument = gql`
    query InitiativeStatusWizard($initiativeId: ID!) {
  initiative(id: $initiativeId) {
    ...initiativeStatusWizardInitiative
  }
}
    ${InitiativeStatusWizardInitiativeFragmentDoc}`;

/**
 * __useInitiativeStatusWizardQuery__
 *
 * To run a query within a React component, call `useInitiativeStatusWizardQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitiativeStatusWizardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitiativeStatusWizardQuery({
 *   variables: {
 *      initiativeId: // value for 'initiativeId'
 *   },
 * });
 */
export function useInitiativeStatusWizardQuery(baseOptions: Apollo.QueryHookOptions<InitiativeStatusWizardQuery, InitiativeStatusWizardQueryVariables> & ({ variables: InitiativeStatusWizardQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InitiativeStatusWizardQuery, InitiativeStatusWizardQueryVariables>(InitiativeStatusWizardDocument, options);
      }
export function useInitiativeStatusWizardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InitiativeStatusWizardQuery, InitiativeStatusWizardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InitiativeStatusWizardQuery, InitiativeStatusWizardQueryVariables>(InitiativeStatusWizardDocument, options);
        }
export function useInitiativeStatusWizardSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InitiativeStatusWizardQuery, InitiativeStatusWizardQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InitiativeStatusWizardQuery, InitiativeStatusWizardQueryVariables>(InitiativeStatusWizardDocument, options);
        }
export type InitiativeStatusWizardQueryHookResult = ReturnType<typeof useInitiativeStatusWizardQuery>;
export type InitiativeStatusWizardLazyQueryHookResult = ReturnType<typeof useInitiativeStatusWizardLazyQuery>;
export type InitiativeStatusWizardSuspenseQueryHookResult = ReturnType<typeof useInitiativeStatusWizardSuspenseQuery>;
export type InitiativeStatusWizardQueryResult = Apollo.QueryResult<InitiativeStatusWizardQuery, InitiativeStatusWizardQueryVariables>;