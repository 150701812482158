import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import UserAvatar from 'user/UserAvatar/UserAvatar';
import { date } from 'shared/services/date.service';

import type { FollowUpActivityItem } from './ContributionMyFollowUpsViewMore.type';

const Container = styled.div`
  color: ${(props) => props.theme.color.typoSecondary};
  display: flex;
  gap: 12px;
  margin-bottom: 4px;
  margin-top: 4px;
`;

type Props = {
  item: FollowUpActivityItem;
};

const TimelineEntryMessage = ({ item }: Props) => {
  const { t } = useTranslation();

  const message = {
    'status-request': t('followUps.timeline.insightRequest.header', {
      date: item.requestSubmitTime
        ? date.formatShort(item.requestSubmitTime)
        : '-',
    }),
    'walk-of-strategy': t('followUps.timeline.campaignRequest.header', {
      date: item.requestSubmitTime
        ? date.formatShort(item.requestSubmitTime)
        : '-',
    }),
    'one-thing': t('followUps.timeline.oneThingCampaignRequest.header', {
      date: item.requestSubmitTime
        ? date.formatShort(item.requestSubmitTime)
        : '-',
    }),
  }[item.type];

  return (
    <Container>
      <UserAvatar
        user={item.requestSubmitter}
        hasLabel={false}
        size={'small'}
      />
      <div>{message}</div>
    </Container>
  );
};

export default TimelineEntryMessage;
