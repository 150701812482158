import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'shared/components/Button';
import AiSophieIcon from 'ai/AiSophieIcon';
import useAiAssistentActive from 'ai/useAiAssistentActive';
import { AskSophieProvider, type PromptMessage } from 'ai/AskSophieChat';

import StrategySummarySidePanel from './AskSophieSidePanel';

type Props = {
  actionIds?: string[];
  infoText: string;
  initiativeIds?: string[];
  metricIds?: string[];
  objectiveIds?: string[];
  onOpen?: () => void;
  promptMessages: PromptMessage[];
  strategyId?: string;
};

const AskSohpieButton = ({
  promptMessages,
  objectiveIds = [],
  metricIds = [],
  initiativeIds = [],
  actionIds = [],
  strategyId,
  infoText,
  onOpen,
}: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { isAiAssistantActive } = useAiAssistentActive();

  const handleOpen = useCallback(() => {
    onOpen?.();
    setIsOpen(true);
  }, [onOpen]);

  if (!isAiAssistantActive) return null;

  return (
    <>
      <Button
        variant={'outlined'}
        onPress={handleOpen}
        icon={AiSophieIcon}
        iconPosition={'start'}
      >
        {t('ai.strategySummary.title')}
      </Button>

      <AskSophieProvider
        objectiveIds={objectiveIds}
        metricIds={metricIds}
        initiativeIds={initiativeIds}
        actionIds={actionIds}
        strategyId={strategyId}
      >
        <StrategySummarySidePanel
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          promptMessages={promptMessages}
          infoText={infoText}
        />
      </AskSophieProvider>
    </>
  );
};

export default AskSohpieButton;
