import type * as Types from '../../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LinkObjectiveToOrgUnitMutationVariables = Types.Exact<{
  objectiveId: Types.Scalars['ID']['input'];
  orgUnitId: Types.Scalars['ID']['input'];
}>;


export type LinkObjectiveToOrgUnitMutation = { __typename: 'Mutation', updateObjective: { __typename: 'Objective', id: string, orgUnitLinks: Array<{ __typename: 'OrgUnit', id: string }> } };

export type UnlinkObjectiveFromOrgUnitMutationVariables = Types.Exact<{
  objectiveId: Types.Scalars['ID']['input'];
  orgUnitId: Types.Scalars['ID']['input'];
}>;


export type UnlinkObjectiveFromOrgUnitMutation = { __typename: 'Mutation', updateObjective: { __typename: 'Objective', id: string, orgUnitLinks: Array<{ __typename: 'OrgUnit', id: string }> } };


export const LinkObjectiveToOrgUnitDocument = gql`
    mutation LinkObjectiveToOrgUnit($objectiveId: ID!, $orgUnitId: ID!) {
  updateObjective(
    input: {idToUpdate: $objectiveId, orgUnitLinks: {idsToSet: [$orgUnitId]}}
  ) {
    id
    orgUnitLinks {
      id
    }
  }
}
    `;
export type LinkObjectiveToOrgUnitMutationFn = Apollo.MutationFunction<LinkObjectiveToOrgUnitMutation, LinkObjectiveToOrgUnitMutationVariables>;

/**
 * __useLinkObjectiveToOrgUnitMutation__
 *
 * To run a mutation, you first call `useLinkObjectiveToOrgUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkObjectiveToOrgUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkObjectiveToOrgUnitMutation, { data, loading, error }] = useLinkObjectiveToOrgUnitMutation({
 *   variables: {
 *      objectiveId: // value for 'objectiveId'
 *      orgUnitId: // value for 'orgUnitId'
 *   },
 * });
 */
export function useLinkObjectiveToOrgUnitMutation(baseOptions?: Apollo.MutationHookOptions<LinkObjectiveToOrgUnitMutation, LinkObjectiveToOrgUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LinkObjectiveToOrgUnitMutation, LinkObjectiveToOrgUnitMutationVariables>(LinkObjectiveToOrgUnitDocument, options);
      }
export type LinkObjectiveToOrgUnitMutationHookResult = ReturnType<typeof useLinkObjectiveToOrgUnitMutation>;
export type LinkObjectiveToOrgUnitMutationResult = Apollo.MutationResult<LinkObjectiveToOrgUnitMutation>;
export type LinkObjectiveToOrgUnitMutationOptions = Apollo.BaseMutationOptions<LinkObjectiveToOrgUnitMutation, LinkObjectiveToOrgUnitMutationVariables>;
export const UnlinkObjectiveFromOrgUnitDocument = gql`
    mutation UnlinkObjectiveFromOrgUnit($objectiveId: ID!, $orgUnitId: ID!) {
  updateObjective(
    input: {idToUpdate: $objectiveId, orgUnitLinks: {idsToRemove: [$orgUnitId]}}
  ) {
    id
    orgUnitLinks {
      id
    }
  }
}
    `;
export type UnlinkObjectiveFromOrgUnitMutationFn = Apollo.MutationFunction<UnlinkObjectiveFromOrgUnitMutation, UnlinkObjectiveFromOrgUnitMutationVariables>;

/**
 * __useUnlinkObjectiveFromOrgUnitMutation__
 *
 * To run a mutation, you first call `useUnlinkObjectiveFromOrgUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkObjectiveFromOrgUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkObjectiveFromOrgUnitMutation, { data, loading, error }] = useUnlinkObjectiveFromOrgUnitMutation({
 *   variables: {
 *      objectiveId: // value for 'objectiveId'
 *      orgUnitId: // value for 'orgUnitId'
 *   },
 * });
 */
export function useUnlinkObjectiveFromOrgUnitMutation(baseOptions?: Apollo.MutationHookOptions<UnlinkObjectiveFromOrgUnitMutation, UnlinkObjectiveFromOrgUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlinkObjectiveFromOrgUnitMutation, UnlinkObjectiveFromOrgUnitMutationVariables>(UnlinkObjectiveFromOrgUnitDocument, options);
      }
export type UnlinkObjectiveFromOrgUnitMutationHookResult = ReturnType<typeof useUnlinkObjectiveFromOrgUnitMutation>;
export type UnlinkObjectiveFromOrgUnitMutationResult = Apollo.MutationResult<UnlinkObjectiveFromOrgUnitMutation>;
export type UnlinkObjectiveFromOrgUnitMutationOptions = Apollo.BaseMutationOptions<UnlinkObjectiveFromOrgUnitMutation, UnlinkObjectiveFromOrgUnitMutationVariables>;