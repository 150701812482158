import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Markdown from 'markdown-to-jsx';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Breadcrumbs from 'shared/components/Breadcrumbs';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import Flex from 'shared/components/Flex';
import Heading from 'shared/components/Heading';
import Spinner from 'shared/spinner/Spinner';
import { getAssessmentTypeName } from 'assessment/assessment.utils';
import AssessmentContextMenu from 'assessment/AssessmentContextMenu/AssessmentContextMenu';
import { teamRoutesPaths } from 'team/team.routing.paths';
import { useTeamSlug } from 'team/TeamAdapter/useTeamSlug';
import UserAvatar from 'user/UserAvatar';
import TeamAvatar from 'team/TeamAvatar/TeamAvatar';

import { useViewAssessmentQuery } from './ViewAssessment.graphql.generated';
import { getBreadcrumbItems } from './ViewAssessment.utils';

const Content = styled.div`
  table {
    th {
      padding: 6px;
    }

    td {
      border: 1px solid ${(props) => props.theme.color.strokeMedium};
      padding: 6px;
    }
  }
`;

const ViewAssessment = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { teamSlug } = useTeamSlug();

  const { assessmentId } = useParams<{ assessmentId: string }>();

  const { activeOrg } = useActiveOrg();
  const { orgUnit } = useOrgUnit();

  const { data } = useViewAssessmentQuery({
    variables: { assessmentId: assessmentId || '' },
    skip: !assessmentId,
  });

  const breadcrumbsItems = useMemo(
    () => getBreadcrumbItems({ t, activeOrg, orgUnit }),
    [activeOrg, orgUnit, t],
  );

  if (!data) return <Spinner.Circle />;

  return (
    <Flex direction={'column'} css={css({ margin: '1rem 3rem' })}>
      <Breadcrumbs items={breadcrumbsItems} />
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <Flex gap={8}>
          <TeamAvatar
            orgUnit={data.strategyAssessment.orgUnit}
            size={'small'}
          />
          <Heading level={3} isBold={false}>
            <b>{t('assessment.viewAssessment.title')}:</b>{' '}
            {data.strategyAssessment.title ||
              getAssessmentTypeName(t, data.strategyAssessment.type)}
          </Heading>
        </Flex>

        <Flex gap={8}>
          <UserAvatar user={data.strategyAssessment.owner} />
          <AssessmentContextMenu
            assessment={data.strategyAssessment}
            onDelete={() =>
              navigate(
                teamRoutesPaths.insights.assessments.root({
                  params: { teamSlug },
                }),
              )
            }
          />
        </Flex>
      </Flex>
      <Content>
        <Markdown>{data.strategyAssessment.content}</Markdown>
      </Content>
    </Flex>
  );
};

export default ViewAssessment;
