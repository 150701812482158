import type { RouteObject } from 'react-router-dom';

import { contributionsRoutes } from 'contribution/contribution.routing';
import StrategyRoom from 'strategy/StrategyRoom';
import { strategyRoutes } from 'strategy/strategy.routing';
import { objectiveRoutes } from 'objective/objective.routing';
import NavigateToTeamStrategyRoot from 'team/NavigateToTeamStrategyRoot';
import TeamMembers from 'team/TeamMembers';
import MyTeams from 'team/MyTeams';
import TeamTeamHierarchy from 'team/TeamTeamHierarchy';
import StrategyLayout from 'strategy/StrategyLayout';
import StrategyBoard from 'strategy/StrategyBoard';
import TeamInsights from 'team/TeamInsights';
import StrategyInsightsReports from 'strategy/StrategyInsightsReports';
import StrategyInsightsAssessments from 'strategy/StrategyInsightsAssessments';
import ViewAssessment from 'assessment/ViewAssessment';
import { assessmentRoutes } from 'assessment/assessment.routing';

import { teamRoutesPaths } from './team.routing.paths';
import TeamInsightsCreateReportButton from './TeamInsights/TeamInsightsCreateReportButton';

export const teamRoutes: RouteObject[] = [
  {
    path: teamRoutesPaths.root(),
    element: <NavigateToTeamStrategyRoot />,
  },
  {
    path: teamRoutesPaths.strategyRoom({
      allowNestedRoutes: true,
    }),
    element: <StrategyRoom />,
  },
  {
    path: teamRoutesPaths.members.root({
      allowNestedRoutes: true,
    }),
    element: <TeamMembers />,
  },
  {
    path: teamRoutesPaths.myTeams({
      allowNestedRoutes: true,
    }),
    element: <MyTeams />,
  },
  {
    path: teamRoutesPaths.teamHierarchy({ allowNestedRoutes: true }),
    element: <TeamTeamHierarchy />,
  },
  {
    path: teamRoutesPaths.strategyBoard({
      allowNestedRoutes: true,
    }),
    element: <StrategyLayout />,
    children: [
      {
        index: true,
        element: <StrategyBoard />,
      },
    ],
  },
  {
    path: teamRoutesPaths.insights.root(),
    element: <StrategyLayout />,
    children: [
      {
        index: true,
        element: <TeamInsights.NavigateToTeamInsightsOverview />,
      },
      {
        path: teamRoutesPaths.insights.overview(),
        element: <TeamInsights.Overview />,
      },
      {
        path: teamRoutesPaths.insights.reports.root(),
        element: (
          <TeamInsights.Layout controls={<TeamInsightsCreateReportButton />}>
            <StrategyInsightsReports />
          </TeamInsights.Layout>
        ),
        children: [
          {
            path: teamRoutesPaths.insights.reports.view(),
            element: <StrategyInsightsReports.ViewReport />,
          },
          {
            path: teamRoutesPaths.insights.reports.create(),
            element: <StrategyInsightsReports.CreateReport />,
          },
          {
            path: teamRoutesPaths.insights.reports.edit(),
            element: <StrategyInsightsReports.EditReport />,
          },
        ],
      },
      {
        path: teamRoutesPaths.insights.assessments.root(),
        element: <StrategyInsightsAssessments />,
      },
      {
        path: teamRoutesPaths.insights.assessments.root(),
        element: <ViewAssessment />,
      },
    ],
  },
  ...strategyRoutes,
  ...objectiveRoutes,
  ...contributionsRoutes,
  ...assessmentRoutes,
];
