import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserProfileFormQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserProfileFormQuery = { __typename: 'Query', allOrgUnits: { __typename: 'OrgUnitConnection', edges: Array<{ __typename: 'OrgUnitEdge', node: { __typename: 'OrgUnit', id: string, name?: string | undefined } }> } };


export const UserProfileFormDocument = gql`
    query UserProfileForm {
  allOrgUnits {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useUserProfileFormQuery__
 *
 * To run a query within a React component, call `useUserProfileFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProfileFormQuery(baseOptions?: Apollo.QueryHookOptions<UserProfileFormQuery, UserProfileFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserProfileFormQuery, UserProfileFormQueryVariables>(UserProfileFormDocument, options);
      }
export function useUserProfileFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProfileFormQuery, UserProfileFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserProfileFormQuery, UserProfileFormQueryVariables>(UserProfileFormDocument, options);
        }
export function useUserProfileFormSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserProfileFormQuery, UserProfileFormQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserProfileFormQuery, UserProfileFormQueryVariables>(UserProfileFormDocument, options);
        }
export type UserProfileFormQueryHookResult = ReturnType<typeof useUserProfileFormQuery>;
export type UserProfileFormLazyQueryHookResult = ReturnType<typeof useUserProfileFormLazyQuery>;
export type UserProfileFormSuspenseQueryHookResult = ReturnType<typeof useUserProfileFormSuspenseQuery>;
export type UserProfileFormQueryResult = Apollo.QueryResult<UserProfileFormQuery, UserProfileFormQueryVariables>;