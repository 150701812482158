import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { UserLimitedProfileFragmentDoc } from '../../user/userProfile.graphql.generated';
import { OrgUnitForTeamImageFragmentDoc } from '../../orgUnit/OrgUnit.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ViewAssessmentQueryVariables = Types.Exact<{
  assessmentId: Types.Scalars['ID']['input'];
}>;


export type ViewAssessmentQuery = { __typename: 'Query', strategyAssessment: { __typename: 'StrategyAssessment', id: string, type: Types.StrategyAssessmentType, title?: string | undefined, content: string, createDateTime: Date, owner: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean }, orgUnit?: { __typename: 'OrgUnit', currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined } | undefined } };


export const ViewAssessmentDocument = gql`
    query ViewAssessment($assessmentId: ID!) {
  strategyAssessment(id: $assessmentId) {
    id
    type
    title
    content
    createDateTime
    owner {
      ...userLimitedProfile
    }
    orgUnit {
      currentUserAuthorizedActions
      ...orgUnitForTeamImage
    }
  }
}
    ${UserLimitedProfileFragmentDoc}
${OrgUnitForTeamImageFragmentDoc}`;

/**
 * __useViewAssessmentQuery__
 *
 * To run a query within a React component, call `useViewAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewAssessmentQuery({
 *   variables: {
 *      assessmentId: // value for 'assessmentId'
 *   },
 * });
 */
export function useViewAssessmentQuery(baseOptions: Apollo.QueryHookOptions<ViewAssessmentQuery, ViewAssessmentQueryVariables> & ({ variables: ViewAssessmentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewAssessmentQuery, ViewAssessmentQueryVariables>(ViewAssessmentDocument, options);
      }
export function useViewAssessmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewAssessmentQuery, ViewAssessmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewAssessmentQuery, ViewAssessmentQueryVariables>(ViewAssessmentDocument, options);
        }
export function useViewAssessmentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ViewAssessmentQuery, ViewAssessmentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ViewAssessmentQuery, ViewAssessmentQueryVariables>(ViewAssessmentDocument, options);
        }
export type ViewAssessmentQueryHookResult = ReturnType<typeof useViewAssessmentQuery>;
export type ViewAssessmentLazyQueryHookResult = ReturnType<typeof useViewAssessmentLazyQuery>;
export type ViewAssessmentSuspenseQueryHookResult = ReturnType<typeof useViewAssessmentSuspenseQuery>;
export type ViewAssessmentQueryResult = Apollo.QueryResult<ViewAssessmentQuery, ViewAssessmentQueryVariables>;