import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

import { date } from 'shared/services/date.service';
import type { Report } from 'types.graphql.generated';
import type { ModalProps } from 'shared/components/__DEPRECATED__/Modal';
import Modal from 'shared/components/__DEPRECATED__/Modal';
import Scrollspy from 'shared/components/Scrollspy';
import Space from 'shared/components/Space';
import { ReactComponent as DownloadIcon } from 'shared/static/icons/icon-download.svg';
import { useExportReportMutation } from 'report/report.graphql.generated';
import useHandleError from 'shared/errors/useHandleError';

import styles from './InsightReportSummary.module.scss';
import Provider, {
  useInsightReportSummaryReport,
} from './InsightReportSummaryProvider';
import Jumbotron from './InsightReportSummaryJumbotron';
import ExportReportModal from './InsightReportSummaryExportReportModal';
import { createSections } from './InsightReportSummary.static';

export type InsightReportSummaryProps = Pick<
  ModalProps,
  'isOpen' | 'onClose'
> & {
  report: Pick<Report, 'id'>;
};

const InsightReportSummary = ({
  isOpen,
  onClose,
}: InsightReportSummaryProps) => {
  const { t } = useTranslation();

  const { report } = useInsightReportSummaryReport();

  const [isExportReportModalOpened, toggleIsExportReportModalOpened] =
    useToggle(false);

  const handleApolloError = useHandleError();

  const [
    exportReport,
    { loading: isExportReportLoading, error: exportReportError },
  ] = useExportReportMutation({
    variables: { id: report.id },
    onError: handleApolloError,
  });

  const sections = useMemo(
    () =>
      report.auditRecord.createDateTime
        ? createSections(t, date.getYear(report.auditRecord.createDateTime))
        : [],
    [report.auditRecord.createDateTime, t],
  );

  return (
    <>
      <Modal
        size={'full'}
        onClose={onClose}
        isOpen={isOpen}
        heading={report.name}
        scrollType={'outer'}
        headerButtons={[
          {
            icon: DownloadIcon,
            children: t('report.insightReportSummary.exportButton'),
            onClick: () => {
              toggleIsExportReportModalOpened(true);
              exportReport();
            },
          },
        ]}
      >
        <div className={styles.container}>
          <Jumbotron />
          <Scrollspy.Provider>
            <div className={styles.content}>
              <div className={styles.scrollSpyContainer}>
                <Scrollspy
                  anchors={sections.map(({ id, heading }) => ({
                    waypointId: id,
                    children: heading,
                  }))}
                  className={styles.scrollSpy}
                />
              </div>
              <Space
                direction={'vertical'}
                size={'big'}
                className={styles.contentInside}
              >
                {sections.map(({ render, ...props }) => (
                  <Fragment key={props.id}>{render(props)}</Fragment>
                ))}
              </Space>
            </div>
          </Scrollspy.Provider>
        </div>
      </Modal>
      <ExportReportModal
        reportName={report.name || ''}
        isError={!!exportReportError}
        isLoading={isExportReportLoading}
        isOpen={isExportReportModalOpened}
        onClose={toggleIsExportReportModalOpened}
      />
    </>
  );
};

export default (props: InsightReportSummaryProps) => (
  <Provider report={props.report}>
    <InsightReportSummary {...props} />
  </Provider>
);
