import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveOrgUnitMemberMutationVariables = Types.Exact<{
  idToUpdate: Types.Scalars['ID']['input'];
  userIdToRemove: Types.Scalars['ID']['input'];
}>;


export type RemoveOrgUnitMemberMutation = { __typename: 'Mutation', updateOrgUnit: { __typename: 'OrgUnit', id: string, orgUnitMembersCount?: number | undefined, orgUnitMembersCascadingTotalCount?: number | undefined, orgUnitMembershipsCascadingTotalCount: number, parentOrgUnitTree: Array<{ __typename: 'OrgUnit', id: string, orgUnitMembersCount?: number | undefined, orgUnitMembersCascadingTotalCount?: number | undefined, orgUnitMembershipsCascadingTotalCount: number }>, orgUnitMembers: { __typename: 'UserConnection', edges: Array<{ __typename: 'UserEdge', node: { __typename: 'User', id: string } }> }, orgUnitMemberships: { __typename: 'OrgUnitMembershipList', memberships: Array<{ __typename: 'OrgUnitMembership', user: { __typename: 'UserLimited', id: string } }> } } };


export const RemoveOrgUnitMemberDocument = gql`
    mutation RemoveOrgUnitMember($idToUpdate: ID!, $userIdToRemove: ID!) {
  updateOrgUnit(
    input: {idToUpdate: $idToUpdate, orgUnitMembers: [{idToRemove: $userIdToRemove}]}
  ) {
    id
    orgUnitMembersCount
    orgUnitMembersCascadingTotalCount
    orgUnitMembershipsCascadingTotalCount
    parentOrgUnitTree {
      id
      orgUnitMembersCount
      orgUnitMembersCascadingTotalCount
      orgUnitMembershipsCascadingTotalCount
    }
    orgUnitMembers {
      edges {
        node {
          id
        }
      }
    }
    orgUnitMemberships {
      memberships {
        user {
          id
        }
      }
    }
  }
}
    `;
export type RemoveOrgUnitMemberMutationFn = Apollo.MutationFunction<RemoveOrgUnitMemberMutation, RemoveOrgUnitMemberMutationVariables>;

/**
 * __useRemoveOrgUnitMemberMutation__
 *
 * To run a mutation, you first call `useRemoveOrgUnitMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrgUnitMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrgUnitMemberMutation, { data, loading, error }] = useRemoveOrgUnitMemberMutation({
 *   variables: {
 *      idToUpdate: // value for 'idToUpdate'
 *      userIdToRemove: // value for 'userIdToRemove'
 *   },
 * });
 */
export function useRemoveOrgUnitMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOrgUnitMemberMutation, RemoveOrgUnitMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveOrgUnitMemberMutation, RemoveOrgUnitMemberMutationVariables>(RemoveOrgUnitMemberDocument, options);
      }
export type RemoveOrgUnitMemberMutationHookResult = ReturnType<typeof useRemoveOrgUnitMemberMutation>;
export type RemoveOrgUnitMemberMutationResult = Apollo.MutationResult<RemoveOrgUnitMemberMutation>;
export type RemoveOrgUnitMemberMutationOptions = Apollo.BaseMutationOptions<RemoveOrgUnitMemberMutation, RemoveOrgUnitMemberMutationVariables>;