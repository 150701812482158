import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteOrgUnitDeleteOrgUnitMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteOrgUnitDeleteOrgUnitMutation = { __typename: 'Mutation', deleteOrgUnits: boolean };

export type OrgUnitForDeleteFragment = { __typename: 'OrgUnit', id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined, currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction> };

export const OrgUnitForDeleteFragmentDoc = gql`
    fragment orgUnitForDelete on OrgUnit {
  id
  name
  nameAbbreviated
  backgroundColorAsHex
  currentUserAuthorizedActions
}
    `;
export const DeleteOrgUnitDeleteOrgUnitDocument = gql`
    mutation DeleteOrgUnitDeleteOrgUnit($id: ID!) {
  deleteOrgUnits(idsToDelete: [$id])
}
    `;
export type DeleteOrgUnitDeleteOrgUnitMutationFn = Apollo.MutationFunction<DeleteOrgUnitDeleteOrgUnitMutation, DeleteOrgUnitDeleteOrgUnitMutationVariables>;

/**
 * __useDeleteOrgUnitDeleteOrgUnitMutation__
 *
 * To run a mutation, you first call `useDeleteOrgUnitDeleteOrgUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrgUnitDeleteOrgUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrgUnitDeleteOrgUnitMutation, { data, loading, error }] = useDeleteOrgUnitDeleteOrgUnitMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrgUnitDeleteOrgUnitMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrgUnitDeleteOrgUnitMutation, DeleteOrgUnitDeleteOrgUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrgUnitDeleteOrgUnitMutation, DeleteOrgUnitDeleteOrgUnitMutationVariables>(DeleteOrgUnitDeleteOrgUnitDocument, options);
      }
export type DeleteOrgUnitDeleteOrgUnitMutationHookResult = ReturnType<typeof useDeleteOrgUnitDeleteOrgUnitMutation>;
export type DeleteOrgUnitDeleteOrgUnitMutationResult = Apollo.MutationResult<DeleteOrgUnitDeleteOrgUnitMutation>;
export type DeleteOrgUnitDeleteOrgUnitMutationOptions = Apollo.BaseMutationOptions<DeleteOrgUnitDeleteOrgUnitMutation, DeleteOrgUnitDeleteOrgUnitMutationVariables>;