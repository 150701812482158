import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { css } from '@emotion/react';

import type { SummaryForEdit } from 'ai/AskSophieChat';
import AskSophieChat, { AskSophieProvider } from 'ai/AskSophieChat';
import Modal from 'shared/components/Modal';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import Flex from 'shared/components/Flex';
import Button from 'shared/components/Button';
import type { StrategyAssessmentInput } from 'types.graphql.generated';
import Spinner from 'shared/spinner/Spinner';
import useHandleError from 'shared/errors/useHandleError';
import { ReactComponent as EditIcon } from 'shared/static/icons/icon-edit.svg';

import type { AssessmentFormValues } from './AssessmentForm';
import AssessmentForm from './AssessmentForm';
import { useAssesmentWizardModalQuery } from './AssesmentWizardModal.graphql.generated';
import useAssessmentPromptMessages from './useAssessmentPromptMessages';

type Props = {
  initialValues?: AssessmentFormValues;
  isOpen: boolean;
  isSubmitDisabled: boolean;
  onClose: () => void;
  onSubmit: (input: StrategyAssessmentInput) => void;
};

const AssesmentWizardModal = ({
  isOpen,
  onClose,
  initialValues,
  onSubmit,
  isSubmitDisabled,
}: Props) => {
  const { t } = useTranslation();

  const { activeOrg } = useActiveOrg();
  const { orgUnit } = useOrgUnit();
  const [modalOverlayElement, setModalOverlayElement] =
    useState<HTMLDivElement | null>(null);

  const [isEditOpen, setIsEditOpen] = useState(false);

  const [editSummary, setEditSummary] = useState<SummaryForEdit>();

  useEffect(() => {
    if (isOpen) {
      setIsEditOpen(!!initialValues && initialValues.content.length > 0);
    }
  }, [initialValues, isOpen]);

  const onError = useHandleError();

  const { data } = useAssesmentWizardModalQuery({
    variables: {
      filter: {
        orgUnitIds: orgUnit ? { value: [orgUnit.id] } : { operator: 'IS_NULL' },
      },
    },
    onError,
    skip: !isOpen,
  });

  const promptMessages = useAssessmentPromptMessages();

  const strategyId = orgUnit
    ? orgUnit.currentStrategy?.id
    : activeOrg.currentStrategy?.id;

  const objectiveIds = data?.allObjectives.edges.map((edge) => edge.node.id);
  const metricIds = data?.allObjectives.edges.flatMap((edge) =>
    edge.node.metrics.map((metric) => metric.id),
  );
  const initiativeIds = data?.allObjectives.edges.flatMap((edge) =>
    edge.node.initiatives.map((initiative) => initiative.id),
  );
  const actionIds = data?.allObjectives.edges.flatMap((edge) =>
    edge.node.actions.map((action) => action.id),
  );

  return (
    <Modal
      headerTitle={t('assessment.createAssessment.title')}
      isOpen={isOpen}
      onClose={onClose}
      hasPadding={false}
      fullWidth={true}
      overlayRef={setModalOverlayElement}
    >
      {data && initialValues ? (
        <Flex justifyContent={'center'} grow={1} css={css({ height: '100%' })}>
          <div
            css={css({
              flexGrow: 1,
              flexBasis: 0,
              overflow: 'hidden',
              overflowY: 'auto',
            })}
          >
            <AskSophieProvider
              strategyId={strategyId}
              objectiveIds={objectiveIds}
              metricIds={metricIds}
              initiativeIds={initiativeIds}
              actionIds={actionIds}
              summaryFooter={(summary: SummaryForEdit) => (
                <Button
                  icon={EditIcon}
                  iconPosition={'start'}
                  variant={isEditOpen ? 'outlined' : 'contained'}
                  onPress={() => {
                    setEditSummary(summary);
                    setIsEditOpen(true);
                  }}
                >
                  {t('assessment.summary.editButton')}
                </Button>
              )}
            >
              <AskSophieChat
                infoText={''}
                isOpen={isOpen}
                promptMessages={promptMessages}
                hideFreeText={true}
              />
            </AskSophieProvider>
          </div>

          <Flex
            css={css({
              flexGrow: 1,
              flexBasis: 0,
              display: isEditOpen ? 'flex' : 'none',
              overflowY: 'auto',
              padding: 20,
            })}
          >
            <AssessmentForm
              initialValues={{ ...initialValues, ...editSummary }}
              onSubmit={onSubmit}
              isSubmitDisabled={isSubmitDisabled}
              overlayElement={modalOverlayElement}
            />
          </Flex>
        </Flex>
      ) : (
        <Spinner.Circle />
      )}
    </Modal>
  );
};

export default AssesmentWizardModal;
