import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { capitalize } from 'lodash';

import { date } from 'shared/services/date.service';
import Card from 'shared/components/Card';
import Text from 'shared/components/Text';
import Space from 'shared/components/Space';
import EmojiBadge from 'shared/components/EmojiBadge';
import { useTeamAdapter } from 'team/TeamAdapter';

import type { StrategyRoadmapSideModalProps } from '../StrategyRoadmapSideModal';
import { illustrations, backgroundColors } from '../StrategyRoadmap.static';
import StrategyRoadmapSideModal from '../StrategyRoadmapSideModal';
import type { StrategyRoadmapStrategyFieldsFragment } from '../StrategyRoadmapProvider/StrategyRoadmapProvider.graphql.generated';
import { getEditStrategyPath } from '../StrategyRoadmap.utils';

type StrategyRoadmapValuesModalProps = {
  showEditButton: boolean;
  strategy: StrategyRoadmapStrategyFieldsFragment;
} & Pick<StrategyRoadmapSideModalProps, 'isOpen' | 'onClose'>;

const StrategyRoadmapValuesModal = ({
  strategy,
  isOpen,
  onClose,
  showEditButton,
}: StrategyRoadmapValuesModalProps) => {
  const { values } = strategy;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { teamAdapter } = useTeamAdapter();

  const content = useMemo(
    () => (
      <Space direction={'vertical'}>
        {values.map((value) => (
          <Card
            border={'full'}
            padding={'small'}
            hasBackground={true}
            key={value.id}
          >
            <Card.Heading before={<EmojiBadge>{value.symbol}</EmojiBadge>}>
              {value.name}
            </Card.Heading>
            <Text variant={'emphasis'} whiteSpace={'preWrap'}>
              {value.description}
            </Text>
          </Card>
        ))}
      </Space>
    ),
    [values],
  );

  const updateDateTime = date.max(
    values.map((value) => value.auditRecord.updateDateTime).filter(Boolean),
  );

  const handleEdit = useCallback(() => {
    onClose();
    navigate(getEditStrategyPath(teamAdapter, strategy, 'values'));
  }, [onClose, navigate, teamAdapter, strategy]);

  return (
    <>
      <StrategyRoadmapSideModal
        top={{
          illustration: illustrations.values,
          backgroundColor: backgroundColors.values,
        }}
        heading={capitalize(t('strategy.value_other'))}
        description={t('strategy.strategyRoadmap.values.modal.description')}
        updateDateTime={updateDateTime}
        onEdit={showEditButton ? handleEdit : undefined}
        isOpen={isOpen}
        onClose={onClose}
      >
        {content}
      </StrategyRoadmapSideModal>
    </>
  );
};

export default StrategyRoadmapValuesModal;
