type ResolveListInputParams<
  PreviousItem extends { id: string },
  NewItem extends { id?: string },
  Input extends object,
> = {
  includeInput?: (input: Input) => boolean;
  newItems: NewItem[];
  previousItems: PreviousItem[];
  resolveInput: (
    newItem: NewItem,
    newItemIndex: number,
    newItemsArray: NewItem[],
  ) => Input;
};

const defaultIncludeInput = () => true;

export const resolveListInput = <
  PreviousItem extends { id: string },
  NewItem extends { id?: string },
  Input extends object,
>(
  params: ResolveListInputParams<PreviousItem, NewItem, Input>,
): {
  add: Input[];
  idsToDelete: string[];
  update: Input[];
} => {
  const {
    resolveInput,
    previousItems,
    newItems,
    includeInput = defaultIncludeInput,
  } = params;
  const idsToDelete = previousItems
    .filter(
      (previousItem) =>
        !newItems.some((newItem) => newItem.id === previousItem.id),
    )
    .map((previousItem) => previousItem.id);
  const { add, update } = newItems.reduce<{ add: Input[]; update: Input[] }>(
    (accumulator, item, itemIndex, itemArray) => {
      const baseInput = resolveInput(item, itemIndex, itemArray);
      if (item.id) {
        accumulator.update.push({
          idToUpdate: item.id,
          ...baseInput,
        });
      } else if (includeInput(baseInput)) {
        accumulator.add.push(baseInput);
      }
      return accumulator;
    },
    {
      add: [],
      update: [],
    },
  );
  return {
    idsToDelete,
    update,
    add,
  };
};
