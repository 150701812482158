import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ThemesOrgQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ThemesOrgQuery = { __typename: 'Query', activeOrg: { __typename: 'Org', id: string, themes: { __typename: 'ThemeConnection', edges: Array<{ __typename: 'ThemeEdge', node: { __typename: 'Theme', id: string, name?: string | undefined, description?: string | undefined, orderNumber?: number | undefined, isActive: boolean, objectives: Array<{ __typename: 'Objective', id: string, name?: string | undefined, currentObjectiveStatus?: { __typename: 'ObjectiveStatus', id: string, statusIndicator: StatusIndicatorClass, auditRecord: { __typename: 'AuditRecord', updateDateTime?: Date | undefined } } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined }>, auditRecord: { __typename: 'AuditRecord', updateDateTime?: Date | undefined }, themeCategory?: { __typename: 'ThemeCategory', id: string, name?: string | undefined } | undefined } }> } } };

export type ThemesOrgUnitQueryVariables = Types.Exact<{
  orgUnitId: Types.Scalars['ID']['input'];
}>;


export type ThemesOrgUnitQuery = { __typename: 'Query', orgUnit: { __typename: 'OrgUnit', id: string, themes: { __typename: 'ThemeConnection', edges: Array<{ __typename: 'ThemeEdge', node: { __typename: 'Theme', id: string, name?: string | undefined, description?: string | undefined, orderNumber?: number | undefined, isActive: boolean, objectives: Array<{ __typename: 'Objective', id: string, name?: string | undefined, currentObjectiveStatus?: { __typename: 'ObjectiveStatus', id: string, statusIndicator: StatusIndicatorClass, auditRecord: { __typename: 'AuditRecord', updateDateTime?: Date | undefined } } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined }>, auditRecord: { __typename: 'AuditRecord', updateDateTime?: Date | undefined }, themeCategory?: { __typename: 'ThemeCategory', id: string, name?: string | undefined } | undefined } }> } } };

export type ThemeFragment = { __typename: 'Theme', id: string, name?: string | undefined, description?: string | undefined, orderNumber?: number | undefined, isActive: boolean, objectives: Array<{ __typename: 'Objective', id: string, name?: string | undefined, currentObjectiveStatus?: { __typename: 'ObjectiveStatus', id: string, statusIndicator: StatusIndicatorClass, auditRecord: { __typename: 'AuditRecord', updateDateTime?: Date | undefined } } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined }>, auditRecord: { __typename: 'AuditRecord', updateDateTime?: Date | undefined }, themeCategory?: { __typename: 'ThemeCategory', id: string, name?: string | undefined } | undefined };

export const ThemeFragmentDoc = gql`
    fragment theme on Theme {
  id
  name
  description
  orderNumber
  objectives {
    id
    name
    currentObjectiveStatus {
      id
      statusIndicator
      auditRecord {
        updateDateTime
      }
    }
    orgUnit {
      id
    }
  }
  auditRecord {
    updateDateTime
  }
  themeCategory {
    id
    name
  }
  isActive
}
    `;
export const ThemesOrgDocument = gql`
    query ThemesOrg {
  activeOrg {
    id
    themes(filter: {isActive: true, orgUnitIds: {operator: IS_NULL}}) {
      edges {
        node {
          ...theme
        }
      }
    }
  }
}
    ${ThemeFragmentDoc}`;

/**
 * __useThemesOrgQuery__
 *
 * To run a query within a React component, call `useThemesOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useThemesOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThemesOrgQuery({
 *   variables: {
 *   },
 * });
 */
export function useThemesOrgQuery(baseOptions?: Apollo.QueryHookOptions<ThemesOrgQuery, ThemesOrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThemesOrgQuery, ThemesOrgQueryVariables>(ThemesOrgDocument, options);
      }
export function useThemesOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThemesOrgQuery, ThemesOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThemesOrgQuery, ThemesOrgQueryVariables>(ThemesOrgDocument, options);
        }
export function useThemesOrgSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ThemesOrgQuery, ThemesOrgQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ThemesOrgQuery, ThemesOrgQueryVariables>(ThemesOrgDocument, options);
        }
export type ThemesOrgQueryHookResult = ReturnType<typeof useThemesOrgQuery>;
export type ThemesOrgLazyQueryHookResult = ReturnType<typeof useThemesOrgLazyQuery>;
export type ThemesOrgSuspenseQueryHookResult = ReturnType<typeof useThemesOrgSuspenseQuery>;
export type ThemesOrgQueryResult = Apollo.QueryResult<ThemesOrgQuery, ThemesOrgQueryVariables>;
export const ThemesOrgUnitDocument = gql`
    query ThemesOrgUnit($orgUnitId: ID!) {
  orgUnit(id: $orgUnitId) {
    id
    themes(filter: {isActive: true}) {
      edges {
        node {
          ...theme
        }
      }
    }
  }
}
    ${ThemeFragmentDoc}`;

/**
 * __useThemesOrgUnitQuery__
 *
 * To run a query within a React component, call `useThemesOrgUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useThemesOrgUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThemesOrgUnitQuery({
 *   variables: {
 *      orgUnitId: // value for 'orgUnitId'
 *   },
 * });
 */
export function useThemesOrgUnitQuery(baseOptions: Apollo.QueryHookOptions<ThemesOrgUnitQuery, ThemesOrgUnitQueryVariables> & ({ variables: ThemesOrgUnitQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThemesOrgUnitQuery, ThemesOrgUnitQueryVariables>(ThemesOrgUnitDocument, options);
      }
export function useThemesOrgUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThemesOrgUnitQuery, ThemesOrgUnitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThemesOrgUnitQuery, ThemesOrgUnitQueryVariables>(ThemesOrgUnitDocument, options);
        }
export function useThemesOrgUnitSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ThemesOrgUnitQuery, ThemesOrgUnitQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ThemesOrgUnitQuery, ThemesOrgUnitQueryVariables>(ThemesOrgUnitDocument, options);
        }
export type ThemesOrgUnitQueryHookResult = ReturnType<typeof useThemesOrgUnitQuery>;
export type ThemesOrgUnitLazyQueryHookResult = ReturnType<typeof useThemesOrgUnitLazyQuery>;
export type ThemesOrgUnitSuspenseQueryHookResult = ReturnType<typeof useThemesOrgUnitSuspenseQuery>;
export type ThemesOrgUnitQueryResult = Apollo.QueryResult<ThemesOrgUnitQuery, ThemesOrgUnitQueryVariables>;