import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { css, useTheme } from '@emotion/react';

import { ReactComponent as CalendarIcon } from 'shared/static/icons/icon-calendar.svg';
import UserAvatar from 'user/UserAvatar';
import { date } from 'shared/services/date.service';
import { StatusIndicator } from 'shared/status/StatusIndicator';
import StatusMark from 'shared/status/StatusMark';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';
import MetricContextMenu from 'metric/MetricContextMenu/MetricContextMenu';
import MaybeLinkIcon from 'objective/LinkIcon/MaybeLinkIcon';
import TeamAvatar from 'team/TeamAvatar/TeamAvatar';
import LockedLink from 'shared/components/LockedLink';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';

import type {
  MetricsListMetric,
  MetricsListObjective,
} from '../MetricsListProvider/MetricsListProvider.type';

type MetricRowProps = {
  metric: MetricsListMetric;
  objective: MetricsListObjective;
};

const Row = styled.li`
  align-items: center;
  display: flex;
  gap: 20px;
  max-height: 50px;
  padding: 16px 22px 16px 26px;
  position: relative;

  &::after {
    border-bottom: 1px solid ${({ theme }) => theme.color.strokeLight};
    bottom: 0;
    content: '';
    left: 26px;
    position: absolute;
    width: calc(100% - 48px); /* Adjusted for padding */
  }
`;

const Name = styled.div`
  align-items: center;
  display: flex;
  gap: 14px;
`;

const NameText = styled(LockedLink)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
  }
`;

const Timeline = styled.div`
  align-items: center;
  display: flex;
  flex-basis: 126px;
  flex-shrink: 0;
  gap: 6px;
  margin-left: auto;
`;

const TimelineIcon = styled(CalendarIcon)`
  color: ${({ theme }) => theme.color.typoSecondary};
  display: inline-block;
  flex-basis: 14px;
  min-height: 14px;
  min-width: 14px;
`;

const TimelineText = styled.div`
  color: ${({ theme }) => theme.color.typoSecondary};
  display: inline-block;
  min-width: 130px;
  white-space: nowrap;
`;

const AvatarContainer = styled.div`
  flex-shrink: 0;
`;

const AvatarContainerEmpty = styled.div`
  flex-shrink: 0;
  height: 28px;
  width: 28px;
`;

const MetricRow = ({ metric, objective }: MetricRowProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const statusIndicator =
    metric.currentMetricStatus?.statusIndicator || new StatusIndicator();

  const { teamAdapter } = useTeamAdapter();

  const linkTarget = objectiveRoutesPaths.metrics.metric.root({
    params: {
      objectiveId: objective.id,
      teamSlug: teamAdapter.toParam(),
      metricId: metric.id,
    },
  });

  const navigateToMetric = () => objective && navigate(linkTarget);

  const timelineRangeText = useMemo(() => {
    const dateEndTime =
      metric.timeLine?.endDate ?? metric.timeLine?.completeDateTime;

    const hasDates = !!(metric.timeLine?.startDate || dateEndTime);

    const startDateText = metric.timeLine?.startDate
      ? date.format(metric.timeLine?.startDate, 'MMM yyyy')
      : t('notSet');

    const endDateText = dateEndTime
      ? date.format(dateEndTime, 'MMM yyyy')
      : t('notSet');

    return hasDates ? `${startDateText} - ${endDateText}` : null;
  }, [
    metric.timeLine?.completeDateTime,
    metric.timeLine?.endDate,
    metric.timeLine?.startDate,
    t,
  ]);

  const canRead = canPerformStrategyElementAction(metric, 'READ');

  return (
    <Row
      onClick={canRead ? navigateToMetric : undefined}
      css={
        canRead &&
        css`
          &:hover {
            background-color: ${theme.color.hoverLight};
            cursor: pointer;
          }
        `
      }
    >
      <Name>
        <StatusMark statusIndicator={statusIndicator} hasBorder={false} />
        <NameText isActive={canRead} to={linkTarget}>
          {metric.name}
        </NameText>
        <MaybeLinkIcon strategyElement={metric} contextObjective={objective} />
      </Name>
      {timelineRangeText && (
        <Timeline>
          <TimelineIcon />
          <TimelineText>{timelineRangeText}</TimelineText>
        </Timeline>
      )}
      <TeamAvatar size={'tiny'} orgUnit={metric.objective?.orgUnit} />
      {metric.owner ? (
        <AvatarContainer>
          <UserAvatar user={metric.owner} />
        </AvatarContainer>
      ) : (
        <AvatarContainerEmpty />
      )}
      <MetricContextMenu metric={metric} contextObjective={objective} />
    </Row>
  );
};

export default MetricRow;
