const EnvPage = () => (
  <div style={{ margin: 20 }}>
    {Object.entries(process.env).map(([key, value]) => (
      <p key={key}>
        {key}:<br />
        {value}
      </p>
    ))}
  </div>
);

export default EnvPage;
