import { date } from 'shared/services/date.service';
import type { TimeLine } from 'types.graphql.generated';

export const calculateTimelineCompletionPercentage = (
  timeline?: Maybe<Partial<TimeLine>>,
) => {
  if (
    timeline?.completeDateTime ||
    (timeline?.endDate && date.isAfter(new Date(), timeline?.endDate))
  ) {
    return 100;
  } else if (timeline?.startDate && timeline?.endDate) {
    const endStartDifference = date.getDifference(
      timeline.endDate,
      timeline.startDate,
      'day',
    );
    const endTodayDifference = date.getDifference(
      timeline.endDate,
      new Date(),
      'day',
    );
    const endStartTodayDifference = endStartDifference - endTodayDifference;
    return Math.ceil((endStartTodayDifference / endStartDifference) * 100);
  }
  return 0;
};
