import Flex from 'shared/components/Flex';
import StrategyBoardList from 'strategy/StrategyBoard/StrategyBoardList';

import type { ObjectiveAlignmentObjectiveFragment } from './ObjectiveAlignment.graphql.generated';

type Props = { objective: ObjectiveAlignmentObjectiveFragment };

const ObjectiveAlignmentRow = ({ objective }: Props) => {
  const objectives = [
    { ...objective, objectiveId: objective.id, isLink: false },
  ];

  const metrics = objective.metrics.map((metric) => ({
    ...metric,
    objectiveId: objective.id,
    isLink: objective.id !== metric.objective?.id,
  }));

  const initiatives = objective.initiatives.map((initiative) => ({
    ...initiative,
    objectiveId: objective.id,
    isLink: objective.id !== initiative.objective?.id,
  }));

  return (
    <Flex gap={20}>
      <StrategyBoardList items={objectives} operationalItemType={'Objective'} />
      <StrategyBoardList items={metrics} operationalItemType={'Metric'} />
      <StrategyBoardList
        items={initiatives}
        operationalItemType={'Initiative'}
      />
    </Flex>
  );
};

export default ObjectiveAlignmentRow;
