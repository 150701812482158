import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { InitiativeOverviewInitiativeStatusFragmentDoc } from '../InitiativeOverview/InitiativeOverviewProvider/InitiativeOverviewProvider.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateInitiativeStatusMutationVariables = Types.Exact<{
  input: Types.InitiativeStatusInput;
}>;


export type UpdateInitiativeStatusMutation = { __typename: 'Mutation', updateInitiativeStatus: { __typename: 'InitiativeStatus', id: string, complete: boolean, keyIndicatorOnBudget?: boolean | undefined, keyIndicatorOnOutcome?: boolean | undefined, keyIndicatorOnTime?: boolean | undefined, comment?: string | undefined, statusDateTime: Date, statusIndicator: StatusIndicatorClass, initiative: { __typename: 'Initiative', id: string, timeLine: { __typename: 'TimeLine', completeDateTime?: Date | undefined }, currentInitiativeStatus?: { __typename: 'InitiativeStatus', id: string } | undefined, initiativeStatusListAll: Array<{ __typename: 'InitiativeStatus', id: string }>, milestones: Array<{ __typename: 'Milestone', id: string, currentMilestoneStatus?: { __typename: 'MilestoneStatus', id: string } | undefined }> }, auditRecord: { __typename: 'AuditRecord', createDateTime?: Date | undefined, updateDateTime?: Date | undefined, createBy?: { __typename: 'AuditRecordUser', displayName?: string | undefined, email?: string | undefined, photoUrl?: string | undefined } | undefined, updateBy?: { __typename: 'AuditRecordUser', displayName?: string | undefined, email?: string | undefined, photoUrl?: string | undefined } | undefined }, actionComments: Array<{ __typename: 'TextItem', id: string, text?: string | undefined }>, challengeComments: Array<{ __typename: 'TextItem', id: string, text?: string | undefined }>, successComments: Array<{ __typename: 'TextItem', id: string, text?: string | undefined }>, milestoneStatuses: Array<{ __typename: 'MilestoneStatus', id: string, comment?: string | undefined, complete: boolean, completionRate?: number | undefined, statusIndicator: StatusIndicatorClass, milestone: { __typename: 'Milestone', id: string, name?: string | undefined } }> } };


export const UpdateInitiativeStatusDocument = gql`
    mutation UpdateInitiativeStatus($input: InitiativeStatusInput!) {
  updateInitiativeStatus(input: $input) {
    ...initiativeOverviewInitiativeStatus
    initiative {
      id
      timeLine {
        completeDateTime
      }
      currentInitiativeStatus {
        id
      }
      initiativeStatusListAll {
        id
      }
      milestones {
        id
        currentMilestoneStatus {
          id
        }
      }
    }
  }
}
    ${InitiativeOverviewInitiativeStatusFragmentDoc}`;
export type UpdateInitiativeStatusMutationFn = Apollo.MutationFunction<UpdateInitiativeStatusMutation, UpdateInitiativeStatusMutationVariables>;

/**
 * __useUpdateInitiativeStatusMutation__
 *
 * To run a mutation, you first call `useUpdateInitiativeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInitiativeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInitiativeStatusMutation, { data, loading, error }] = useUpdateInitiativeStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInitiativeStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInitiativeStatusMutation, UpdateInitiativeStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInitiativeStatusMutation, UpdateInitiativeStatusMutationVariables>(UpdateInitiativeStatusDocument, options);
      }
export type UpdateInitiativeStatusMutationHookResult = ReturnType<typeof useUpdateInitiativeStatusMutation>;
export type UpdateInitiativeStatusMutationResult = Apollo.MutationResult<UpdateInitiativeStatusMutation>;
export type UpdateInitiativeStatusMutationOptions = Apollo.BaseMutationOptions<UpdateInitiativeStatusMutation, UpdateInitiativeStatusMutationVariables>;