import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InsightReportWizardControlsQueryVariables = Types.Exact<{
  reportId: Types.Scalars['ID']['input'];
}>;


export type InsightReportWizardControlsQuery = { __typename: 'Query', report: { __typename: 'Report', id: string, reportStage?: Types.ReportStage | undefined, reportSelection: { __typename: 'ReportSelection', reportObjectives: Array<{ __typename: 'ReportObjective', id: string, statusRequest?: { __typename: 'StatusRequest', id: string, isComplete: boolean } | undefined, reportMetrics: Array<{ __typename: 'ReportMetric', id: string, statusRequest?: { __typename: 'StatusRequest', id: string, isComplete: boolean } | undefined }>, reportInitiatives: Array<{ __typename: 'ReportInitiative', id: string, statusRequest?: { __typename: 'StatusRequest', id: string, isComplete: boolean } | undefined }>, reportActions: Array<{ __typename: 'ReportAction', id: string, statusRequest?: { __typename: 'StatusRequest', id: string, isComplete: boolean } | undefined }> }> } } };


export const InsightReportWizardControlsDocument = gql`
    query InsightReportWizardControls($reportId: ID!) {
  report(id: $reportId) {
    id
    reportStage
    reportSelection {
      reportObjectives {
        id
        statusRequest {
          id
          isComplete
        }
        reportMetrics {
          id
          statusRequest {
            id
            isComplete
          }
        }
        reportInitiatives {
          id
          statusRequest {
            id
            isComplete
          }
        }
        reportActions {
          id
          statusRequest {
            id
            isComplete
          }
        }
      }
    }
  }
}
    `;

/**
 * __useInsightReportWizardControlsQuery__
 *
 * To run a query within a React component, call `useInsightReportWizardControlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightReportWizardControlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightReportWizardControlsQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useInsightReportWizardControlsQuery(baseOptions: Apollo.QueryHookOptions<InsightReportWizardControlsQuery, InsightReportWizardControlsQueryVariables> & ({ variables: InsightReportWizardControlsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsightReportWizardControlsQuery, InsightReportWizardControlsQueryVariables>(InsightReportWizardControlsDocument, options);
      }
export function useInsightReportWizardControlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightReportWizardControlsQuery, InsightReportWizardControlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsightReportWizardControlsQuery, InsightReportWizardControlsQueryVariables>(InsightReportWizardControlsDocument, options);
        }
export function useInsightReportWizardControlsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InsightReportWizardControlsQuery, InsightReportWizardControlsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InsightReportWizardControlsQuery, InsightReportWizardControlsQueryVariables>(InsightReportWizardControlsDocument, options);
        }
export type InsightReportWizardControlsQueryHookResult = ReturnType<typeof useInsightReportWizardControlsQuery>;
export type InsightReportWizardControlsLazyQueryHookResult = ReturnType<typeof useInsightReportWizardControlsLazyQuery>;
export type InsightReportWizardControlsSuspenseQueryHookResult = ReturnType<typeof useInsightReportWizardControlsSuspenseQuery>;
export type InsightReportWizardControlsQueryResult = Apollo.QueryResult<InsightReportWizardControlsQuery, InsightReportWizardControlsQueryVariables>;