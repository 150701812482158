import { css } from '@emotion/react';
import { Field } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useActiveOrg } from 'org/ActiveOrgProvider';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import Button from 'shared/components/Button';
import Flex from 'shared/components/Flex';
import { RichTextField } from 'shared/components/RichTextInput';
import Form from 'shared/form/Form';
import type {
  StrategyAssessmentInput,
  StrategyAssessmentType,
} from 'types.graphql.generated';
import { useUser } from 'user/UserProvider';
import OpenTextInputField from 'shared/components/OpenTextInput/OpenTextInput.field';

export type AssessmentFormValues = {
  content: string;
  id?: string;
  title: string;
  type: StrategyAssessmentType;
};

type Props = {
  initialValues: AssessmentFormValues;
  isSubmitDisabled: boolean;
  onSubmit: (input: StrategyAssessmentInput) => void;
  overlayElement: HTMLDivElement | null;
};

const AssessmentForm = ({
  initialValues,
  onSubmit,
  isSubmitDisabled,
  overlayElement,
}: Props) => {
  const { t } = useTranslation();

  const { user } = useUser();
  const { activeOrg } = useActiveOrg();
  const { orgUnit } = useOrgUnit();

  const handleSubmit = useCallback(
    (values: AssessmentFormValues) => {
      onSubmit({
        orgKey: activeOrg.orgKey,
        orgUnit: { idToSet: orgUnit?.id },
        owner: { idToSet: user.id },
        idToUpdate: values.id,
        type: values.type,
        content: values.content,
        title: values.title,
      });
    },
    [activeOrg.orgKey, onSubmit, orgUnit?.id, user.id],
  );

  return (
    <Form<AssessmentFormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      css={css`
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 1rem;
        height: 100%;
      `}
    >
      <Field
        component={OpenTextInputField}
        name={'title'}
        placeholder={t('assessment.fields.title.placeholder')}
        fontSize={'big'}
        maxLength={100}
        autoFocus={true}
      />
      <Field
        name={'content'}
        component={RichTextField}
        popupContainerParent={overlayElement}
      />

      <Flex justifyContent={'flex-end'} css={css({ paddingBottom: 20 })}>
        <Button type={'submit'} isDisabled={isSubmitDisabled}>
          {t('publish')}
        </Button>
      </Flex>
    </Form>
  );
};

export default AssessmentForm;
