import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MetricStatusWizardScoreQueryVariables = Types.Exact<{
  metricId: Types.Scalars['ID']['input'];
}>;


export type MetricStatusWizardScoreQuery = { __typename: 'Query', metric: { __typename: 'Metric', id: string, currentMetricStatus?: { __typename: 'MetricStatus', id: string, statusValue?: number | undefined, statusDateTime: Date } | undefined } };


export const MetricStatusWizardScoreDocument = gql`
    query MetricStatusWizardScore($metricId: ID!) {
  metric(id: $metricId) {
    id
    currentMetricStatus {
      id
      statusValue
      statusDateTime
    }
  }
}
    `;

/**
 * __useMetricStatusWizardScoreQuery__
 *
 * To run a query within a React component, call `useMetricStatusWizardScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetricStatusWizardScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetricStatusWizardScoreQuery({
 *   variables: {
 *      metricId: // value for 'metricId'
 *   },
 * });
 */
export function useMetricStatusWizardScoreQuery(baseOptions: Apollo.QueryHookOptions<MetricStatusWizardScoreQuery, MetricStatusWizardScoreQueryVariables> & ({ variables: MetricStatusWizardScoreQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MetricStatusWizardScoreQuery, MetricStatusWizardScoreQueryVariables>(MetricStatusWizardScoreDocument, options);
      }
export function useMetricStatusWizardScoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MetricStatusWizardScoreQuery, MetricStatusWizardScoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MetricStatusWizardScoreQuery, MetricStatusWizardScoreQueryVariables>(MetricStatusWizardScoreDocument, options);
        }
export function useMetricStatusWizardScoreSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MetricStatusWizardScoreQuery, MetricStatusWizardScoreQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MetricStatusWizardScoreQuery, MetricStatusWizardScoreQueryVariables>(MetricStatusWizardScoreDocument, options);
        }
export type MetricStatusWizardScoreQueryHookResult = ReturnType<typeof useMetricStatusWizardScoreQuery>;
export type MetricStatusWizardScoreLazyQueryHookResult = ReturnType<typeof useMetricStatusWizardScoreLazyQuery>;
export type MetricStatusWizardScoreSuspenseQueryHookResult = ReturnType<typeof useMetricStatusWizardScoreSuspenseQuery>;
export type MetricStatusWizardScoreQueryResult = Apollo.QueryResult<MetricStatusWizardScoreQuery, MetricStatusWizardScoreQueryVariables>;