import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StrategyInsightsReportsForOrgQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type StrategyInsightsReportsForOrgQuery = { __typename: 'Query', activeOrg: { __typename: 'Org', id: string, reports: { __typename: 'ReportConnection', edges: Array<{ __typename: 'ReportEdge', node: { __typename: 'Report', id: string, name?: string | undefined, publishedTime?: Date | undefined, reportStage?: Types.ReportStage | undefined, auditRecord: { __typename: 'AuditRecord', createDateTime?: Date | undefined }, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined } | undefined } }> } } };

export type StrategyInsightsReportsForOrgUnitQueryVariables = Types.Exact<{
  orgUnitId: Types.Scalars['ID']['input'];
}>;


export type StrategyInsightsReportsForOrgUnitQuery = { __typename: 'Query', orgUnit: { __typename: 'OrgUnit', id: string, reports: { __typename: 'ReportConnection', edges: Array<{ __typename: 'ReportEdge', node: { __typename: 'Report', id: string, name?: string | undefined, publishedTime?: Date | undefined, reportStage?: Types.ReportStage | undefined, auditRecord: { __typename: 'AuditRecord', createDateTime?: Date | undefined }, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined } | undefined } }> } } };

export type StrategyInsightsReportsOrgUnitFragment = { __typename: 'OrgUnit', id: string, reports: { __typename: 'ReportConnection', edges: Array<{ __typename: 'ReportEdge', node: { __typename: 'Report', id: string, name?: string | undefined, publishedTime?: Date | undefined, reportStage?: Types.ReportStage | undefined, auditRecord: { __typename: 'AuditRecord', createDateTime?: Date | undefined }, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined } | undefined } }> } };

export type StrategyInsightsReportsOrgFragment = { __typename: 'Org', id: string, reports: { __typename: 'ReportConnection', edges: Array<{ __typename: 'ReportEdge', node: { __typename: 'Report', id: string, name?: string | undefined, publishedTime?: Date | undefined, reportStage?: Types.ReportStage | undefined, auditRecord: { __typename: 'AuditRecord', createDateTime?: Date | undefined }, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined } | undefined } }> } };

export type StrategyInsightsReportsReportFragment = { __typename: 'Report', id: string, name?: string | undefined, publishedTime?: Date | undefined, reportStage?: Types.ReportStage | undefined, auditRecord: { __typename: 'AuditRecord', createDateTime?: Date | undefined }, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined } | undefined };

export const StrategyInsightsReportsReportFragmentDoc = gql`
    fragment strategyInsightsReportsReport on Report {
  id
  name
  publishedTime
  reportStage
  auditRecord {
    createDateTime
  }
  owner {
    id
    email
    displayName
  }
}
    `;
export const StrategyInsightsReportsOrgUnitFragmentDoc = gql`
    fragment strategyInsightsReportsOrgUnit on OrgUnit {
  id
  reports {
    edges {
      node {
        ...strategyInsightsReportsReport
      }
    }
  }
}
    ${StrategyInsightsReportsReportFragmentDoc}`;
export const StrategyInsightsReportsOrgFragmentDoc = gql`
    fragment strategyInsightsReportsOrg on Org {
  id
  reports {
    edges {
      node {
        ...strategyInsightsReportsReport
      }
    }
  }
}
    ${StrategyInsightsReportsReportFragmentDoc}`;
export const StrategyInsightsReportsForOrgDocument = gql`
    query StrategyInsightsReportsForOrg {
  activeOrg {
    ...strategyInsightsReportsOrg
  }
}
    ${StrategyInsightsReportsOrgFragmentDoc}`;

/**
 * __useStrategyInsightsReportsForOrgQuery__
 *
 * To run a query within a React component, call `useStrategyInsightsReportsForOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useStrategyInsightsReportsForOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStrategyInsightsReportsForOrgQuery({
 *   variables: {
 *   },
 * });
 */
export function useStrategyInsightsReportsForOrgQuery(baseOptions?: Apollo.QueryHookOptions<StrategyInsightsReportsForOrgQuery, StrategyInsightsReportsForOrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StrategyInsightsReportsForOrgQuery, StrategyInsightsReportsForOrgQueryVariables>(StrategyInsightsReportsForOrgDocument, options);
      }
export function useStrategyInsightsReportsForOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StrategyInsightsReportsForOrgQuery, StrategyInsightsReportsForOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StrategyInsightsReportsForOrgQuery, StrategyInsightsReportsForOrgQueryVariables>(StrategyInsightsReportsForOrgDocument, options);
        }
export function useStrategyInsightsReportsForOrgSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<StrategyInsightsReportsForOrgQuery, StrategyInsightsReportsForOrgQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StrategyInsightsReportsForOrgQuery, StrategyInsightsReportsForOrgQueryVariables>(StrategyInsightsReportsForOrgDocument, options);
        }
export type StrategyInsightsReportsForOrgQueryHookResult = ReturnType<typeof useStrategyInsightsReportsForOrgQuery>;
export type StrategyInsightsReportsForOrgLazyQueryHookResult = ReturnType<typeof useStrategyInsightsReportsForOrgLazyQuery>;
export type StrategyInsightsReportsForOrgSuspenseQueryHookResult = ReturnType<typeof useStrategyInsightsReportsForOrgSuspenseQuery>;
export type StrategyInsightsReportsForOrgQueryResult = Apollo.QueryResult<StrategyInsightsReportsForOrgQuery, StrategyInsightsReportsForOrgQueryVariables>;
export const StrategyInsightsReportsForOrgUnitDocument = gql`
    query StrategyInsightsReportsForOrgUnit($orgUnitId: ID!) {
  orgUnit(id: $orgUnitId) {
    ...strategyInsightsReportsOrgUnit
  }
}
    ${StrategyInsightsReportsOrgUnitFragmentDoc}`;

/**
 * __useStrategyInsightsReportsForOrgUnitQuery__
 *
 * To run a query within a React component, call `useStrategyInsightsReportsForOrgUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useStrategyInsightsReportsForOrgUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStrategyInsightsReportsForOrgUnitQuery({
 *   variables: {
 *      orgUnitId: // value for 'orgUnitId'
 *   },
 * });
 */
export function useStrategyInsightsReportsForOrgUnitQuery(baseOptions: Apollo.QueryHookOptions<StrategyInsightsReportsForOrgUnitQuery, StrategyInsightsReportsForOrgUnitQueryVariables> & ({ variables: StrategyInsightsReportsForOrgUnitQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StrategyInsightsReportsForOrgUnitQuery, StrategyInsightsReportsForOrgUnitQueryVariables>(StrategyInsightsReportsForOrgUnitDocument, options);
      }
export function useStrategyInsightsReportsForOrgUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StrategyInsightsReportsForOrgUnitQuery, StrategyInsightsReportsForOrgUnitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StrategyInsightsReportsForOrgUnitQuery, StrategyInsightsReportsForOrgUnitQueryVariables>(StrategyInsightsReportsForOrgUnitDocument, options);
        }
export function useStrategyInsightsReportsForOrgUnitSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<StrategyInsightsReportsForOrgUnitQuery, StrategyInsightsReportsForOrgUnitQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StrategyInsightsReportsForOrgUnitQuery, StrategyInsightsReportsForOrgUnitQueryVariables>(StrategyInsightsReportsForOrgUnitDocument, options);
        }
export type StrategyInsightsReportsForOrgUnitQueryHookResult = ReturnType<typeof useStrategyInsightsReportsForOrgUnitQuery>;
export type StrategyInsightsReportsForOrgUnitLazyQueryHookResult = ReturnType<typeof useStrategyInsightsReportsForOrgUnitLazyQuery>;
export type StrategyInsightsReportsForOrgUnitSuspenseQueryHookResult = ReturnType<typeof useStrategyInsightsReportsForOrgUnitSuspenseQuery>;
export type StrategyInsightsReportsForOrgUnitQueryResult = Apollo.QueryResult<StrategyInsightsReportsForOrgUnitQuery, StrategyInsightsReportsForOrgUnitQueryVariables>;