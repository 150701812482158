import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { MetricsListObjectiveFragmentDoc } from '../../MetricsList/MetricsListProvider/MetricsListProvider.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ObjectiveMetricsQueryVariables = Types.Exact<{
  objectiveId: Types.Scalars['ID']['input'];
}>;


export type ObjectiveMetricsQuery = { __typename: 'Query', objective: { __typename: 'Objective', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, isCurrentUserOwner: boolean, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined, currentObjectiveStatus?: { __typename: 'ObjectiveStatus', id: string, complete: boolean } | undefined, timeLine: { __typename: 'TimeLine', startDate?: Date | undefined, endDate?: Date | undefined, completeDateTime?: Date | undefined }, metrics: Array<{ __typename: 'Metric', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, isCurrentUserOwner: boolean, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined, currentMetricStatus?: { __typename: 'MetricStatus', id: string, complete: boolean, statusIndicator: StatusIndicatorClass } | undefined, timeLine: { __typename: 'TimeLine', startDate?: Date | undefined, endDate?: Date | undefined, completeDateTime?: Date | undefined }, objective?: { __typename: 'Objective', id: string, name?: string | undefined, orgUnit?: { __typename: 'OrgUnit', currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined } | undefined } | undefined }> } };


export const ObjectiveMetricsDocument = gql`
    query ObjectiveMetrics($objectiveId: ID!) {
  objective(id: $objectiveId) {
    ...metricsListObjective
  }
}
    ${MetricsListObjectiveFragmentDoc}`;

/**
 * __useObjectiveMetricsQuery__
 *
 * To run a query within a React component, call `useObjectiveMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useObjectiveMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObjectiveMetricsQuery({
 *   variables: {
 *      objectiveId: // value for 'objectiveId'
 *   },
 * });
 */
export function useObjectiveMetricsQuery(baseOptions: Apollo.QueryHookOptions<ObjectiveMetricsQuery, ObjectiveMetricsQueryVariables> & ({ variables: ObjectiveMetricsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ObjectiveMetricsQuery, ObjectiveMetricsQueryVariables>(ObjectiveMetricsDocument, options);
      }
export function useObjectiveMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjectiveMetricsQuery, ObjectiveMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ObjectiveMetricsQuery, ObjectiveMetricsQueryVariables>(ObjectiveMetricsDocument, options);
        }
export function useObjectiveMetricsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ObjectiveMetricsQuery, ObjectiveMetricsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ObjectiveMetricsQuery, ObjectiveMetricsQueryVariables>(ObjectiveMetricsDocument, options);
        }
export type ObjectiveMetricsQueryHookResult = ReturnType<typeof useObjectiveMetricsQuery>;
export type ObjectiveMetricsLazyQueryHookResult = ReturnType<typeof useObjectiveMetricsLazyQuery>;
export type ObjectiveMetricsSuspenseQueryHookResult = ReturnType<typeof useObjectiveMetricsSuspenseQuery>;
export type ObjectiveMetricsQueryResult = Apollo.QueryResult<ObjectiveMetricsQuery, ObjectiveMetricsQueryVariables>;