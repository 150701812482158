import type { MDXEditorMethods } from '@mdxeditor/editor';
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  headingsPlugin,
  InsertTable,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
  MDXEditor,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
  UndoRedo,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import { useParentContainer } from 'shared/hooks/useParentContainer';

const Container = styled.div`
  display: flex;
  flex-grow: 1;

  .mdxeditor {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .mdxeditor-root-contenteditable {
      display: flex;
      flex-grow: 1;

      & > div {
        flex-grow: 1;
        display: flex;

        [contenteditable='true'] {
          cursor: text;
          flex-grow: 1;
        }
      }
    }
  }
`;

type Props = {
  onChange: (value: string) => void;
  popupContainerParent?: HTMLDivElement;
  value?: string;
};

const RichTextInput = ({
  value = '',
  onChange,
  popupContainerParent,
}: Props) => {
  const mdxEditorRef = useRef<MDXEditorMethods>(null);

  useEffect(() => {
    mdxEditorRef.current?.setMarkdown(value);
  }, [value]);

  useParentContainer('.mdxeditor-popup-container', popupContainerParent);

  return (
    <Container>
      <MDXEditor
        ref={mdxEditorRef}
        markdown={value}
        onChange={onChange}
        plugins={[
          toolbarPlugin({
            toolbarContents: () => (
              <>
                <UndoRedo />
                <BlockTypeSelect />
                <BoldItalicUnderlineToggles />
                <ListsToggle />
                <InsertTable />
              </>
            ),
          }),
          headingsPlugin(),
          listsPlugin(),
          tablePlugin(),
          thematicBreakPlugin(),
          markdownShortcutPlugin(),
        ]}
      />
    </Container>
  );
};

export default RichTextInput;
