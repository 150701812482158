import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { UserLimitedProfileFragmentDoc } from '../../user/userProfile.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveWalkOfStrategyCampaignMutationVariables = Types.Exact<{
  input: Types.CampaignInput;
}>;


export type SaveWalkOfStrategyCampaignMutation = { __typename: 'Mutation', saveCampaign: { __typename: 'Campaign', id: string, name: string, deadline?: Date | undefined, state: { __typename: 'CampaignState', stage: Types.CampaignStage }, timeLine: { __typename: 'TimeLine', startDate?: Date | undefined, endDate?: Date | undefined }, owner: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean }, strategy: { __typename: 'Strategy', id: string, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined } } };


export const SaveWalkOfStrategyCampaignDocument = gql`
    mutation SaveWalkOfStrategyCampaign($input: CampaignInput!) {
  saveCampaign(input: $input) {
    id
    name
    deadline
    state {
      stage
    }
    timeLine {
      startDate
      endDate
    }
    owner {
      ...userLimitedProfile
    }
    strategy {
      id
      orgUnit {
        id
        name
      }
    }
  }
}
    ${UserLimitedProfileFragmentDoc}`;
export type SaveWalkOfStrategyCampaignMutationFn = Apollo.MutationFunction<SaveWalkOfStrategyCampaignMutation, SaveWalkOfStrategyCampaignMutationVariables>;

/**
 * __useSaveWalkOfStrategyCampaignMutation__
 *
 * To run a mutation, you first call `useSaveWalkOfStrategyCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveWalkOfStrategyCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveWalkOfStrategyCampaignMutation, { data, loading, error }] = useSaveWalkOfStrategyCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveWalkOfStrategyCampaignMutation(baseOptions?: Apollo.MutationHookOptions<SaveWalkOfStrategyCampaignMutation, SaveWalkOfStrategyCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveWalkOfStrategyCampaignMutation, SaveWalkOfStrategyCampaignMutationVariables>(SaveWalkOfStrategyCampaignDocument, options);
      }
export type SaveWalkOfStrategyCampaignMutationHookResult = ReturnType<typeof useSaveWalkOfStrategyCampaignMutation>;
export type SaveWalkOfStrategyCampaignMutationResult = Apollo.MutationResult<SaveWalkOfStrategyCampaignMutation>;
export type SaveWalkOfStrategyCampaignMutationOptions = Apollo.BaseMutationOptions<SaveWalkOfStrategyCampaignMutation, SaveWalkOfStrategyCampaignMutationVariables>;