import { useEffect } from 'react';
import { addWeeks, differenceInDays, formatRFC3339 } from 'date-fns';
import type { TFunction } from 'react-i18next';

import { useForm } from 'shared/form/Form';
import { date } from 'shared/services/date.service';
import type { Report, ReportInput } from 'types.graphql.generated';
import useHandleError from 'shared/errors/useHandleError';

import type {
  CutoffIntervalUnitFieldOption,
  RequestStatusUpdateFormValues,
} from './RequestStatusUpdateForm.type';
import type { RequestStatusUpdateFormReportQuery } from './RequestStatusUpdateForm.graphql.generated';
import {
  RequestStatusUpdateFormReportDocument,
  useUpdateReportWithStatusRequestDatesMutation,
} from './RequestStatusUpdateForm.graphql.generated';

export const getCutoffIntervalUnitFieldOptions = (
  t: TFunction,
): CutoffIntervalUnitFieldOption[] => [
  {
    id: 'day',
    title: t('day_other'),
  },
  {
    id: 'month',
    title: t('month_other'),
  },
  {
    id: 'week',
    title: t('week_other'),
  },
];

export const createInitialValues = (
  t: TFunction,
): RequestStatusUpdateFormValues => ({
  cutoffInterval: {
    amount: 1,
    unit: getCutoffIntervalUnitFieldOptions(t).find(
      (option) => option.id === 'month',
    )!,
  },
  deadlineDate: addWeeks(new Date(), 1),
  owner: '',
});

export const resolveInitialValues = (
  t: TFunction,
  report: RequestStatusUpdateFormReportQuery['report'],
): RequestStatusUpdateFormValues => ({
  deadlineDate: report.statusDeadlineTime
    ? new Date(report.statusDeadlineTime)
    : createInitialValues(t).deadlineDate,
  cutoffInterval: report.statusCutoffTime
    ? {
        amount: differenceInDays(new Date(), report.statusCutoffTime),
        unit: getCutoffIntervalUnitFieldOptions(t).find(
          (option) => option.id === 'day',
        )!,
      }
    : createInitialValues(t).cutoffInterval,
  owner: report.owner?.email || '',
});

const getDateFromCutoffInterval = (
  cutoffInterval: RequestStatusUpdateFormValues['cutoffInterval'],
): Date => {
  const unit = cutoffInterval.unit.id;
  const amount = cutoffInterval.amount;

  switch (unit) {
    case 'month': {
      return date.subtract(new Date(), { months: amount });
    }
    case 'week': {
      return date.subtract(new Date(), { weeks: amount });
    }
    case 'day': {
      return date.subtract(new Date(), { days: amount });
    }
  }
};

const resolveStatusRequestDates = (params: {
  cutoffInterval: RequestStatusUpdateFormValues['cutoffInterval'];
  deadlineDate: Date;
}): Pick<ReportInput, 'statusCutoffTime' | 'statusDeadlineTime'> => {
  const { deadlineDate, cutoffInterval } = params;

  return {
    statusDeadlineTime: date.formatRfc3339(deadlineDate),
    statusCutoffTime: formatRFC3339(getDateFromCutoffInterval(cutoffInterval)),
  };
};

type UpdateReportWithStatusRequestDatesProps = {
  report: Pick<Report, 'id'>;
};

export const UpdateReportWithStatusRequestDates = ({
  report,
}: UpdateReportWithStatusRequestDatesProps) => {
  const {
    values: {
      deadlineDate,
      cutoffInterval: { amount, unit },
    },
  } = useForm<RequestStatusUpdateFormValues>();

  const handleApolloError = useHandleError();

  const [updateReport] = useUpdateReportWithStatusRequestDatesMutation({
    onError: handleApolloError,
    refetchQueries: [RequestStatusUpdateFormReportDocument],
  });

  useEffect(() => {
    if (deadlineDate && amount && unit) {
      const requestDates = resolveStatusRequestDates({
        deadlineDate,
        cutoffInterval: {
          amount,
          unit,
        },
      });
      updateReport({
        variables: {
          input: {
            idToUpdate: report.id,
            ...requestDates,
          },
        },
      });
    }
  }, [deadlineDate, amount, unit, report.id, updateReport]);

  return null;
};
