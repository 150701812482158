import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { UserLimitedProfileFragmentDoc } from '../../user/userProfile.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OneThingCampaignResultsQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['ID']['input'];
}>;


export type OneThingCampaignResultsQuery = { __typename: 'Query', oneThingCampaign: { __typename: 'OneThingCampaign', id: string, name: string, deadline?: Date | undefined, state: { __typename: 'CampaignState', stage: Types.CampaignStage }, timeLine: { __typename: 'TimeLine', startDate?: Date | undefined, endDate?: Date | undefined }, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined, completionRate: { __typename: 'CampaignCompletionRate', percentEngaged: number, countEngaged: number, countDone: number, percentDone: number }, tasks: Array<{ __typename: 'OneThingTask', id: string, status: Types.ProgressStatus, task: string, completionRate: number, objective: { __typename: 'Objective', id: string, name?: string | undefined, currentObjectiveStatus?: { __typename: 'ObjectiveStatus', id: string, statusIndicator: StatusIndicatorClass } | undefined }, owner: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean }, timeLine: { __typename: 'TimeLine', startDate?: Date | undefined, completeDateTime?: Date | undefined } }> } };

export type OneThingCampaignResultsCampaignFragment = { __typename: 'OneThingCampaign', id: string, name: string, deadline?: Date | undefined, state: { __typename: 'CampaignState', stage: Types.CampaignStage }, timeLine: { __typename: 'TimeLine', startDate?: Date | undefined, endDate?: Date | undefined }, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined, completionRate: { __typename: 'CampaignCompletionRate', percentEngaged: number, countEngaged: number, countDone: number, percentDone: number }, tasks: Array<{ __typename: 'OneThingTask', id: string, status: Types.ProgressStatus, task: string, completionRate: number, objective: { __typename: 'Objective', id: string, name?: string | undefined, currentObjectiveStatus?: { __typename: 'ObjectiveStatus', id: string, statusIndicator: StatusIndicatorClass } | undefined }, owner: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean }, timeLine: { __typename: 'TimeLine', startDate?: Date | undefined, completeDateTime?: Date | undefined } }> };

export type OneThingCampaignResultsTaskFragment = { __typename: 'OneThingTask', id: string, status: Types.ProgressStatus, task: string, completionRate: number, objective: { __typename: 'Objective', id: string, name?: string | undefined, currentObjectiveStatus?: { __typename: 'ObjectiveStatus', id: string, statusIndicator: StatusIndicatorClass } | undefined }, owner: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean }, timeLine: { __typename: 'TimeLine', startDate?: Date | undefined, completeDateTime?: Date | undefined } };

export type OneThingCampaignResultsObjectiveFragment = { __typename: 'Objective', id: string, name?: string | undefined, currentObjectiveStatus?: { __typename: 'ObjectiveStatus', id: string, statusIndicator: StatusIndicatorClass } | undefined };

export const OneThingCampaignResultsObjectiveFragmentDoc = gql`
    fragment oneThingCampaignResultsObjective on Objective {
  id
  name
  currentObjectiveStatus {
    id
    statusIndicator
  }
}
    `;
export const OneThingCampaignResultsTaskFragmentDoc = gql`
    fragment oneThingCampaignResultsTask on OneThingTask {
  id
  status
  task
  completionRate
  objective {
    ...oneThingCampaignResultsObjective
  }
  owner {
    ...userLimitedProfile
  }
  timeLine {
    startDate
    completeDateTime
  }
}
    ${OneThingCampaignResultsObjectiveFragmentDoc}
${UserLimitedProfileFragmentDoc}`;
export const OneThingCampaignResultsCampaignFragmentDoc = gql`
    fragment oneThingCampaignResultsCampaign on OneThingCampaign {
  id
  name
  deadline
  state {
    stage
  }
  timeLine {
    startDate
    endDate
  }
  orgUnit {
    id
    name
  }
  completionRate {
    percentEngaged
    countEngaged
    countDone
    percentDone
  }
  tasks {
    ...oneThingCampaignResultsTask
  }
}
    ${OneThingCampaignResultsTaskFragmentDoc}`;
export const OneThingCampaignResultsDocument = gql`
    query OneThingCampaignResults($campaignId: ID!) {
  oneThingCampaign(id: $campaignId) {
    ...oneThingCampaignResultsCampaign
  }
}
    ${OneThingCampaignResultsCampaignFragmentDoc}`;

/**
 * __useOneThingCampaignResultsQuery__
 *
 * To run a query within a React component, call `useOneThingCampaignResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOneThingCampaignResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOneThingCampaignResultsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useOneThingCampaignResultsQuery(baseOptions: Apollo.QueryHookOptions<OneThingCampaignResultsQuery, OneThingCampaignResultsQueryVariables> & ({ variables: OneThingCampaignResultsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OneThingCampaignResultsQuery, OneThingCampaignResultsQueryVariables>(OneThingCampaignResultsDocument, options);
      }
export function useOneThingCampaignResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OneThingCampaignResultsQuery, OneThingCampaignResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OneThingCampaignResultsQuery, OneThingCampaignResultsQueryVariables>(OneThingCampaignResultsDocument, options);
        }
export function useOneThingCampaignResultsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OneThingCampaignResultsQuery, OneThingCampaignResultsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OneThingCampaignResultsQuery, OneThingCampaignResultsQueryVariables>(OneThingCampaignResultsDocument, options);
        }
export type OneThingCampaignResultsQueryHookResult = ReturnType<typeof useOneThingCampaignResultsQuery>;
export type OneThingCampaignResultsLazyQueryHookResult = ReturnType<typeof useOneThingCampaignResultsLazyQuery>;
export type OneThingCampaignResultsSuspenseQueryHookResult = ReturnType<typeof useOneThingCampaignResultsSuspenseQuery>;
export type OneThingCampaignResultsQueryResult = Apollo.QueryResult<OneThingCampaignResultsQuery, OneThingCampaignResultsQueryVariables>;