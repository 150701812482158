import type * as Types from '../../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateOneThingTaskObjectivesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ObjectiveFilterInput>;
}>;


export type CreateOneThingTaskObjectivesQuery = { __typename: 'Query', allObjectives: { __typename: 'ObjectiveConnection', edges: Array<{ __typename: 'ObjectiveEdge', node: { __typename: 'Objective', id: string, name?: string | undefined, theme?: { __typename: 'Theme', id: string, name?: string | undefined, orderNumber?: number | undefined } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined, currentObjectiveStatus?: { __typename: 'ObjectiveStatus', id: string, statusIndicator: StatusIndicatorClass } | undefined } }> } };

export type CreateOneThingTaskObjectiveFragment = { __typename: 'Objective', id: string, name?: string | undefined, theme?: { __typename: 'Theme', id: string, name?: string | undefined, orderNumber?: number | undefined } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined, currentObjectiveStatus?: { __typename: 'ObjectiveStatus', id: string, statusIndicator: StatusIndicatorClass } | undefined };

export const CreateOneThingTaskObjectiveFragmentDoc = gql`
    fragment createOneThingTaskObjective on Objective {
  id
  name
  theme {
    id
    name
    orderNumber
  }
  orgUnit {
    id
    name
  }
  currentObjectiveStatus {
    id
    statusIndicator
  }
}
    `;
export const CreateOneThingTaskObjectivesDocument = gql`
    query CreateOneThingTaskObjectives($filter: ObjectiveFilterInput) {
  allObjectives(filter: $filter) {
    edges {
      node {
        ...createOneThingTaskObjective
      }
    }
  }
}
    ${CreateOneThingTaskObjectiveFragmentDoc}`;

/**
 * __useCreateOneThingTaskObjectivesQuery__
 *
 * To run a query within a React component, call `useCreateOneThingTaskObjectivesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateOneThingTaskObjectivesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateOneThingTaskObjectivesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCreateOneThingTaskObjectivesQuery(baseOptions?: Apollo.QueryHookOptions<CreateOneThingTaskObjectivesQuery, CreateOneThingTaskObjectivesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateOneThingTaskObjectivesQuery, CreateOneThingTaskObjectivesQueryVariables>(CreateOneThingTaskObjectivesDocument, options);
      }
export function useCreateOneThingTaskObjectivesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateOneThingTaskObjectivesQuery, CreateOneThingTaskObjectivesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateOneThingTaskObjectivesQuery, CreateOneThingTaskObjectivesQueryVariables>(CreateOneThingTaskObjectivesDocument, options);
        }
export function useCreateOneThingTaskObjectivesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CreateOneThingTaskObjectivesQuery, CreateOneThingTaskObjectivesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CreateOneThingTaskObjectivesQuery, CreateOneThingTaskObjectivesQueryVariables>(CreateOneThingTaskObjectivesDocument, options);
        }
export type CreateOneThingTaskObjectivesQueryHookResult = ReturnType<typeof useCreateOneThingTaskObjectivesQuery>;
export type CreateOneThingTaskObjectivesLazyQueryHookResult = ReturnType<typeof useCreateOneThingTaskObjectivesLazyQuery>;
export type CreateOneThingTaskObjectivesSuspenseQueryHookResult = ReturnType<typeof useCreateOneThingTaskObjectivesSuspenseQuery>;
export type CreateOneThingTaskObjectivesQueryResult = Apollo.QueryResult<CreateOneThingTaskObjectivesQuery, CreateOneThingTaskObjectivesQueryVariables>;