import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditInitiativeQueryVariables = Types.Exact<{
  initiativeId: Types.Scalars['ID']['input'];
}>;


export type EditInitiativeQuery = { __typename: 'Query', initiative: { __typename: 'Initiative', id: string, name?: string | undefined, description?: string | undefined, isCurrentUserOwner: boolean, milestones: Array<{ __typename: 'Milestone', id: string, name?: string | undefined, comment?: string | undefined, timeLine?: { __typename: 'TimeLine', endDate?: Date | undefined } | undefined }>, owner?: { __typename: 'UserLimited', id: string, email: string } | undefined, timeLine: { __typename: 'TimeLine', endDate?: Date | undefined, startDate?: Date | undefined }, objective?: { __typename: 'Objective', id: string, name?: string | undefined } | undefined, priority?: { __typename: 'Priority', number: number, label: string } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined, attributes: Array<{ __typename: 'StrategyItemAttribute', id: string, name?: string | undefined, title?: string | undefined, description?: string | undefined, orderNumber?: number | undefined }> } };

export type UpdateInitiativeMutationVariables = Types.Exact<{
  input: Types.InitiativeInput;
}>;


export type UpdateInitiativeMutation = { __typename: 'Mutation', updateInitiative: { __typename: 'Initiative', id: string, name?: string | undefined, description?: string | undefined, isCurrentUserOwner: boolean, milestones: Array<{ __typename: 'Milestone', id: string, name?: string | undefined, comment?: string | undefined, timeLine?: { __typename: 'TimeLine', endDate?: Date | undefined } | undefined }>, owner?: { __typename: 'UserLimited', id: string, email: string } | undefined, timeLine: { __typename: 'TimeLine', endDate?: Date | undefined, startDate?: Date | undefined }, objective?: { __typename: 'Objective', id: string, name?: string | undefined } | undefined, priority?: { __typename: 'Priority', number: number, label: string } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined, attributes: Array<{ __typename: 'StrategyItemAttribute', id: string, name?: string | undefined, title?: string | undefined, description?: string | undefined, orderNumber?: number | undefined }> } };

export type InitiativeForEditFragment = { __typename: 'Initiative', id: string, name?: string | undefined, description?: string | undefined, isCurrentUserOwner: boolean, milestones: Array<{ __typename: 'Milestone', id: string, name?: string | undefined, comment?: string | undefined, timeLine?: { __typename: 'TimeLine', endDate?: Date | undefined } | undefined }>, owner?: { __typename: 'UserLimited', id: string, email: string } | undefined, timeLine: { __typename: 'TimeLine', endDate?: Date | undefined, startDate?: Date | undefined }, objective?: { __typename: 'Objective', id: string, name?: string | undefined } | undefined, priority?: { __typename: 'Priority', number: number, label: string } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined, attributes: Array<{ __typename: 'StrategyItemAttribute', id: string, name?: string | undefined, title?: string | undefined, description?: string | undefined, orderNumber?: number | undefined }> };

export const InitiativeForEditFragmentDoc = gql`
    fragment initiativeForEdit on Initiative {
  id
  name
  description
  isCurrentUserOwner
  milestones {
    id
    name
    comment
    timeLine {
      endDate
    }
  }
  owner {
    id
    email
  }
  timeLine {
    endDate
    startDate
  }
  objective {
    id
    name
  }
  priority {
    number
    label
  }
  orgUnit {
    id
    name
  }
  attributes {
    id
    name
    title
    description
    orderNumber
  }
}
    `;
export const EditInitiativeDocument = gql`
    query EditInitiative($initiativeId: ID!) {
  initiative(id: $initiativeId) {
    ...initiativeForEdit
  }
}
    ${InitiativeForEditFragmentDoc}`;

/**
 * __useEditInitiativeQuery__
 *
 * To run a query within a React component, call `useEditInitiativeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditInitiativeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditInitiativeQuery({
 *   variables: {
 *      initiativeId: // value for 'initiativeId'
 *   },
 * });
 */
export function useEditInitiativeQuery(baseOptions: Apollo.QueryHookOptions<EditInitiativeQuery, EditInitiativeQueryVariables> & ({ variables: EditInitiativeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditInitiativeQuery, EditInitiativeQueryVariables>(EditInitiativeDocument, options);
      }
export function useEditInitiativeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditInitiativeQuery, EditInitiativeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditInitiativeQuery, EditInitiativeQueryVariables>(EditInitiativeDocument, options);
        }
export function useEditInitiativeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EditInitiativeQuery, EditInitiativeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EditInitiativeQuery, EditInitiativeQueryVariables>(EditInitiativeDocument, options);
        }
export type EditInitiativeQueryHookResult = ReturnType<typeof useEditInitiativeQuery>;
export type EditInitiativeLazyQueryHookResult = ReturnType<typeof useEditInitiativeLazyQuery>;
export type EditInitiativeSuspenseQueryHookResult = ReturnType<typeof useEditInitiativeSuspenseQuery>;
export type EditInitiativeQueryResult = Apollo.QueryResult<EditInitiativeQuery, EditInitiativeQueryVariables>;
export const UpdateInitiativeDocument = gql`
    mutation UpdateInitiative($input: InitiativeInput!) {
  updateInitiative(input: $input) {
    ...initiativeForEdit
  }
}
    ${InitiativeForEditFragmentDoc}`;
export type UpdateInitiativeMutationFn = Apollo.MutationFunction<UpdateInitiativeMutation, UpdateInitiativeMutationVariables>;

/**
 * __useUpdateInitiativeMutation__
 *
 * To run a mutation, you first call `useUpdateInitiativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInitiativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInitiativeMutation, { data, loading, error }] = useUpdateInitiativeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInitiativeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInitiativeMutation, UpdateInitiativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInitiativeMutation, UpdateInitiativeMutationVariables>(UpdateInitiativeDocument, options);
      }
export type UpdateInitiativeMutationHookResult = ReturnType<typeof useUpdateInitiativeMutation>;
export type UpdateInitiativeMutationResult = Apollo.MutationResult<UpdateInitiativeMutation>;
export type UpdateInitiativeMutationOptions = Apollo.BaseMutationOptions<UpdateInitiativeMutation, UpdateInitiativeMutationVariables>;