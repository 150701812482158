import { useEffect } from 'react';

export function useParentContainer(
  childElementSelector: string,
  parentElement?: HTMLDivElement,
): void {
  useEffect(() => {
    if (!parentElement || !childElementSelector) return;

    const child = document.querySelector(childElementSelector);

    if (child && child instanceof HTMLElement) {
      parentElement.appendChild(child);
    }
  }, [parentElement, childElementSelector]);
}
