import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmailLoginInstructionsMutationVariables = Types.Exact<{
  userId: Types.Scalars['ID']['input'];
}>;


export type EmailLoginInstructionsMutation = { __typename: 'Mutation', updateUser: { __typename: 'User', id: string } };


export const EmailLoginInstructionsDocument = gql`
    mutation EmailLoginInstructions($userId: ID!) {
  updateUser(input: {idToUpdate: $userId, doEmailUserInvite: true}) {
    id
  }
}
    `;
export type EmailLoginInstructionsMutationFn = Apollo.MutationFunction<EmailLoginInstructionsMutation, EmailLoginInstructionsMutationVariables>;

/**
 * __useEmailLoginInstructionsMutation__
 *
 * To run a mutation, you first call `useEmailLoginInstructionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailLoginInstructionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailLoginInstructionsMutation, { data, loading, error }] = useEmailLoginInstructionsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEmailLoginInstructionsMutation(baseOptions?: Apollo.MutationHookOptions<EmailLoginInstructionsMutation, EmailLoginInstructionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmailLoginInstructionsMutation, EmailLoginInstructionsMutationVariables>(EmailLoginInstructionsDocument, options);
      }
export type EmailLoginInstructionsMutationHookResult = ReturnType<typeof useEmailLoginInstructionsMutation>;
export type EmailLoginInstructionsMutationResult = Apollo.MutationResult<EmailLoginInstructionsMutation>;
export type EmailLoginInstructionsMutationOptions = Apollo.BaseMutationOptions<EmailLoginInstructionsMutation, EmailLoginInstructionsMutationVariables>;