import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveCampaignUserResponseMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.CampaignUserResponseInput>;
}>;


export type SaveCampaignUserResponseMutation = { __typename: 'Mutation', saveCampaignUserResponse: { __typename: 'CampaignUserResponse', id: string, status: Types.ProgressStatus, comments: Array<{ __typename: 'CampaignComment', id: string, text: string, auditRecord?: { __typename: 'AuditRecord', createDateTime?: Date | undefined } | undefined }>, answers: Array<{ __typename: 'CampaignAnswer', id: string, rating: Types.LikertScaleRating, question: { __typename: 'CampaignQuestion', id: string, questionType: Types.CampaignQuestionType } }>, campaign: { __typename: 'Campaign', id: string, currentUserResponse?: { __typename: 'CampaignUserResponse', id: string } | undefined } } };


export const SaveCampaignUserResponseDocument = gql`
    mutation SaveCampaignUserResponse($input: CampaignUserResponseInput) {
  saveCampaignUserResponse(input: $input) {
    id
    status
    comments {
      id
      text
      auditRecord {
        createDateTime
      }
    }
    answers {
      id
      rating
      question {
        id
        questionType
      }
    }
    campaign {
      id
      currentUserResponse {
        id
      }
    }
  }
}
    `;
export type SaveCampaignUserResponseMutationFn = Apollo.MutationFunction<SaveCampaignUserResponseMutation, SaveCampaignUserResponseMutationVariables>;

/**
 * __useSaveCampaignUserResponseMutation__
 *
 * To run a mutation, you first call `useSaveCampaignUserResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCampaignUserResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCampaignUserResponseMutation, { data, loading, error }] = useSaveCampaignUserResponseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCampaignUserResponseMutation(baseOptions?: Apollo.MutationHookOptions<SaveCampaignUserResponseMutation, SaveCampaignUserResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveCampaignUserResponseMutation, SaveCampaignUserResponseMutationVariables>(SaveCampaignUserResponseDocument, options);
      }
export type SaveCampaignUserResponseMutationHookResult = ReturnType<typeof useSaveCampaignUserResponseMutation>;
export type SaveCampaignUserResponseMutationResult = Apollo.MutationResult<SaveCampaignUserResponseMutation>;
export type SaveCampaignUserResponseMutationOptions = Apollo.BaseMutationOptions<SaveCampaignUserResponseMutation, SaveCampaignUserResponseMutationVariables>;