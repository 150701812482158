import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import AiSophieIcon from 'ai/AiSophieIcon';
import Text from 'shared/components/Text';

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin: 1rem;
  margin-top: 5rem;
  gap: 12px;
`;

type Props = {
  infoText: string;
};

const Start = ({ infoText }: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <AiSophieIcon fontSize={'8rem'} />
      <Text size={'large'} isCentered={true} color={'black'}>
        {t('ai.strategySummary.start.header')}
      </Text>
      <Text size={'medium'} isCentered={true}>
        {infoText}
      </Text>
    </Container>
  );
};

export default Start;
