import { StatusIndicator } from 'shared/status/StatusIndicator';

import type {
  MetricOverviewMetricMetricStatus,
  MetricOverviewMetric,
} from './MetricOverview.type';

//we add creation date as a status to the list, so that it can be sorted by date within the list when we display it in a Activity Timeline component
export const getMetricStatusListWithMetricCreationDate = (
  metric: Maybe<MetricOverviewMetric>,
  metricStatusList: Maybe<MetricOverviewMetricMetricStatus[]>,
) =>
  metric && metricStatusList
    ? [
        ...metricStatusList,
        metric.auditRecord.createDateTime
          ? castMetricCreationDateToMetricStatus(
              metric,
              metric.auditRecord.createDateTime,
            )
          : undefined,
      ].filter(Boolean)
    : [];

const castMetricCreationDateToMetricStatus = (
  metric: Pick<MetricOverviewMetric, 'id' | 'auditRecord'>,
  statusDateTime: Date,
): MetricOverviewMetricMetricStatus => ({
  id: metric.id,
  context: 'metricCreated',
  complete: false,
  statusIndicator: new StatusIndicator(),
  statusDateTime,
  auditRecord: metric.auditRecord,
  actionComments: [],
  challengeComments: [],
  successComments: [],
});
