import { StatusIndicator } from 'shared/status/StatusIndicator';

import type {
  InitiativeOverviewInitiativeStatus,
  InitiativeOverviewInitiative,
} from './InitiativeOverview.type';

//we add creation date as a status to the list, so that it can be sorted by date within the list when we display it in a Activity Timeline component
export const getInitiativeStatusListWithInitiativeCreationDate = (
  initiative: Maybe<InitiativeOverviewInitiative>,
  initiativeStatusList: Maybe<InitiativeOverviewInitiativeStatus[]>,
) =>
  initiative && initiativeStatusList
    ? [
        ...initiativeStatusList,
        initiative.auditRecord.createDateTime
          ? castInitiativeCreationDateToInitiativeStatus(
              initiative,
              initiative.auditRecord.createDateTime,
            )
          : undefined,
      ].filter(Boolean)
    : [];

const castInitiativeCreationDateToInitiativeStatus = (
  initiative: Pick<InitiativeOverviewInitiative, 'id' | 'auditRecord'>,
  statusDateTime: Date,
): InitiativeOverviewInitiativeStatus => ({
  id: initiative.id,
  context: 'initiativeCreated',
  complete: false,
  statusIndicator: new StatusIndicator(),
  statusDateTime,
  auditRecord: initiative.auditRecord,
  actionComments: [],
  challengeComments: [],
  successComments: [],
  milestoneStatuses: [],
});
