import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StrategyRoomDeleteThemeMutationVariables = Types.Exact<{
  themeId: Types.Scalars['ID']['input'];
}>;


export type StrategyRoomDeleteThemeMutation = { __typename: 'Mutation', deleteThemes: boolean };


export const StrategyRoomDeleteThemeDocument = gql`
    mutation StrategyRoomDeleteTheme($themeId: ID!) {
  deleteThemes(idsToDelete: [$themeId])
}
    `;
export type StrategyRoomDeleteThemeMutationFn = Apollo.MutationFunction<StrategyRoomDeleteThemeMutation, StrategyRoomDeleteThemeMutationVariables>;

/**
 * __useStrategyRoomDeleteThemeMutation__
 *
 * To run a mutation, you first call `useStrategyRoomDeleteThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStrategyRoomDeleteThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [strategyRoomDeleteThemeMutation, { data, loading, error }] = useStrategyRoomDeleteThemeMutation({
 *   variables: {
 *      themeId: // value for 'themeId'
 *   },
 * });
 */
export function useStrategyRoomDeleteThemeMutation(baseOptions?: Apollo.MutationHookOptions<StrategyRoomDeleteThemeMutation, StrategyRoomDeleteThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StrategyRoomDeleteThemeMutation, StrategyRoomDeleteThemeMutationVariables>(StrategyRoomDeleteThemeDocument, options);
      }
export type StrategyRoomDeleteThemeMutationHookResult = ReturnType<typeof useStrategyRoomDeleteThemeMutation>;
export type StrategyRoomDeleteThemeMutationResult = Apollo.MutationResult<StrategyRoomDeleteThemeMutation>;
export type StrategyRoomDeleteThemeMutationOptions = Apollo.BaseMutationOptions<StrategyRoomDeleteThemeMutation, StrategyRoomDeleteThemeMutationVariables>;