import { useTranslation } from 'react-i18next';

import ReportStageTag from 'report/ReportStageTag';
import Heading from 'shared/components/Heading';
import Space from 'shared/components/Space';
import Text from 'shared/components/Text';
import { ReactComponent as SeparatorIcon } from 'shared/static/icons/icon-separator.svg';
import { date } from 'shared/services/date.service';

import { useInsightReportSummaryReport } from '../InsightReportSummaryProvider';
import styles from './InsightReportSummaryJumbotron.module.scss';

const InsightReportSummaryJumbotron = () => {
  const { t } = useTranslation();

  const {
    report: { strategy, auditRecord, reportStage },
  } = useInsightReportSummaryReport();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <Space direction={'vertical'}>
            <Heading
              level={3}
              as={2}
              className={styles.heading}
              hasMargin={false}
            >
              {strategy?.name}
            </Heading>
            <Space size={'small'} className={styles.description}>
              <Text variant={'strong'} className={styles.text}>
                {t('report.strategyInsightReport')}
              </Text>
              <Text className={styles.text}>
                <SeparatorIcon />
              </Text>
              <ReportStageTag
                reportStage={reportStage}
                className={styles.reportStageTag}
                variant={'regular'}
              />
              <Text className={styles.text}>
                <SeparatorIcon />
              </Text>
              <Text variant={'strong'} className={styles.text}>
                {auditRecord.createDateTime
                  ? date.formatShort(auditRecord.createDateTime)
                  : '-'}
              </Text>
            </Space>
          </Space>
        </div>
      </div>
      <div className={styles.spacer} />
    </>
  );
};

export default InsightReportSummaryJumbotron;
