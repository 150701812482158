import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteOneThingTaskMutationVariables = Types.Exact<{
  taskId: Types.Scalars['ID']['input'];
}>;


export type DeleteOneThingTaskMutation = { __typename: 'Mutation', deleteOneThingTasks: boolean };


export const DeleteOneThingTaskDocument = gql`
    mutation DeleteOneThingTask($taskId: ID!) {
  deleteOneThingTasks(idsToDelete: [$taskId])
}
    `;
export type DeleteOneThingTaskMutationFn = Apollo.MutationFunction<DeleteOneThingTaskMutation, DeleteOneThingTaskMutationVariables>;

/**
 * __useDeleteOneThingTaskMutation__
 *
 * To run a mutation, you first call `useDeleteOneThingTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneThingTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneThingTaskMutation, { data, loading, error }] = useDeleteOneThingTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useDeleteOneThingTaskMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneThingTaskMutation, DeleteOneThingTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneThingTaskMutation, DeleteOneThingTaskMutationVariables>(DeleteOneThingTaskDocument, options);
      }
export type DeleteOneThingTaskMutationHookResult = ReturnType<typeof useDeleteOneThingTaskMutation>;
export type DeleteOneThingTaskMutationResult = Apollo.MutationResult<DeleteOneThingTaskMutation>;
export type DeleteOneThingTaskMutationOptions = Apollo.BaseMutationOptions<DeleteOneThingTaskMutation, DeleteOneThingTaskMutationVariables>;