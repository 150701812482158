import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PreviewStrategyModalQueryVariables = Types.Exact<{
  strategyId: Types.Scalars['ID']['input'];
}>;


export type PreviewStrategyModalQuery = { __typename: 'Query', strategy: { __typename: 'Strategy', id: string, name?: string | undefined, isLive: boolean, isComplete: boolean } };


export const PreviewStrategyModalDocument = gql`
    query PreviewStrategyModal($strategyId: ID!) {
  strategy(id: $strategyId) {
    id
    name
    isLive
    isComplete
  }
}
    `;

/**
 * __usePreviewStrategyModalQuery__
 *
 * To run a query within a React component, call `usePreviewStrategyModalQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewStrategyModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewStrategyModalQuery({
 *   variables: {
 *      strategyId: // value for 'strategyId'
 *   },
 * });
 */
export function usePreviewStrategyModalQuery(baseOptions: Apollo.QueryHookOptions<PreviewStrategyModalQuery, PreviewStrategyModalQueryVariables> & ({ variables: PreviewStrategyModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviewStrategyModalQuery, PreviewStrategyModalQueryVariables>(PreviewStrategyModalDocument, options);
      }
export function usePreviewStrategyModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewStrategyModalQuery, PreviewStrategyModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviewStrategyModalQuery, PreviewStrategyModalQueryVariables>(PreviewStrategyModalDocument, options);
        }
export function usePreviewStrategyModalSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PreviewStrategyModalQuery, PreviewStrategyModalQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PreviewStrategyModalQuery, PreviewStrategyModalQueryVariables>(PreviewStrategyModalDocument, options);
        }
export type PreviewStrategyModalQueryHookResult = ReturnType<typeof usePreviewStrategyModalQuery>;
export type PreviewStrategyModalLazyQueryHookResult = ReturnType<typeof usePreviewStrategyModalLazyQuery>;
export type PreviewStrategyModalSuspenseQueryHookResult = ReturnType<typeof usePreviewStrategyModalSuspenseQuery>;
export type PreviewStrategyModalQueryResult = Apollo.QueryResult<PreviewStrategyModalQuery, PreviewStrategyModalQueryVariables>;