import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAssessmentMutationVariables = Types.Exact<{
  input: Types.StrategyAssessmentInput;
}>;


export type CreateAssessmentMutation = { __typename: 'Mutation', saveStrategyAssessment: { __typename: 'StrategyAssessment', id: string } };


export const CreateAssessmentDocument = gql`
    mutation CreateAssessment($input: StrategyAssessmentInput!) {
  saveStrategyAssessment(input: $input) {
    id
  }
}
    `;
export type CreateAssessmentMutationFn = Apollo.MutationFunction<CreateAssessmentMutation, CreateAssessmentMutationVariables>;

/**
 * __useCreateAssessmentMutation__
 *
 * To run a mutation, you first call `useCreateAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssessmentMutation, { data, loading, error }] = useCreateAssessmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssessmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssessmentMutation, CreateAssessmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAssessmentMutation, CreateAssessmentMutationVariables>(CreateAssessmentDocument, options);
      }
export type CreateAssessmentMutationHookResult = ReturnType<typeof useCreateAssessmentMutation>;
export type CreateAssessmentMutationResult = Apollo.MutationResult<CreateAssessmentMutation>;
export type CreateAssessmentMutationOptions = Apollo.BaseMutationOptions<CreateAssessmentMutation, CreateAssessmentMutationVariables>;