import { ApolloError } from '@apollo/client';
import type { TFunction } from 'react-i18next';

import { isAuthError } from 'auth/auth.utils';
import {
  getCurrentGraphQLError,
  getGraphqlErrorMessage,
} from 'shared/graphql/utils';
import { isServerError } from 'shared/utils/apollo.utils';

const logoutErrorCodes = ['SYSTEM_ACCESS_DENIED'];
const errorPageErrorCodes = ['NOT_FOUND', 'ACCESS_DENIED'];

export function getErrorMessage(t: TFunction, error: Error) {
  return error instanceof ApolloError
    ? getApolloErrorMessage(t, error)
    : getBaseErrorMessage(t, error);
}

export function getErrorShouldLogout(error: Error) {
  return error instanceof ApolloError
    ? getApolloErrorShouldLogout(error)
    : getBaseErrorShouldLogout(error);
}

export function getErrorShouldShowErrorPage(error: Error) {
  return error instanceof ApolloError
    ? getApolloErrorShouldShowErrorPage(error)
    : false;
}

export function getBaseErrorMessage(t: TFunction, error: Error) {
  if (isServerError(error) && error.statusCode === 401)
    return t('errorMessage.unauthorized');

  if (isAuthError(error)) return error.message;

  if (error?.message === 'Failed to fetch') return t('errorMessage.network');
}

export function getApolloErrorMessage(t: TFunction, error: ApolloError) {
  const { networkError } = error;

  if (networkError) return getBaseErrorMessage(t, networkError);

  const graphqlError = getCurrentGraphQLError(error);

  if (!graphqlError) return getBaseErrorMessage(t, error);

  const errorCode = graphqlError.extensions?.errorCode;

  if (!errorCode) return getBaseErrorMessage(t, error);

  return getGraphqlErrorMessage(t, graphqlError);
}

export function getBaseErrorShouldLogout(error: Error) {
  return isServerError(error) && error.statusCode === 401;
}

export function getApolloErrorShouldLogout(error: ApolloError) {
  const { networkError } = error;

  if (networkError) return false;

  const graphqlError = getCurrentGraphQLError(error);

  if (!graphqlError) return getBaseErrorShouldLogout(error);

  const errorCode = graphqlError.extensions?.errorCode;

  if (!errorCode) return getBaseErrorShouldLogout(error);

  return logoutErrorCodes.includes(errorCode);
}

export function getApolloErrorShouldShowErrorPage(error: ApolloError) {
  const { networkError } = error;

  if (networkError) return false;

  const graphqlError = getCurrentGraphQLError(error);

  if (!graphqlError) return false;

  const errorCode = graphqlError.extensions?.errorCode;

  if (!errorCode) return false;

  return errorPageErrorCodes.includes(errorCode);
}
