import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SelectOrgUnitInputQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SelectOrgUnitInputQuery = { __typename: 'Query', activeOrg: { __typename: 'Org', id: string, displayName?: string | undefined, orgUnits: Array<{ __typename: 'OrgUnit', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, parentOrgUnit?: { __typename: 'OrgUnit', id: string } | undefined, childOrgUnits: Array<{ __typename: 'OrgUnit', id: string }> }> } };

export type SelectOrgUnitInputOrgUnitFragment = { __typename: 'OrgUnit', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, parentOrgUnit?: { __typename: 'OrgUnit', id: string } | undefined, childOrgUnits: Array<{ __typename: 'OrgUnit', id: string }> };

export const SelectOrgUnitInputOrgUnitFragmentDoc = gql`
    fragment selectOrgUnitInputOrgUnit on OrgUnit {
  id
  name
  currentUserAuthorizedActions
  parentOrgUnit {
    id
  }
  childOrgUnits {
    id
  }
}
    `;
export const SelectOrgUnitInputDocument = gql`
    query SelectOrgUnitInput {
  activeOrg {
    id
    displayName
    orgUnits {
      ...selectOrgUnitInputOrgUnit
    }
  }
}
    ${SelectOrgUnitInputOrgUnitFragmentDoc}`;

/**
 * __useSelectOrgUnitInputQuery__
 *
 * To run a query within a React component, call `useSelectOrgUnitInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectOrgUnitInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectOrgUnitInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelectOrgUnitInputQuery(baseOptions?: Apollo.QueryHookOptions<SelectOrgUnitInputQuery, SelectOrgUnitInputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectOrgUnitInputQuery, SelectOrgUnitInputQueryVariables>(SelectOrgUnitInputDocument, options);
      }
export function useSelectOrgUnitInputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectOrgUnitInputQuery, SelectOrgUnitInputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectOrgUnitInputQuery, SelectOrgUnitInputQueryVariables>(SelectOrgUnitInputDocument, options);
        }
export function useSelectOrgUnitInputSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SelectOrgUnitInputQuery, SelectOrgUnitInputQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SelectOrgUnitInputQuery, SelectOrgUnitInputQueryVariables>(SelectOrgUnitInputDocument, options);
        }
export type SelectOrgUnitInputQueryHookResult = ReturnType<typeof useSelectOrgUnitInputQuery>;
export type SelectOrgUnitInputLazyQueryHookResult = ReturnType<typeof useSelectOrgUnitInputLazyQuery>;
export type SelectOrgUnitInputSuspenseQueryHookResult = ReturnType<typeof useSelectOrgUnitInputSuspenseQuery>;
export type SelectOrgUnitInputQueryResult = Apollo.QueryResult<SelectOrgUnitInputQuery, SelectOrgUnitInputQueryVariables>;