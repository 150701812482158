import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ObjectiveInsightsQueryVariables = Types.Exact<{
  objectiveId: Types.Scalars['ID']['input'];
}>;


export type ObjectiveInsightsQuery = { __typename: 'Query', objective: { __typename: 'Objective', id: string, name?: string | undefined, description?: string | undefined, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, isCurrentUserOwner: boolean, auditRecord: { __typename: 'AuditRecord', createDateTime?: Date | undefined, updateDateTime?: Date | undefined, createBy?: { __typename: 'AuditRecordUser', displayName?: string | undefined, email?: string | undefined, photoUrl?: string | undefined } | undefined }, objectiveStatusListAll: Array<{ __typename: 'ObjectiveStatus', id: string, complete: boolean, comment?: string | undefined, statusIndicator: StatusIndicatorClass, statusDateTime: Date, auditRecord: { __typename: 'AuditRecord', createDateTime?: Date | undefined, updateDateTime?: Date | undefined, createBy?: { __typename: 'AuditRecordUser', displayName?: string | undefined, email?: string | undefined, photoUrl?: string | undefined } | undefined }, actionComments: Array<{ __typename: 'TextItem', id: string, text?: string | undefined }>, challengeComments: Array<{ __typename: 'TextItem', id: string, text?: string | undefined }>, successComments: Array<{ __typename: 'TextItem', id: string, text?: string | undefined }> }>, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined } };

export type ObjectiveInsightsObjectiveFragment = { __typename: 'Objective', id: string, name?: string | undefined, description?: string | undefined, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, isCurrentUserOwner: boolean, auditRecord: { __typename: 'AuditRecord', createDateTime?: Date | undefined, updateDateTime?: Date | undefined, createBy?: { __typename: 'AuditRecordUser', displayName?: string | undefined, email?: string | undefined, photoUrl?: string | undefined } | undefined }, objectiveStatusListAll: Array<{ __typename: 'ObjectiveStatus', id: string, complete: boolean, comment?: string | undefined, statusIndicator: StatusIndicatorClass, statusDateTime: Date, auditRecord: { __typename: 'AuditRecord', createDateTime?: Date | undefined, updateDateTime?: Date | undefined, createBy?: { __typename: 'AuditRecordUser', displayName?: string | undefined, email?: string | undefined, photoUrl?: string | undefined } | undefined }, actionComments: Array<{ __typename: 'TextItem', id: string, text?: string | undefined }>, challengeComments: Array<{ __typename: 'TextItem', id: string, text?: string | undefined }>, successComments: Array<{ __typename: 'TextItem', id: string, text?: string | undefined }> }>, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined };

export type ObjectiveInsightsObjectiveStatusFragment = { __typename: 'ObjectiveStatus', id: string, complete: boolean, comment?: string | undefined, statusIndicator: StatusIndicatorClass, statusDateTime: Date, auditRecord: { __typename: 'AuditRecord', createDateTime?: Date | undefined, updateDateTime?: Date | undefined, createBy?: { __typename: 'AuditRecordUser', displayName?: string | undefined, email?: string | undefined, photoUrl?: string | undefined } | undefined }, actionComments: Array<{ __typename: 'TextItem', id: string, text?: string | undefined }>, challengeComments: Array<{ __typename: 'TextItem', id: string, text?: string | undefined }>, successComments: Array<{ __typename: 'TextItem', id: string, text?: string | undefined }> };

export const ObjectiveInsightsObjectiveStatusFragmentDoc = gql`
    fragment objectiveInsightsObjectiveStatus on ObjectiveStatus {
  id
  auditRecord {
    createBy {
      displayName
      email
      photoUrl
    }
    createDateTime
    updateDateTime
  }
  complete
  comment
  actionComments {
    id
    text
  }
  challengeComments {
    id
    text
  }
  successComments {
    id
    text
  }
  statusIndicator
  statusDateTime
}
    `;
export const ObjectiveInsightsObjectiveFragmentDoc = gql`
    fragment objectiveInsightsObjective on Objective {
  id
  name
  description
  currentUserAuthorizedActions
  isCurrentUserOwner
  auditRecord {
    createBy {
      displayName
      email
      photoUrl
    }
    createDateTime
    updateDateTime
  }
  objectiveStatusListAll {
    ...objectiveInsightsObjectiveStatus
  }
  owner {
    id
    email
    displayName
  }
  orgUnit {
    id
  }
}
    ${ObjectiveInsightsObjectiveStatusFragmentDoc}`;
export const ObjectiveInsightsDocument = gql`
    query ObjectiveInsights($objectiveId: ID!) {
  objective(id: $objectiveId) {
    ...objectiveInsightsObjective
  }
}
    ${ObjectiveInsightsObjectiveFragmentDoc}`;

/**
 * __useObjectiveInsightsQuery__
 *
 * To run a query within a React component, call `useObjectiveInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useObjectiveInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObjectiveInsightsQuery({
 *   variables: {
 *      objectiveId: // value for 'objectiveId'
 *   },
 * });
 */
export function useObjectiveInsightsQuery(baseOptions: Apollo.QueryHookOptions<ObjectiveInsightsQuery, ObjectiveInsightsQueryVariables> & ({ variables: ObjectiveInsightsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ObjectiveInsightsQuery, ObjectiveInsightsQueryVariables>(ObjectiveInsightsDocument, options);
      }
export function useObjectiveInsightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjectiveInsightsQuery, ObjectiveInsightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ObjectiveInsightsQuery, ObjectiveInsightsQueryVariables>(ObjectiveInsightsDocument, options);
        }
export function useObjectiveInsightsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ObjectiveInsightsQuery, ObjectiveInsightsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ObjectiveInsightsQuery, ObjectiveInsightsQueryVariables>(ObjectiveInsightsDocument, options);
        }
export type ObjectiveInsightsQueryHookResult = ReturnType<typeof useObjectiveInsightsQuery>;
export type ObjectiveInsightsLazyQueryHookResult = ReturnType<typeof useObjectiveInsightsLazyQuery>;
export type ObjectiveInsightsSuspenseQueryHookResult = ReturnType<typeof useObjectiveInsightsSuspenseQuery>;
export type ObjectiveInsightsQueryResult = Apollo.QueryResult<ObjectiveInsightsQuery, ObjectiveInsightsQueryVariables>;