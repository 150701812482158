import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignResponsesQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['ID']['input'];
}>;


export type CampaignResponsesQuery = { __typename: 'Query', campaign: { __typename: 'Campaign', id: string, currentUserResponse?: { __typename: 'CampaignUserResponse', id: string, status: Types.ProgressStatus, comments: Array<{ __typename: 'CampaignComment', id: string, text: string, auditRecord?: { __typename: 'AuditRecord', createDateTime?: Date | undefined } | undefined }>, answers: Array<{ __typename: 'CampaignAnswer', id: string, rating: Types.LikertScaleRating, question: { __typename: 'CampaignQuestion', id: string, questionType: Types.CampaignQuestionType } }> } | undefined } };


export const CampaignResponsesDocument = gql`
    query CampaignResponses($campaignId: ID!) {
  campaign(id: $campaignId) {
    id
    currentUserResponse {
      id
      status
      comments {
        id
        text
        auditRecord {
          createDateTime
        }
      }
      answers {
        id
        rating
        question {
          id
          questionType
        }
      }
    }
  }
}
    `;

/**
 * __useCampaignResponsesQuery__
 *
 * To run a query within a React component, call `useCampaignResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignResponsesQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useCampaignResponsesQuery(baseOptions: Apollo.QueryHookOptions<CampaignResponsesQuery, CampaignResponsesQueryVariables> & ({ variables: CampaignResponsesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignResponsesQuery, CampaignResponsesQueryVariables>(CampaignResponsesDocument, options);
      }
export function useCampaignResponsesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignResponsesQuery, CampaignResponsesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignResponsesQuery, CampaignResponsesQueryVariables>(CampaignResponsesDocument, options);
        }
export function useCampaignResponsesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CampaignResponsesQuery, CampaignResponsesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CampaignResponsesQuery, CampaignResponsesQueryVariables>(CampaignResponsesDocument, options);
        }
export type CampaignResponsesQueryHookResult = ReturnType<typeof useCampaignResponsesQuery>;
export type CampaignResponsesLazyQueryHookResult = ReturnType<typeof useCampaignResponsesLazyQuery>;
export type CampaignResponsesSuspenseQueryHookResult = ReturnType<typeof useCampaignResponsesSuspenseQuery>;
export type CampaignResponsesQueryResult = Apollo.QueryResult<CampaignResponsesQuery, CampaignResponsesQueryVariables>;