import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrgUnitQueryVariables = Types.Exact<{
  orgUnitId: Types.Scalars['ID']['input'];
}>;


export type OrgUnitQuery = { __typename: 'Query', orgUnit: { __typename: 'OrgUnit', currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined, parentOrgUnitTree: Array<{ __typename: 'OrgUnit', id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined, currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, parentOrgUnit?: { __typename: 'OrgUnit', id: string } | undefined, currentStrategy?: { __typename: 'Strategy', id: string, name?: string | undefined } | undefined }>, currentStrategy?: { __typename: 'Strategy', id: string, name?: string | undefined } | undefined } };

export type OrgUnitFieldsFragment = { __typename: 'OrgUnit', id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined, currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, currentStrategy?: { __typename: 'Strategy', id: string, name?: string | undefined } | undefined };

export const OrgUnitFieldsFragmentDoc = gql`
    fragment orgUnitFields on OrgUnit {
  id
  name
  nameAbbreviated
  currentStrategy {
    id
    name
  }
  backgroundColorAsHex
  currentUserAuthorizedActions
}
    `;
export const OrgUnitDocument = gql`
    query OrgUnit($orgUnitId: ID!) {
  orgUnit(id: $orgUnitId) {
    ...orgUnitFields
    currentUserAuthorizedActions
    parentOrgUnitTree {
      ...orgUnitFields
      parentOrgUnit {
        id
      }
    }
  }
}
    ${OrgUnitFieldsFragmentDoc}`;

/**
 * __useOrgUnitQuery__
 *
 * To run a query within a React component, call `useOrgUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgUnitQuery({
 *   variables: {
 *      orgUnitId: // value for 'orgUnitId'
 *   },
 * });
 */
export function useOrgUnitQuery(baseOptions: Apollo.QueryHookOptions<OrgUnitQuery, OrgUnitQueryVariables> & ({ variables: OrgUnitQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgUnitQuery, OrgUnitQueryVariables>(OrgUnitDocument, options);
      }
export function useOrgUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgUnitQuery, OrgUnitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgUnitQuery, OrgUnitQueryVariables>(OrgUnitDocument, options);
        }
export function useOrgUnitSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OrgUnitQuery, OrgUnitQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrgUnitQuery, OrgUnitQueryVariables>(OrgUnitDocument, options);
        }
export type OrgUnitQueryHookResult = ReturnType<typeof useOrgUnitQuery>;
export type OrgUnitLazyQueryHookResult = ReturnType<typeof useOrgUnitLazyQuery>;
export type OrgUnitSuspenseQueryHookResult = ReturnType<typeof useOrgUnitSuspenseQuery>;
export type OrgUnitQueryResult = Apollo.QueryResult<OrgUnitQuery, OrgUnitQueryVariables>;