import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { UserLimitedProfileFragmentDoc } from '../../user/userProfile.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OneThingCampaignsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OneThingCampaignsQuery = { __typename: 'Query', allOneThingCampaigns: { __typename: 'OneThingCampaignConnection', edges: Array<{ __typename: 'OneThingCampaignEdge', node: { __typename: 'OneThingCampaign', id: string, name: string, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction> } | undefined, completionRate: { __typename: 'CampaignCompletionRate', percentDone: number }, timeLine: { __typename: 'TimeLine', startDate?: Date | undefined, endDate?: Date | undefined }, state: { __typename: 'CampaignState', stage: Types.CampaignStage }, owner: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean }, tasks: Array<{ __typename: 'OneThingTask', id: string }> } }> } };


export const OneThingCampaignsDocument = gql`
    query OneThingCampaigns {
  allOneThingCampaigns {
    edges {
      node {
        id
        name
        orgUnit {
          id
          name
          currentUserAuthorizedActions
        }
        completionRate {
          percentDone
        }
        timeLine {
          startDate
          endDate
        }
        state {
          stage
        }
        owner {
          ...userLimitedProfile
        }
        tasks {
          id
        }
      }
    }
  }
}
    ${UserLimitedProfileFragmentDoc}`;

/**
 * __useOneThingCampaignsQuery__
 *
 * To run a query within a React component, call `useOneThingCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOneThingCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOneThingCampaignsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOneThingCampaignsQuery(baseOptions?: Apollo.QueryHookOptions<OneThingCampaignsQuery, OneThingCampaignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OneThingCampaignsQuery, OneThingCampaignsQueryVariables>(OneThingCampaignsDocument, options);
      }
export function useOneThingCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OneThingCampaignsQuery, OneThingCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OneThingCampaignsQuery, OneThingCampaignsQueryVariables>(OneThingCampaignsDocument, options);
        }
export function useOneThingCampaignsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OneThingCampaignsQuery, OneThingCampaignsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OneThingCampaignsQuery, OneThingCampaignsQueryVariables>(OneThingCampaignsDocument, options);
        }
export type OneThingCampaignsQueryHookResult = ReturnType<typeof useOneThingCampaignsQuery>;
export type OneThingCampaignsLazyQueryHookResult = ReturnType<typeof useOneThingCampaignsLazyQuery>;
export type OneThingCampaignsSuspenseQueryHookResult = ReturnType<typeof useOneThingCampaignsSuspenseQuery>;
export type OneThingCampaignsQueryResult = Apollo.QueryResult<OneThingCampaignsQuery, OneThingCampaignsQueryVariables>;