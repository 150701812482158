import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StrategyRoomStrategiesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.StrategyFilterInput>;
}>;


export type StrategyRoomStrategiesQuery = { __typename: 'Query', activeOrg: { __typename: 'Org', id: string, strategies: { __typename: 'StrategyConnection', edges: Array<{ __typename: 'StrategyEdge', node: { __typename: 'Strategy', id: string, name?: string | undefined, isLive: boolean, isComplete: boolean, auditRecord: { __typename: 'AuditRecord', updateDateTime?: Date | undefined, createDateTime?: Date | undefined }, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined } }> } } };

export type StrategyRoomStrategyFragment = { __typename: 'Strategy', id: string, name?: string | undefined, isLive: boolean, isComplete: boolean, auditRecord: { __typename: 'AuditRecord', updateDateTime?: Date | undefined, createDateTime?: Date | undefined }, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined };

export const StrategyRoomStrategyFragmentDoc = gql`
    fragment strategyRoomStrategy on Strategy {
  id
  name
  isLive
  isComplete
  auditRecord {
    updateDateTime
    createDateTime
  }
  orgUnit {
    id
  }
}
    `;
export const StrategyRoomStrategiesDocument = gql`
    query StrategyRoomStrategies($filter: StrategyFilterInput) {
  activeOrg {
    id
    strategies(filter: $filter) {
      edges {
        node {
          ...strategyRoomStrategy
        }
      }
    }
  }
}
    ${StrategyRoomStrategyFragmentDoc}`;

/**
 * __useStrategyRoomStrategiesQuery__
 *
 * To run a query within a React component, call `useStrategyRoomStrategiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStrategyRoomStrategiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStrategyRoomStrategiesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStrategyRoomStrategiesQuery(baseOptions?: Apollo.QueryHookOptions<StrategyRoomStrategiesQuery, StrategyRoomStrategiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StrategyRoomStrategiesQuery, StrategyRoomStrategiesQueryVariables>(StrategyRoomStrategiesDocument, options);
      }
export function useStrategyRoomStrategiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StrategyRoomStrategiesQuery, StrategyRoomStrategiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StrategyRoomStrategiesQuery, StrategyRoomStrategiesQueryVariables>(StrategyRoomStrategiesDocument, options);
        }
export function useStrategyRoomStrategiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<StrategyRoomStrategiesQuery, StrategyRoomStrategiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StrategyRoomStrategiesQuery, StrategyRoomStrategiesQueryVariables>(StrategyRoomStrategiesDocument, options);
        }
export type StrategyRoomStrategiesQueryHookResult = ReturnType<typeof useStrategyRoomStrategiesQuery>;
export type StrategyRoomStrategiesLazyQueryHookResult = ReturnType<typeof useStrategyRoomStrategiesLazyQuery>;
export type StrategyRoomStrategiesSuspenseQueryHookResult = ReturnType<typeof useStrategyRoomStrategiesSuspenseQuery>;
export type StrategyRoomStrategiesQueryResult = Apollo.QueryResult<StrategyRoomStrategiesQuery, StrategyRoomStrategiesQueryVariables>;