import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerateUploadUrlMutationVariables = Types.Exact<{
  orgKey: Types.Scalars['String']['input'];
  objectType: Types.StorageObjectType;
  fileName: Types.Scalars['String']['input'];
}>;


export type GenerateUploadUrlMutation = { __typename: 'Mutation', generateUploadUrl: { __typename: 'UploadUrl', signedUrl: string, fileUrl: string } };


export const GenerateUploadUrlDocument = gql`
    mutation GenerateUploadUrl($orgKey: String!, $objectType: StorageObjectType!, $fileName: String!) {
  generateUploadUrl(orgKey: $orgKey, objectType: $objectType, fileName: $fileName) {
    signedUrl
    fileUrl
  }
}
    `;
export type GenerateUploadUrlMutationFn = Apollo.MutationFunction<GenerateUploadUrlMutation, GenerateUploadUrlMutationVariables>;

/**
 * __useGenerateUploadUrlMutation__
 *
 * To run a mutation, you first call `useGenerateUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateUploadUrlMutation, { data, loading, error }] = useGenerateUploadUrlMutation({
 *   variables: {
 *      orgKey: // value for 'orgKey'
 *      objectType: // value for 'objectType'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useGenerateUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<GenerateUploadUrlMutation, GenerateUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateUploadUrlMutation, GenerateUploadUrlMutationVariables>(GenerateUploadUrlDocument, options);
      }
export type GenerateUploadUrlMutationHookResult = ReturnType<typeof useGenerateUploadUrlMutation>;
export type GenerateUploadUrlMutationResult = Apollo.MutationResult<GenerateUploadUrlMutation>;
export type GenerateUploadUrlMutationOptions = Apollo.BaseMutationOptions<GenerateUploadUrlMutation, GenerateUploadUrlMutationVariables>;