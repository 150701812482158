import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserTenantsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserTenantsQuery = { __typename: 'Query', allTenantsForCurrentUser: Array<{ __typename: 'Tenant', id: string, orgKey: string, domainNames: Array<string>, displayName?: string | undefined, isLive: boolean, isPrimaryTenantForCurrentUser: boolean }> };

export type UserTenantsTenantFragment = { __typename: 'Tenant', id: string, orgKey: string, domainNames: Array<string>, displayName?: string | undefined, isLive: boolean, isPrimaryTenantForCurrentUser: boolean };

export const UserTenantsTenantFragmentDoc = gql`
    fragment userTenantsTenant on Tenant {
  id
  orgKey
  domainNames
  displayName
  isLive
  isPrimaryTenantForCurrentUser
}
    `;
export const UserTenantsDocument = gql`
    query UserTenants {
  allTenantsForCurrentUser {
    ...userTenantsTenant
  }
}
    ${UserTenantsTenantFragmentDoc}`;

/**
 * __useUserTenantsQuery__
 *
 * To run a query within a React component, call `useUserTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTenantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserTenantsQuery(baseOptions?: Apollo.QueryHookOptions<UserTenantsQuery, UserTenantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserTenantsQuery, UserTenantsQueryVariables>(UserTenantsDocument, options);
      }
export function useUserTenantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTenantsQuery, UserTenantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserTenantsQuery, UserTenantsQueryVariables>(UserTenantsDocument, options);
        }
export function useUserTenantsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserTenantsQuery, UserTenantsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserTenantsQuery, UserTenantsQueryVariables>(UserTenantsDocument, options);
        }
export type UserTenantsQueryHookResult = ReturnType<typeof useUserTenantsQuery>;
export type UserTenantsLazyQueryHookResult = ReturnType<typeof useUserTenantsLazyQuery>;
export type UserTenantsSuspenseQueryHookResult = ReturnType<typeof useUserTenantsSuspenseQuery>;
export type UserTenantsQueryResult = Apollo.QueryResult<UserTenantsQuery, UserTenantsQueryVariables>;