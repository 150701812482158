import styled from '@emotion/styled';

import { useAskSophie } from 'ai/AskSophieChat/AskSophieProvider';
import PromptButton from 'ai/AskSohpieButton/AskSophieSidePanel/PromptButton';

import type { Message, PromptMessage } from './AskSophieChat.type';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
`;

type Props = {
  addMessage: (message: Message) => void;
  isLoading: boolean;
  messages: PromptMessage[];
};

const Prompt = ({ messages, isLoading, addMessage }: Props) => {
  const { objectiveIds, metricIds, initiativeIds, actionIds, strategyId } =
    useAskSophie();

  const createAddSummaryHandler = (promptMessage: PromptMessage) => () => {
    addMessage({ type: 'InputMessage', content: promptMessage.buttonLabel });
    addMessage({
      type: 'SummaryMessage',
      promptMessage,
      context: {
        objectiveIds,
        metricIds,
        initiativeIds,
        actionIds,
        strategyId,
      },
    });
  };

  return (
    <Container>
      {messages.map((message) => (
        <PromptButton
          key={message.buttonLabel}
          onClick={createAddSummaryHandler(message)}
          isDisabled={isLoading}
        >
          {message.buttonLabel}
        </PromptButton>
      ))}
    </Container>
  );
};

export default Prompt;
