import cn from 'classnames';
import type { HTMLAttributes, PropsWithChildren, RefObject } from 'react';
import type { Merge } from 'type-fest';

import styles from './Tabs.module.scss';
import { useTabs } from './useTabs';

export type TabsItemProps = Merge<
  HTMLAttributes<HTMLLIElement>,
  PropsWithChildren<{
    buttonClassName?: string;
    className?: string;
    isActive?: boolean;
    onClick?: (value?: any) => void;
    onTabClick?: () => void;
    tabIndex?: number;
    tabRef?: RefObject<HTMLLIElement>;
    tabsRefs?: Array<RefObject<HTMLLIElement>>;
  }>
>;

const TabsItem = ({
  onClick,
  onTabClick,
  buttonClassName,
  className,
  children,
  tabRef,
  tabIndex,
  isActive,
  ...restProps
}: TabsItemProps) => {
  const { activeTab, setActiveTab } = useTabs();

  const onButtonClick = () => {
    if (tabIndex !== undefined) setActiveTab(tabIndex);
    onTabClick?.();
    onClick?.();
  };

  return (
    <li
      className={cn(styles.listItem, className)}
      ref={tabRef}
      key={tabIndex}
      onClick={onButtonClick}
      {...restProps}
    >
      <button
        className={cn(styles.button, buttonClassName, {
          [styles.buttonActive]:
            isActive !== undefined ? isActive : tabIndex === activeTab,
        })}
      >
        {children}
      </button>
    </li>
  );
};

export default TabsItem;
