import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssesmentWizardModalQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ObjectiveFilterInput>;
}>;


export type AssesmentWizardModalQuery = { __typename: 'Query', allObjectives: { __typename: 'ObjectiveConnection', edges: Array<{ __typename: 'ObjectiveEdge', node: { __typename: 'Objective', id: string, metrics: Array<{ __typename: 'Metric', id: string }>, initiatives: Array<{ __typename: 'Initiative', id: string }>, actions: Array<{ __typename: 'Action', id: string }> } }> } };


export const AssesmentWizardModalDocument = gql`
    query AssesmentWizardModal($filter: ObjectiveFilterInput) {
  allObjectives(filter: $filter) {
    edges {
      node {
        id
        metrics {
          id
        }
        initiatives {
          id
        }
        actions {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useAssesmentWizardModalQuery__
 *
 * To run a query within a React component, call `useAssesmentWizardModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssesmentWizardModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssesmentWizardModalQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAssesmentWizardModalQuery(baseOptions?: Apollo.QueryHookOptions<AssesmentWizardModalQuery, AssesmentWizardModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssesmentWizardModalQuery, AssesmentWizardModalQueryVariables>(AssesmentWizardModalDocument, options);
      }
export function useAssesmentWizardModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssesmentWizardModalQuery, AssesmentWizardModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssesmentWizardModalQuery, AssesmentWizardModalQueryVariables>(AssesmentWizardModalDocument, options);
        }
export function useAssesmentWizardModalSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AssesmentWizardModalQuery, AssesmentWizardModalQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AssesmentWizardModalQuery, AssesmentWizardModalQueryVariables>(AssesmentWizardModalDocument, options);
        }
export type AssesmentWizardModalQueryHookResult = ReturnType<typeof useAssesmentWizardModalQuery>;
export type AssesmentWizardModalLazyQueryHookResult = ReturnType<typeof useAssesmentWizardModalLazyQuery>;
export type AssesmentWizardModalSuspenseQueryHookResult = ReturnType<typeof useAssesmentWizardModalSuspenseQuery>;
export type AssesmentWizardModalQueryResult = Apollo.QueryResult<AssesmentWizardModalQuery, AssesmentWizardModalQueryVariables>;