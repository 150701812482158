import { StatusIndicator } from 'shared/status/StatusIndicator';

import type {
  ObjectiveInsightsObjective,
  ObjectiveInsightsStatus,
} from '../ObjectiveInsights.type';

export const getObjectiveStatusListWithObjectiveCreationDate = (
  objective: ObjectiveInsightsObjective,
  objectiveStatusList: ObjectiveInsightsStatus[],
) =>
  [
    ...objectiveStatusList,
    objective.auditRecord.createDateTime
      ? castObjectiveCreationDateToObjectiveStatus(
          objective,
          objective.auditRecord.createDateTime,
        )
      : undefined,
  ].filter(Boolean);

const castObjectiveCreationDateToObjectiveStatus = (
  objective: Pick<ObjectiveInsightsObjective, 'id' | 'auditRecord'>,
  statusDateTime: Date,
): ObjectiveInsightsStatus => ({
  id: objective!.id,
  context: 'objectiveCreated',
  complete: false,
  statusIndicator: new StatusIndicator(),
  statusDateTime,
  auditRecord: objective!.auditRecord,
  actionComments: [],
  challengeComments: [],
  successComments: [],
});
